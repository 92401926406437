import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";

//Dashboard
import Dashboard from "./dashboard/reducer";
import sidebarReducer from "../Modules/Redux/Reducers/ApplicantSideBarReducers"
import formFormIndexNumberReducer from "../Modules/Redux/FormResultsCheck/Reducers/FormFourCheckerReducer"
import authUserDataReducer from "../Modules/Redux/FormResultsCheck/Reducers/AuthUserReducer"


const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  Dashboard,
  sidebarReducer,
  formFormIndexNumberReducer,
  authUserDataReducer
});

export default rootReducer;
