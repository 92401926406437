import React from "react"

const GlobalLoader = () => {

  // Path to the gif stored in the public folder
  const giff = process.env.PUBLIC_URL + "/giff/loader.gif"

  return (
    <div style={{
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 1000,
      pointerEvents: "all"
    }}>
      {/* Container for gif and text */}
      <div style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
      }}>
        {/* Display the GIF */}
        <img src={giff} alt="Loading..." style={{
          pointerEvents: "auto",
          width: "150px",
          height: "150px"
        }} />
        {/* Display the text next to the gif */}
        <span style={{
          color: "white",
          fontSize: "20px",
          marginLeft: "10px" // Space between gif and text
        }}>
          Please wait...
        </span>
      </div>
    </div>
  )
}

export default GlobalLoader
