import { useMutation, useQuery } from "@apollo/client"
import { REQUEST_FEE_STRUCTURE_CONTROL_NUMBERS } from "../../Students/Mutations/StudentPaymentMutations"
import {
  registrationGraphQLClient,
  uaaGraphQLClient,
} from "../../Authentication/ApolloClient"
import { GET_STUDENT } from "../../Users/Queries/UserQuery"
import { useFormik } from "formik"
import {
  GET_CONTROL_NUMBER,
  GET_FEE_STRUCTURE,
} from "../../Students/Queries/Payment/StudentPaymentQueries"
import showToast from "../../../helpers/ToastHelper"
import { LoaderPage } from "../../../helpers/LoaderPage"
import LayoutHelper from "../../../helpers/LayoutHelper"
import {
  Card,
  CardBody,
  CardSubtitle,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap"
import React, { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import * as Yup from "yup"
import { GET_REGIONS_BY_COUNTRY } from "../../Regions/Queries/RegionQueries"
import { GET_DISTRICT_BY_REGION } from "../../Districts/Queries/DistrictQueries"
import { GET_BANKS } from "../../Banks/Queries/BankQueries"
import { GET_SPONSORS } from "../../Sponsors/Queries/SponsorQueries"
import { limit } from "../../../helpers/UrlHelper"
import Select from "react-select"
import SubmitButtonHelper from "../../../helpers/SubmitButtonHelper"
import { GET_COUNTRY } from "../../Country/Queries/CountryQueries"
import { paginationHelper } from "../../../helpers/Functions/GraphqlUpdateFunction"
import { UPDATE_STUDENT_CONTANTS } from "../../Students/Mutations/UpdateStudentMutation"

const breadcrumbItem = [
  {
    title: "Self registration",
    path: "",
    isActive: true,
  },
]

const SelfRegistrationContactInformation = () => {
  const navigate = useNavigate()
  const [selectedCountry, setSelectedCountry] = useState("")
  const [currentPage, setCurrentPage] = useState(0)
  const pagination = { offset: currentPage, limit: limit, search: null }
  const [selectedRegion, setSelectedRegion] = useState("")

  const {
    loading: userLoading,
    error: userError,
    data: userDetails,
    refetch,
  } = useQuery(GET_STUDENT, {
    client: uaaGraphQLClient,
    variables: {
      username: JSON.parse(localStorage.getItem("authUser"))?.data?.username,
    },
    fetchPolicy: "network-only",
  })

  const user = userDetails?.getUserByUsername?.data

  useEffect(() => {
    if (user) {
      setSelectedCountry(user?.country?.uid)
    }
  }, [user])

  const [update] = useMutation(UPDATE_STUDENT_CONTANTS, {
    client: uaaGraphQLClient,
  })

  const {
    loading: loadingFeeStructureControlNumbers,
    error: errorFeeStructure,
    data: feeStructureControlNumber,
    refetch: refetchControlNUmbers,
  } = useQuery(GET_CONTROL_NUMBER, {
    client: registrationGraphQLClient,
    skip: !user?.student?.registrationNumber,
    variables: {
      registrationNumber: user?.student?.registrationNumber,
    },
    fetchPolicy: "network-only",
  })

  const feeWithStructureControlNumber =
    feeStructureControlNumber?.getControlNumbers?.data
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      countryUid: user?.country
        ? user?.country?.uid
        : "",
      regiodUid: user?.student?.district?.region
        ? user?.student?.district?.region?.uid
        : "",
      postalAddress: user ? user?.postalAddress : "",
      physicalAddress: user ? user?.physicalAddress : "",
      districtUid: user?.student?.district ? user?.student?.district?.uid : "",
      bankUid: user?.student?.bank ? user?.student?.bank?.uid : "",
      accountNumber: user?.student ? user?.student?.accountNumber : "",
      sponsors: user ? user?.sponsors : "",
    },

    validationSchema: Yup.object().shape({
      postalAddress: Yup.string()
        .nullable()
        .required("Postal address is required"),
      physicalAddress: Yup.string()
        .nullable()
        .required("Your physical address is required"),
      accountNumber: Yup.string()
        .nullable()
        .required("Bank Account number is required"),
      countryUid: Yup.string().required("Country name is required"),
      regiodUid: Yup.string().required("Region name is required"),
      districtUid: Yup.string().required("District name is required"),
      bankUid: Yup.string().required("Bank name is required"),
      // sponsorUid: Yup.string().required("Sponsor name is required"),
    }),

    onSubmit: (values, { resetForm }) => {
      const postData = {
        districtUid: values.districtUid,
        accountNumber: values.accountNumber,
        bankUid: values.bankUid,
        postalAddress: values.postalAddress,
        physicalAddress: values.physicalAddress,
        sponsors: values.sponsors,
      }
      update({
        client: uaaGraphQLClient,
        variables: {
          input: { ...postData, userUid: user?.uid },
        },
        onCompleted: ({ updateStudentContact }) => {
          showToast(
            updateStudentContact.message,
            updateStudentContact.code === 8000 ? "success" : "error"
          )
          if (updateStudentContact.code === 8000) {
            navigate("/self-registration/next-of-kin-information")
          }
        },
        onError: error => {
          // Handle errors
          console.error("Mutation error:", error)
        },
      })
    },
  })

  const {
    loading,
    error,
    data: regionData,
  } = useQuery(GET_REGIONS_BY_COUNTRY, {
    client: uaaGraphQLClient,
    skip: !selectedCountry,
    variables: {
      countryUid: selectedCountry,
    },
    fetchPolicy: "cache-and-network",
  })

  const {
    loading: countryLoading,
    error: countryError,
    data: countryData,
  } = useQuery(GET_COUNTRY, {
    client: uaaGraphQLClient,
    variables: {
      pagination: paginationHelper,
    },
  })

  const countries = countryData?.getCountries?.data?.items

  const {
    loading: districtLoading,
    error: districtError,
    data: districtData,
    refetch: districtRefetch,
  } = useQuery(GET_DISTRICT_BY_REGION, {
    client: uaaGraphQLClient,
    skip: !selectedRegion,
    variables: {
      regionUid: selectedRegion,
    },
    fetchPolicy: "cache-and-network",
  })

  const {
    loading: banksLoading,
    error: bankError,
    data: bankData,
    refetch: bankrefetch,
  } = useQuery(GET_BANKS, {
    client: uaaGraphQLClient,
    variables: {
      pagination,
    },
    fetchPolicy: "cache-first",
  })

  const {
    loading: sponsorLoading,
    error: sponsorkError,
    data: sponsorData,
    refetch: sponsorrefetch,
  } = useQuery(GET_SPONSORS, {
    client: uaaGraphQLClient,
    variables: {
      pagination,
    },
    fetchPolicy: "cache-first",
  })

  const regions = regionData?.getRegionsByCountry?.data // get regions from country selected

  const districts = districtData?.getDistrictsByRegion?.data // get districts from region selcted

  const banks = bankData?.getBanks?.data?.items // get banks list

  const sponsors = sponsorData?.getSponsors?.data?.items // get sponsors list

  if (userLoading)
    return (
      <LoaderPage
        breadcrumbItem={breadcrumbItem}
        title={"Registration"}
        description={"Fetching User Details"}
      />
    )
  if (loadingFeeStructureControlNumbers)
    return (
      <LoaderPage
        breadcrumbItem={breadcrumbItem}
        title={"Registration"}
        description={"Fetching Control Number Information"}
      />
    )

  if (!feeWithStructureControlNumber) navigate("/self-registration/payments")

  if (!user?.placeOfBirth	|| !user?.dateOfBirth){
    showToast("Please update required details", "info")
    navigate("/self-registration/my-information");
  }
  // console.log("Fees", fees)
  // console.log("controls", feeWithStructureControlNumber)
  return (
    <LayoutHelper
      breadcrumbItem={breadcrumbItem}
      pageTitle={"Contact Information"}
    >
      <Row>
        <Col>
          <Card>
            <CardBody>
              <CardSubtitle>
                <h4>Update Contact Information</h4>
              </CardSubtitle>

              <Form
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return null
                }}
              >
                <Row>
                  <Col lg="4">
                    <div className="mb-3">
                      <Label>Country</Label>
                      <Select
                        name="countryUid"
                        onChange={e => {
                          validation.setFieldValue("countryUid", e.value)
                          setSelectedCountry(
                            user?.country?.uid ? user?.country?.uid : e.value
                          )
                        }}
                        options={countries?.map(dp => ({
                          value: dp?.uid,
                          label: dp?.name,
                        }))}
                        className="select2-selection"
                        styles={{
                          menu: base => ({
                            ...base,
                            position: "absolute",
                            zIndex: 9999,
                          }),
                        }}
                        defaultValue={user?.country?.uid}
                        defaultInputValue={user?.country?.name}
                      />
                      <Input
                        name="countryUid"
                        placeholder="Select Region"
                        type="hidden"
                        rows={2}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={
                          validation.values.countryUid || user?.countryUid || ""
                        }
                        invalid={
                          !!(
                            validation.touched.countryUid &&
                            validation.errors.countryUid
                          )
                        }
                      />
                      {validation.touched.countryUid &&
                      validation.errors.countryUid ? (
                        <FormFeedback type="invalid">
                          {validation.errors.countryUid}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col lg="4">
                    <div className="mb-3">
                      <Label>Region</Label>
                      <Select
                        onChange={e => {
                          validation.setFieldValue("regiodUid", e.value)
                          setSelectedRegion(e.value)
                        }}
                        name="regiodUid"
                        options={regions?.map(dp => ({
                          value: dp?.uid,
                          label: dp?.name,
                        }))}
                        className="select2-selection"
                        styles={{
                          menu: base => ({
                            ...base,
                            position: "absolute",
                            zIndex: 9999,
                          }),
                        }}
                        defaultValue={user?.student?.district?.region?.uid}
                        defaultInputValue={
                          user?.student?.district?.region?.name
                        }
                      />
                      <Input
                        name="regiodUid"
                        placeholder="Select Region"
                        type="hidden"
                        rows={2}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={
                          validation.values.regiodUid || user?.regiodUid || ""
                        }
                        invalid={
                          !!(
                            validation.touched.regiodUid &&
                            validation.errors.regiodUid
                          )
                        }
                      />
                      {validation.touched.regiodUid &&
                      validation.errors.regiodUid ? (
                        <FormFeedback type="invalid">
                          {validation.errors.regiodUid}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col lg="4">
                    <div className="mb-3">
                      <Label>District</Label>
                      <Select
                        onChange={e => {
                          validation.setFieldValue("districtUid", e.value)
                        }}
                        name="districtUid"
                        options={districts?.map(dp => ({
                          value: dp?.uid,
                          label: dp?.name,
                        }))}
                        className="select2-selection"
                        styles={{
                          menu: base => ({
                            ...base,
                            position: "absolute",
                            zIndex: 9999,
                          }),
                        }}
                        defaultValue={user?.student?.district?.uid}
                        defaultInputValue={user?.student?.district?.name}
                      />
                      <Input
                        name="districtUid"
                        placeholder="Select District"
                        type="hidden"
                        rows={2}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={
                          validation.values.districtUid ||
                          user?.student?.district?.uid ||
                          ""
                        }
                        invalid={
                          !!(
                            validation.touched.districtUid &&
                            validation.errors.districtUid
                          )
                        }
                      />
                      {validation.touched.districtUid &&
                      validation.errors.districtUid ? (
                        <FormFeedback type="invalid">
                          {validation.errors.districtUid}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg="4">
                    <div className="mb-3">
                      <Label>Bank name</Label>
                      <Select
                        onChange={e => {
                          validation.setFieldValue("bankUid", e.value)
                        }}
                        name="bankUid"
                        options={banks?.map(bank => ({
                          value: bank?.uid,
                          label: bank?.name,
                        }))}
                        className="select2-selection"
                        styles={{
                          menu: base => ({
                            ...base,
                            position: "absolute",
                            zIndex: 9999,
                          }),
                        }}
                        defaultValue={user?.student?.bank?.uid}
                        defaultInputValue={user?.student?.bank?.name}
                      />
                      <Input
                        name="bankUid"
                        placeholder="Select Region"
                        type="hidden"
                        rows={2}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.bankUid || user?.bankUid || ""}
                        invalid={
                          !!(
                            validation.touched.bankUid &&
                            validation.errors.bankUid
                          )
                        }
                      />
                      {validation.touched.bankUid &&
                      validation.errors.bankUid ? (
                        <FormFeedback type="invalid">
                          {validation.errors.bankUid}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  <Col lg="4">
                    <div className="mb-3">
                      <Label for="accountNumber">Bank Account Number</Label>
                      <Input
                        name="accountNumber"
                        placeholder="Enter Bank Account Number"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.accountNumber || ""}
                        invalid={
                          !!(
                            validation.touched.accountNumber &&
                            validation.errors.accountNumber
                          )
                        }
                        defaultValue={user?.student?.accountNumber}
                      />
                      {validation.touched.accountNumber &&
                      validation.errors.accountNumber ? (
                        <FormFeedback type="invalid">
                          {validation.errors.accountNumber}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  <Col lg="4">
                    <div className="mb-3">
                      <label>Select Financial Sponsor</label>
                      <Select
                        onChange={selectedOptions => {
                          const selectedValues = selectedOptions.map(
                            option => ({
                              sponsorUid: option.value,
                            })
                          )
                          validation.setFieldValue("sponsors", selectedValues)
                        }}
                        name="sponsors"
                        options={sponsors?.map(sponsor => ({
                          value: sponsor?.uid,
                          label: sponsor?.name,
                        }))}
                        isMulti={true} // Enable multiple selections
                        className="select2-selection"
                        styles={{
                          menu: base => ({
                            ...base,
                            position: "absolute",
                            zIndex: 9999,
                          }),
                        }}
                      />
                      <Input
                        name="sponsors" // Update the field name
                        placeholder="Select Financial Sponsor"
                        type="hidden"
                        rows={2}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={JSON.stringify(
                          validation.values.sponsors || user?.sponsors || []
                        )} // Set the value as a JSON string
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col lg="6">
                    <div className="mb-3">
                      <Label for="postalAddress">Postal Address</Label>
                      <Input
                        name="postalAddress"
                        placeholder="Enter your postal address"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.postalAddress || ""}
                        invalid={
                          !!(
                            validation.touched.postalAddress &&
                            validation.errors.postalAddress
                          )
                        }
                        defaultValue={user?.student?.bank?.uid}
                      />
                      {validation.touched.postalAddress &&
                      validation.errors.postalAddress ? (
                        <FormFeedback type="invalid">
                          {validation.errors.postalAddress}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="mb-3">
                      <Label for="physicalAddress">Physical Address</Label>
                      <Input
                        name="physicalAddress"
                        placeholder="Enter your physical address"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.physicalAddress || ""}
                        invalid={
                          !!(
                            validation.touched.physicalAddress &&
                            validation.errors.physicalAddress
                          )
                        }
                      />
                      {validation.touched.physicalAddress &&
                      validation.errors.physicalAddress ? (
                        <FormFeedback type="invalid">
                          {validation.errors.physicalAddress}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <div className="wizard">
                  <div className="actions">
                    <ul>
                      <li className="next">
                        <SubmitButtonHelper
                          type="primary"
                          name="Submit"
                          formik={validation}
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </LayoutHelper>
  )
}
export default SelfRegistrationContactInformation
