import PropTypes from "prop-types"
import React from "react"
import { useSelector } from "react-redux"
import {Route, Routes, useNavigate} from "react-router-dom"
import { layoutTypes } from "./constants/layout"

// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./Routes"
// Import all middleware
import Authmiddleware from "./Routes/route"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"
import "./assets/scss/theme.scss"
import EsbDashboardLayout from "./components/EsbDashboardLayout/EsbDashboardLayout"
import {ServicesCards} from "./components/EsbDashboardLayout/ESBServices";
import StudentIdIcon from "./assets/images/svg/id.png";
import {jwtDecode} from "jwt-decode";


const getLayout = (layoutType) => {
  let Layout = VerticalLayout;
  switch (layoutType) {
    case layoutTypes.VERTICAL:
      Layout = VerticalLayout;
      break;
    case layoutTypes.HORIZONTAL:
      Layout = HorizontalLayout;
      break;
    default:
      break;
  }
  return Layout;
};


const App = () => {
  const accessToken = JSON.parse(localStorage.getItem("authUser"))?.accessToken
  const userData = accessToken ? jwtDecode(accessToken) : null
  const userAuthorities = userData?.authorities || []
  const hasAuthority = authority => userAuthorities.includes(authority)
  const navigate = useNavigate();
  const { layoutType } = useSelector((state) => ({
    layoutType: state.Layout.layoutType,
  }));
  const Layout = getLayout(layoutType);
  const activePath = location.pathname.split('/')[1];

  const link = ServicesCards.filter(card => card.link.startsWith(`/${activePath}/`));
  // console.log(link)
  const filteredLinks = link.length > 0 ? link[0]: {
    image: StudentIdIcon,
    link: location.pathname,
    title: activePath,
    shortName: activePath,
    authority: null
  }
  if(activePath && activePath !== 'esb-dashboard'){
    if(filteredLinks.authority){
      if(!hasAuthority(filteredLinks.authority)){
        navigate('/esb-dashboard')
      }
    }
  }

  // console.log(filteredLinks)
  return (
    <React.Fragment>
      <Routes>
        {publicRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <NonAuthLayout>
                {route.component}
              </NonAuthLayout>
            }
            key={idx}
            exact={true}
          />
        ))}

        {authProtectedRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={route.path === "/esb-dashboard" ?
              (
                <Authmiddleware>
                  <EsbDashboardLayout>{route.component}</EsbDashboardLayout>
                </Authmiddleware>
              )
              :
              (
                <Authmiddleware>
                <Layout>{route.component}</Layout>
              </Authmiddleware>
              )
            }
            key={idx}
          />
        ))}
      </Routes>
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any
};

export default App;
