import React, { useContext, useRef, useState, useEffect } from "react"
import { Modal, Form, Input, FormFeedback, Button, Label } from "reactstrap"
import * as Yup from "yup"
import { CREATE_ROOM_REQUEST } from "../Mutations/AccommodationMutations"
import { useFormik } from "formik"
import { useMutation, useLazyQuery, useQuery } from "@apollo/client"
import showToast from "helpers/ToastHelper"
import { GET_STUDENT_ROOM_REQUESTS } from "../Queries/AccommodationQueries"
import { MyAccommodationContext } from "./MyAccommodation"
import { GET_STUDENTS } from "Modules/Students/Queries/StudentsQueries"

import {
  createUpdateFunction,
  paginationHelper,
} from "helpers/Functions/GraphqlUpdateFunction"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import {
  accomodationGraphQLClient,
  onlineApplicationGraphQLClient,
  registrationGraphQLClient,
  uaaGraphQLClient,
} from "Modules/Authentication/ApolloClient"
import Select from "react-select"
import { GET_APPLICANT_DETAILS } from "../../Applicant/Queries/ApplicantQueries"
import PlaceHolderLoader from "../../../helpers/PlaceHolderLoader"

const RoomRequestModal = () => {
  const { studentInfo, studentProgram } = useContext(MyAccommodationContext)
  const [create_room_request] = useMutation(CREATE_ROOM_REQUEST, {
    client: accomodationGraphQLClient,
  })
  const [modalOpen, setModalOpen] = useState(false)
  const ExtractUserAuth = JSON.parse(localStorage.getItem("authUser"))?.data
  const formRef = useRef()

  const resetForm = () => {
    if (formRef.current) {
      formRef.current.resetForm()
    }
  }

  const {
    loading: applicantLoading,
    error: applicantError,
    data: applicantData,
  } = useQuery(GET_APPLICANT_DETAILS, {
    client: onlineApplicationGraphQLClient,
    skip: !modalOpen,
    variables: {
      userUid: ExtractUserAuth?.uid,
    },
    fetchPolicy: "network-only",
  })

  const applicant = applicantData?.getApplicantDetails.data

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      studentUid: studentInfo?.student?.uid,
      programUid: studentInfo?.student?.programmeUid,
      gender: studentInfo?.gender,
      campusCode: applicant?.campusCode,
      studyYear: studentInfo?.student?.studyYear,
    },

    validationSchema: Yup.object().shape({
      studentUid: Yup.string().required(
        "Student identification must be submitted"
      ),
      programUid: Yup.string().required("Program category must be submitted"),
      gender: Yup.string().required("Student gender must be submitted"),
    }),

    onSubmit: (values, { setSubmitting }) => {
      create_room_request({
        variables: {
          input: { ...values },
        },
        client: accomodationGraphQLClient,
        refetchQueries: [
          {
            query: GET_STUDENT_ROOM_REQUESTS,
            variables: {
              studentUid: studentInfo.student.uid,
            },
          },
        ],
        onCompleted: ({ registerRoomRequests }) => {
          if (registerRoomRequests.code === 8000) {
            validation.resetForm()
            setModalOpen(false)
          } else {
            setModalOpen(true)
            setSubmitting(false)
          }
          showToast(
            registerRoomRequests.message,
            registerRoomRequests.code === 8000 ? "success" : "error"
          )
        },
        onError: error => {
          setSubmitting(false)
          if (process.env.REACT_APP_DEBUG_MODE) {
            console.error("Mutation error:", error)
          }
        },
      })
    },
  })
  return (
    <div className="text-sm-end ms-auto">
      <Button
        type="button"
        color="primary"
        className="btn btn-primary btn-sm btn-rounded"
        onClick={() => setModalOpen(true)}
      >
        <i className="mdi mdi-plus me-1" />
        Request
      </Button>

      <Modal
        isOpen={modalOpen}
        backdrop={"static"}
        id="staticBackdrop"
        size="lg"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            {!"New Room Request"}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setModalOpen(false)
            }}
            aria-label="Close"
          ></button>
        </div>
        {applicantLoading ? (
          <PlaceHolderLoader rows={4} type="table" columSize={12} />
        ) : (
          <Form
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return null
            }}
          >
            <div className="modal-body">
              <div className="mb-1">
                <Label>
                  Student <span className="text-danger">*</span>:
                </Label>
                <Input
                  required
                  value={studentInfo?.student?.registrationNumber}
                  disabled
                />
              </div>

              <div className="mb-1">
                <label htmlFor="programUid" className="col-form-label">
                  Program <span className="text-danger">*</span>:
                </label>
                <Input required value={studentProgram?.name} disabled />
                {validation.touched.programUid &&
                validation.errors.programUid ? (
                  <FormFeedback type="invalid">
                    {validation.errors.programUid}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-1">
                <label htmlFor="gender" className="col-form-label">
                  Gender<span className="text-danger">*</span>:
                </label>
                <Input required value={studentInfo?.gender} disabled />
                {validation.touched.gender && validation.errors.gender ? (
                  <FormFeedback type="invalid">
                    {validation.errors.gender}
                  </FormFeedback>
                ) : null}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => {
                  setModalOpen(false)
                  resetForm() // Reset the form
                }}
              >
                Close
              </button>
              <SubmitButtonHelper
                name="Submit"
                type="primary"
                formik={validation}
              />
            </div>
          </Form>
        )}
      </Modal>
    </div>
  )
}

export default RoomRequestModal
