import React, { useContext, useState } from "react"
import { useMutation } from "@apollo/client"
import { showStartSelfRegistrationAlert } from "../../../../helpers/DeleteWaringHelper"
import { MOVE_APPLICANT_TO_STUDENT } from "../Mutations/ApplicantRegistrationForSelected"
import { onlineApplicationGraphQLClient } from "../../../Authentication/ApolloClient"
import { OnlineDashboardContext } from "../../../Dashboard/OnlineApplicationDashboard"
import showToast from "../../../../helpers/ToastHelper"
import logout from "../../../Authentication/Logout"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { logoutUser } from "../../../../store/auth/login/actions"
import GlobalLoader from "../../../Dashboard/GlobalLoader"

const ApplicantRegistrationForSelectedOne = () => {
  const history = useNavigate()
  const dispatch = useDispatch()

  const performLogout = () => {
    logout()
    showToast("Logged out successfully", "success")
  }

  const [loading, setLoading] = useState(false)
  const { applicant } = useContext(OnlineDashboardContext)

  const [registerApplicant] = useMutation(MOVE_APPLICANT_TO_STUDENT, {
    client: onlineApplicationGraphQLClient,
  })

  const handleStartSelfRegistration = () => {
    showStartSelfRegistrationAlert(() => {
      setLoading(true)
      registerApplicant({
        variables: {
          applicantUid: applicant?.uid,
          programmeCode: applicant?.suaProgrammeCode,
        },
        onCompleted: async ({ moveApplicantToStudent }) => {
          setLoading(false)
          if (moveApplicantToStudent?.code === 8000) {
            showToast(moveApplicantToStudent?.message, "success")
            dispatch(logoutUser(history))
          } else {
            showToast(moveApplicantToStudent?.message, "error")
          }
        },
      })
    })
  }

  return (
    <>
      <div className="alert alert-info">
        <h4 className="text-center" style={{ textDecoration: "underline" }}>
          IMPORTANT NOTE FOR SELECTED APPLICANT!
        </h4>
        <p style={{ textAlign: "justify" }}>
          <b>Welcome to Sokoine University of Agriculture!</b> <br /> We are
          pleased to inform you that you have been selected to join Sokoine University of Agriculture (SUA). To secure your place at SUA, please begin the registration process promptly.
        </p>
        <p style={{ textAlign: "justify" }}>
          Click on the <b>"Start Self Registration"</b> button below to begin.
        </p>
      </div>

      <p>
        <button
          disabled={loading}
          onClick={() => handleStartSelfRegistration()}
          className="btn btn-block btn-primary"
        >
          {loading ? "Please wait..." : "Start Self Registration"}
        </button>
      </p>

      {loading && <GlobalLoader />}
    </>
  )
}
export default ApplicantRegistrationForSelectedOne
