import { gql } from "@apollo/client"

const GENERATE_ALLOCATION_XLS = gql`
    mutation generateAllocationXlsTemplate(
        $allocationUid: String!
        $outOff: Int!
        $examCategoryId: Int!
        $assessmentNumber: Int!
        $assessmentWeight: Int!
        $excelSorting: String!
        $isTheoryProbation: Boolean!
        $isPracticalProbation: Boolean!
    ) {
        generateAllocationXlsTemplate(
            isTheoryProbation: $isTheoryProbation
            isPracticalProbation: $isPracticalProbation
            allocationUid: $allocationUid
            outOff: $outOff
            examCategoryId: $examCategoryId
            assessmentNumber: $assessmentNumber
            assessmentWeight: $assessmentWeight
            excelSorting: $excelSorting
        ) {
            base64Data
        }
    }
`

const FORWARD_EXAM_BY_PROGRAM_ID = gql`
  mutation forwardByProgramID($programCourseId: String!, $stage: String!) {
    status
    code
    message
    data
  }
`

const UPLOAD_EXAM_SCORE_RESULT_BY_EXCEL = gql`
  mutation UploadScore($base64File: String!) {
    uploadScore(base64File: $base64File) {
      status
      code
      message
      data {
        success
        failed
        failedStudents {
          regNumber
          reason
        }
        successStudents {
          regNumber
        }
      }
    }
  }
`

const FORWARD_INSTRUCTOR_COURSE_RESULT = gql`
  mutation ForwardInstructorCourseResult($programCourseUids: [String!]!) {
    forwardInstructorCourseResult(programCourseUids: $programCourseUids) {
      status
      code
      message
      data
    }
  }
`

const UPLOAD_EXAM_SCORE_RESULT_ONLINE = gql`
  mutation UploadOnlineScore($inputs: UploadInput!) {
    uploadOnlineScore(inputs: $inputs) {
      status
      code
      message
      data {
        success
        failed
        failedStudents {
          regNumber
          reason
        }
        successStudents {
          regNumber
        }
      }
    }
  }
`

const APPROVE_STUDY_POSTPONEMENT_REQUEST = gql`
  mutation ApproveStudyPostponementRequest($inputs: UploadInput!) {
    approveStudyPostponementRequest(inputs: $inputs) {
      status
      code
      message
      data {
        success
        failed
        failedStudents {
          regNumber
          reason
        }
        successStudents {
          regNumber
        }
      }
    }
  }
`

const RECORD_APPROVAL_ACTION = gql`
  mutation RecordApprovalAction(
    $requestId: ID!
    $approverId: ID!
    $action: String!
  ) {
    recordApprovalAction(
      requestId: $requestId
      approverId: $approverId
      action: $action
    ) {
      approvalId
      requestId
      approverId
      action
      timestamp
    }
  }
`

const REJECT_STUDY_POSTPONEMENT_REQUEST = gql`
  mutation RejectStudyPostponementRequest($inputs: UploadInput!) {
    rejectStudyPostponementRequest(inputs: $inputs) {
      status
      code
      message
      data {
        success
        failed
        failedStudents {
          regNumber
          reason
        }
        successStudents {
          regNumber
        }
      }
    }
  }
`

const APPROVE_EXAM_POSTPONEMENT_REQUEST = gql`
  mutation ApproveExamPostponementRequest($inputs: UploadInput!) {
    approveExamPostponementRequest(inputs: $inputs) {
      status
      code
      message
      data {
        success
        failed
        failedStudents {
          regNumber
          reason
        }
      }
    }
  }
`

const REJECT_EXAM_POSTPONEMENT_REQUEST = gql`
  mutation RejectExamPostponementRequest($inputs: UploadInput!) {
    rejectExamPostponementRequest(inputs: $inputs) {
      status
      code
      message
      data {
        success
        failed
        failedStudents {
          regNumber
          reason
        }
      }
    }
  }
`

export {
  GENERATE_ALLOCATION_XLS,
  UPLOAD_EXAM_SCORE_RESULT_BY_EXCEL,
  UPLOAD_EXAM_SCORE_RESULT_ONLINE,
  APPROVE_STUDY_POSTPONEMENT_REQUEST,
  REJECT_STUDY_POSTPONEMENT_REQUEST,
  APPROVE_EXAM_POSTPONEMENT_REQUEST,
  REJECT_EXAM_POSTPONEMENT_REQUEST,
  FORWARD_EXAM_BY_PROGRAM_ID,
  RECORD_APPROVAL_ACTION,
  FORWARD_INSTRUCTOR_COURSE_RESULT
}
