import { gql } from "@apollo/client"

const GET_APPLICANT_COUNT_BY_DATE = gql`
    query GetApplicantCountByDate{
        getApplicantCountByDate{
            status
            code
            message
            data{
                totalCount
                items{
                    date
                    count
                    applicants{
                        uid
                        formFourIndexNumber
                        firstName
                        middleName
                        lastName
                        phoneNumber
                        isPaid
                        email
                    }
                }
            }
        }
    }
`

const GET_APPLICANT_COUNT_BY_DATE_GRAPH = gql`
    query GetApplicantCountByDate{
        getApplicantCountByDate{
            status
            code
            message
            data{
                totalCount
                items{
                    date
                    count
                }
            }
        }
    }
`

const GET_APPLICANT_SUBMISSION_STATUS_REPORT = gql`
    query GetApplicantSubmissionReport($submissionType: Int!){
        getApplicantSubmissionReport(submissionType: $submissionType){
            status
            code
            message
            data{
                uid
                firstName
                middleName
                lastName
                submissionStatus
                controlNumber
                isPaid
                email
                formFourIndexNumber
                phoneNumber
                admissionYear
            }
        }
    }
`

const GET_APPLICANT_COUNT_BY_PROGRAM = gql`
    query GetApplicantCountByProgram($admissionWindowUid: String){
        getApplicantCountByProgram(admissionWindowUid: $admissionWindowUid){
            status
            code
            message
            data{
                items{
                    programName
                    programCode
                    countSelectedMale
                    countSelectedFemale
                    countSelectedTotal
                    countAllMale
                    countAllFemale
                    countMultipleTotal
                    countMultipleFemale
                    countMultipleMale
                    countSingleMale
                    countSingleFemale
                    countSingleTotal
                    countSingleTotal
                    countAllTotal
                }
                totalCount
            }
        }
    }
`
export {
  GET_APPLICANT_COUNT_BY_DATE,
  GET_APPLICANT_COUNT_BY_PROGRAM,
  GET_APPLICANT_COUNT_BY_DATE_GRAPH,
  GET_APPLICANT_SUBMISSION_STATUS_REPORT
}