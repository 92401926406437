import { UPDATE_FORM_FOUR_STATUS } from "../Actions/FormFourActionTypes"

const initialState = {
  formForResults: null, // or true, depending on your app's logic
};

// Sidebar reducer
const formFormIndexNumberReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_FORM_FOUR_STATUS:
      return {
        ...state,
        formForResults: action.payload,
      };
    default:
      return state;
  }
};

export default formFormIndexNumberReducer;
