import { AUTH_USER_DATA } from "../../Actions/AuthUserAction"

const initialState = {
  data: null,
};

const authUserDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_USER_DATA:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export default authUserDataReducer;
