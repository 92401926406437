import React, { createContext, useContext, useEffect, useState } from "react"
import {
  Modal,
  Form,
  Input,
  FormFeedback,
  Label,
  Row,
  Col,
  Card,
  CardBody
} from "reactstrap"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { useMutation } from "@apollo/client"
import showToast from "helpers/ToastHelper"
import { limit } from "helpers/UrlHelper"
import { useFormik } from "formik"
import Select from "react-select"
import * as Yup from "yup"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import { registrationGraphQLClient } from "Modules/Authentication/ApolloClient"
import { GENERATE_ALLOCATION_XLS } from "../Mutations/InstructorMutations"
import { base64toBlob } from "./base64toBlob"
import { InstructorCourseDetailsContext } from "./InstructorCourseDetail"
import { CourseWeights, courseMarkedOut, ExcelSortingFormat } from "./CourseWeight"

const CourseExcelDownloder = ({ isOpen, closeModal }) => {

  const {
    examCategoryID, examCategoryName, assessmentNumber, allocationUID,
    examType,
    courseData,
    setExamType
  } =
    useContext(InstructorCourseDetailsContext) // use context to retrieve data from Instructor

  const courseWeight = CourseWeights() // course weight

  const markedOut = courseMarkedOut() // Marked out

  const excelFormat = ExcelSortingFormat()

  const [templateDownload] = useMutation(GENERATE_ALLOCATION_XLS, {
    client: registrationGraphQLClient
  })

  const optionForExamType = [
    {
      label: "Theory probation exam",
      value: 1
    },
    {
      label: "Practical probation exam",
      value: 2
    }
  ]

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      outOff: "",
      assessmentWeight: 1,
      excelSorting: "REGNO"

    },

    validationSchema: Yup.object().shape({
      outOff: Yup.number().required("Out Of value is required"),
      assessmentWeight: Yup.number().required("Weight is required")
      // excelSorting: Yup.number().required("Excel Sorting Option is required"),
    }),

    onSubmit: async (values, { setSubmitting }) => {
      try {
        // console.log("Data to post For Excel: ", assessmentNumber + " " + allocationUID + " " + examCategoryID + " " + examCategoryName + " " + values.outOff)
        // return
        const { data, error } = await templateDownload({
          variables: {
            allocationUid: allocationUID,
            outOff: values.outOff,
            isTheoryProbation: false,
            isPracticalProbation: false,
            examCategoryId: examCategoryID,
            assessmentWeight: parseInt(values.assessmentWeight),
            assessmentNumber: assessmentNumber,
            excelSorting: values.excelSorting
          }
        })

        const base64Data = data?.generateAllocationXlsTemplate?.base64Data

        if (base64Data) {
          const blob = base64toBlob(base64Data)
          const url = URL.createObjectURL(blob)
          const link = document.createElement("a")
          link.href = url
          // link.setAttribute("download", "template.xlsx")
          link.setAttribute("download", examCategoryName + ".xlsx")
          closeModal()
          validation.resetForm()
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          URL.revokeObjectURL(url)
        } else {
          console.error("Base64 data not found in the response")
        }
      } catch (error) {
        showToast("Error while generating template:", error)
        console.error("Error while generating template:", error)
      }
      console.log(values)
    }
  })

  const validationForProbation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      outOff: "",
      examTypeOption : null,
      assessmentWeight: 1,
      excelSorting: "REGNO"

    },

    validationSchema: Yup.object().shape({
      outOff: Yup.number().required("Out Of value is required"),
      assessmentWeight: Yup.number().required("Weight is required"),
      examTypeOption: Yup.number().required("This field is required"),
      // excelSorting: Yup.number().required("Excel Sorting Option is required"),
    }),

    onSubmit: async (values, { setSubmitting }) => {
      console.log(values)
      try {
        // console.log("Data to post For Excel: ", assessmentNumber + " " + allocationUID + " " + examCategoryID + " " + examCategoryName + " " + values.outOff)
        // return
        const isTheory = values?.examTypeOption === 1 ? true : false
        const iPractical  = validationForProbation?.values?.examTypeOption === 2 ? true : false
        const { data, error } = await templateDownload({
          variables: {
            allocationUid: allocationUID,
            outOff: values.outOff,
            examCategoryId: 0,
            isTheoryProbation: isTheory,
            isPracticalProbation: iPractical,
            assessmentWeight: parseInt(values.assessmentWeight),
            assessmentNumber: 1,
            excelSorting: values.excelSorting
          }
        })

        const base64Data = data?.generateAllocationXlsTemplate?.base64Data

        if (base64Data) {
          const blob = base64toBlob(base64Data)
          const url = URL.createObjectURL(blob)
          const link = document.createElement("a")
          link.href = url
          // link.setAttribute("download", "template.xlsx")
          link.setAttribute("download",  courseData?.getCourseAllocation?.data
            ?.programCourse?.course?.code + "_PROBATION_TEMPLATE.xlsx")
          closeModal()
          validation.resetForm()
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          URL.revokeObjectURL(url)
        } else {
          console.error("Base64 data not found in the response")
        }
      } catch (error) {
        showToast("Error while generating template:", error)
        console.error("Error while generating template:", error)
      }
      console.log(values)
    }
  })
  return (
    <Modal isOpen={isOpen || !!""} backdrop={"static"} id="staticBackdrop">
      <div className="modal-header">
        <h5 className="modal-title" id="staticBackdropLabel">
          {examType === "PROBATION" ? "Probation Exam marked out of? " : "Exam marked out of?"}
        </h5>
        <button
          type="button"
          className="btn-close"
          onClick={() => {
            closeModal()
            setExamType("")
            validation.resetForm()
          }}
          aria-label="Close"
        ></button>
      </div>
      {
        examType === "PROBATION" ? (
          <Form
            onSubmit={e => {
              e.preventDefault()
              validationForProbation.handleSubmit()
              return null
            }}
          >
            <div className="modal-body">
              <div className="mb-1">
                <label htmlFor="examTypeOption" className="col-form-label">
                  Probation exam type
                </label>
                <Select
                  id="outOff"
                  name="examTypeOption"
                  onChange={e => {
                    validationForProbation.setFieldValue("examTypeOption", e.value)
                  }}
                  options={optionForExamType}
                  className="select2-selection"
                  styles={{
                    menu: base => ({
                      ...base,
                      position: "absolute",
                      zIndex: 9999
                    })
                  }}
                />
                <Input
                  type="hidden"
                  name="examTypeOption"
                  className="form-control"
                  id="firstname"
                  placeholder="Enter Out of Mark"
                  onChange={validationForProbation.handleChange}
                  onBlur={validationForProbation.handleBlur}
                  value={validationForProbation.values.examTypeOption || ""}
                  invalid={
                    validationForProbation.touched.examTypeOption && validationForProbation.errors.examTypeOption
                      ? true
                      : false
                  }
                />
                {validationForProbation.touched.examTypeOption && validationForProbation.errors.examTypeOption ? (
                  <FormFeedback type="invalid">
                    {validationForProbation.errors.examTypeOption}
                  </FormFeedback>
                ) : null}
              </div>
            </div>
            <div className="modal-body">
              <div className="mb-1">
                <label htmlFor="name" className="col-form-label">
                  Select Mark Out Of
                </label>
                <Select
                  id="outOff"
                  name="outOff"
                  onChange={e => {
                    validationForProbation.setFieldValue("outOff", e.value)
                  }}
                  options={markedOut}
                  className="select2-selection"
                  styles={{
                    menu: base => ({
                      ...base,
                      position: "absolute",
                      zIndex: 9999
                    })
                  }}
                />
                <Input
                  type="hidden"
                  name="outOff"
                  className="form-control"
                  id="firstname"
                  placeholder="Enter Out of Mark"
                  onChange={validationForProbation.handleChange}
                  onBlur={validationForProbation.handleBlur}
                  value={validationForProbation.values.outOff || ""}
                  invalid={
                    validationForProbation.touched.outOff && validationForProbation.errors.outOff
                      ? true
                      : false
                  }
                />
                {validationForProbation.touched.outOff && validationForProbation.errors.outOff ? (
                  <FormFeedback type="invalid">
                    {validationForProbation.errors.outOff}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-1">
                <label htmlFor="assessmentWeight" className="col-form-label">
                  Weight
                </label>
                <Select
                  id="assessmentWeight"
                  name="assessmentWeight"
                  onChange={e => {
                    validationForProbation.setFieldValue("assessmentWeight", e.value)
                  }}
                  options={courseWeight}
                  className="select2-selection"
                  styles={{
                    menu: base => ({
                      ...base,
                      position: "absolute",
                      zIndex: 9999
                    })
                  }}
                  defaultInputValue="1"
                  defaultValue={parseInt(1)}
                />
                <Input
                  type="hidden"
                  name="assessmentWeight"
                  className="form-control"
                  id="assessmentWeight"
                  placeholder="Enter Out of Mark"
                  onChange={validationForProbation.handleChange}
                  onBlur={validationForProbation.handleBlur}
                  value={validationForProbation.values.assessmentWeight || ""}
                  invalid={
                    validationForProbation.touched.assessmentWeight &&
                    validationForProbation.errors.assessmentWeight
                      ? true
                      : false
                  }
                />
                {validationForProbation.touched.assessmentWeight &&
                validationForProbation.errors.assessmentWeight ? (
                  <FormFeedback type="invalid">
                    {validationForProbation.errors.assessmentWeight}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-1">
                <label htmlFor="excelSorting" className="col-form-label">
                  Sort Excel By?
                </label>
                <Select
                  id="excelSorting"
                  name="excelSorting"
                  onChange={e => {
                    validationForProbation.setFieldValue("excelSorting", e.value)
                  }}
                  options={excelFormat}
                  className="select2-selection"
                  styles={{
                    menu: base => ({
                      ...base,
                      position: "absolute",
                      zIndex: 9999
                    })
                  }}
                />
                <Input
                  type="hidden"
                  name="excelSorting"
                  className="form-control"
                  id="excelSorting"
                  placeholder="Excel Sorting Selection"
                  onChange={validationForProbation.handleChange}
                  onBlur={validationForProbation.handleBlur}
                  value={validationForProbation.values.excelSorting || ""}
                  invalid={
                    validationForProbation.touched.excelSorting &&
                    validationForProbation.errors.excelSorting
                      ? true
                      : false
                  }
                />
                {validationForProbation.touched.excelSorting &&
                validationForProbation.errors.excelSorting ? (
                  <FormFeedback type="invalid">
                    {validationForProbation.errors.excelSorting}
                  </FormFeedback>
                ) : null}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => {
                  closeModal()
                  setExamType("")
                  validationForProbation.resetForm()
                }}
              >
                Close
              </button>
              <SubmitButtonHelper
                name="Submit"
                type="primary"
                formik={validationForProbation}
              />
            </div>
          </Form>
        ) : examType !== "PROBATION" &&(
          <Form
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return null
            }}
          >
            <div className="modal-body">
              <div className="mb-3">
                <label htmlFor="name" className="col-form-label">
                  Select Mark Out Of
                </label>
                <Select
                  id="outOff"
                  name="outOff"
                  onChange={e => {
                    validation.setFieldValue("outOff", e.value)
                  }}
                  options={markedOut}
                  className="select2-selection"
                  styles={{
                    menu: base => ({
                      ...base,
                      position: "absolute",
                      zIndex: 9999
                    })
                  }}
                />
                <Input
                  type="hidden"
                  name="outOff"
                  className="form-control"
                  id="firstname"
                  placeholder="Enter Out of Mark"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.outOff || ""}
                  invalid={
                    validation.touched.outOff && validation.errors.outOff
                      ? true
                      : false
                  }
                />
                {validation.touched.outOff && validation.errors.outOff ? (
                  <FormFeedback type="invalid">
                    {validation.errors.outOff}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <label htmlFor="assessmentWeight" className="col-form-label">
                  Weight
                </label>
                <Select
                  id="assessmentWeight"
                  name="assessmentWeight"
                  onChange={e => {
                    validation.setFieldValue("assessmentWeight", e.value)
                  }}
                  options={courseWeight}
                  className="select2-selection"
                  styles={{
                    menu: base => ({
                      ...base,
                      position: "absolute",
                      zIndex: 9999
                    })
                  }}
                  defaultInputValue="1"
                  defaultValue={parseInt(1)}
                />
                <Input
                  type="hidden"
                  name="assessmentWeight"
                  className="form-control"
                  id="assessmentWeight"
                  placeholder="Enter Out of Mark"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.assessmentWeight || ""}
                  invalid={
                    validation.touched.assessmentWeight &&
                    validation.errors.assessmentWeight
                      ? true
                      : false
                  }
                />
                {validation.touched.assessmentWeight &&
                validation.errors.assessmentWeight ? (
                  <FormFeedback type="invalid">
                    {validation.errors.assessmentWeight}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <label htmlFor="excelSorting" className="col-form-label">
                  Sort Excel By?
                </label>
                <Select
                  id="excelSorting"
                  name="excelSorting"
                  onChange={e => {
                    validation.setFieldValue("excelSorting", e.value)
                  }}
                  options={excelFormat}
                  className="select2-selection"
                  styles={{
                    menu: base => ({
                      ...base,
                      position: "absolute",
                      zIndex: 9999
                    })
                  }}
                />
                <Input
                  type="hidden"
                  name="excelSorting"
                  className="form-control"
                  id="excelSorting"
                  placeholder="Excel Sorting Selection"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.excelSorting || ""}
                  invalid={
                    validation.touched.excelSorting &&
                    validation.errors.excelSorting
                      ? true
                      : false
                  }
                />
                {validation.touched.excelSorting &&
                validation.errors.excelSorting ? (
                  <FormFeedback type="invalid">
                    {validation.errors.excelSorting}
                  </FormFeedback>
                ) : null}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => {
                  closeModal()
                  validation.resetForm()
                }}
              >
                Close
              </button>
              <SubmitButtonHelper
                name="Submit"
                type="primary"
                formik={validation}
              />
            </div>
          </Form>
        )
      }
    </Modal>
  )
}

export default CourseExcelDownloder
