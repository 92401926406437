import { UPDATE_SELECTED_STATUS } from "../Actions/ActionTypes";

// Initial state for sidebar reducer
const initialState = {
  selectedStatus: false, // or true, depending on your app's logic
};

// Sidebar reducer
const sidebarReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SELECTED_STATUS:
      return {
        ...state,
        selectedStatus: action.payload,
      };
    default:
      return state;
  }
};

export default sidebarReducer;
