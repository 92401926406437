// import React from "react"
// import Card from "../../../components/Common/Card"
// import { jwtDecode } from "jwt-decode"
// import {ServicesCards} from "../../../components/EsbDashboardLayout/ESBServices";
// import showToast from "../../../helpers/ToastHelper";
// import {useNavigate} from "react-router-dom";
// const EsbDashboard = () => {
//     const navigate = useNavigate()
//     const accessToken = JSON.parse(localStorage.getItem("authUser"))?.accessToken
//     const userData = accessToken ? jwtDecode(accessToken) : null
//     const userAuthorities = userData?.authorities || []
//     const hasAuthority = authority => userAuthorities.includes(authority)
//     const visibleSystems = ServicesCards.filter(system =>
//         userAuthorities.includes(system.authority)
//     );
//     if(visibleSystems.length === 1) {
//         navigate(`${visibleSystems[0].link}`)
//     }
//   return (
//     <div className="card-wrapper">
//       <h2>Services</h2>
//       <div className="row card-container">
//         {ServicesCards.map((card, index) => {
//           if (card.authority && !hasAuthority(card.authority)) {
//             return null;
//           }
//           return (
//             <Card
//               key={index}
//               card_image={card.image}
//               link={card.link}
//               title={card.title}
//             />
//           );
//         })}
//       </div>
//     </div>
//   );
// }
//
// export default EsbDashboard

// import React from "react"
// import Card from "../../../components/Common/Card"
// import { jwtDecode } from "jwt-decode"
// import {ServicesCards} from "../../../components/EsbDashboardLayout/ESBServices";
// import showToast from "../../../helpers/ToastHelper";
// import {useNavigate} from "react-router-dom";
// const EsbDashboard = () => {
//     const navigate = useNavigate()
//     const accessToken = JSON.parse(localStorage.getItem("authUser"))?.accessToken
//     const userData = accessToken ? jwtDecode(accessToken) : null
//     const userAuthorities = userData?.authorities || []
//     const hasAuthority = authority => userAuthorities.includes(authority)
//     const visibleSystems = ServicesCards.filter(system =>
//         userAuthorities.includes(system.authority)
//     );
//     if(visibleSystems.length === 1) {
//         navigate(`${visibleSystems[0].link}`)
//     }
//   return (
//     <div className="card-wrapper">
//       <h2>Services</h2>
//       <div className="row card-container">
//         {ServicesCards.map((card, index) => {
//           if (card.authority && !hasAuthority(card.authority)) {
//             return null;
//           }
//           return (
//             <Card
//               key={index}
//               card_image={card.image}
//               link={card.link}
//               title={card.title}
//             />
//           );
//         })}
//       </div>
//     </div>
//   );
// }
//
// export default EsbDashboard

import React, { useEffect } from "react"
import Card from "../../../components/Common/Card"
import { jwtDecode } from "jwt-decode"
import { ServicesCards } from "../../../components/EsbDashboardLayout/ESBServices"
import { useNavigate } from "react-router-dom"
import { useQuery } from "@apollo/client"
import { GET_STUDENT } from "../../Users/Queries/UserQuery"
import { uaaGraphQLClient } from "../../Authentication/ApolloClient"
import { useDispatch, useSelector } from "react-redux"
import { authUserData } from "../../Redux/FormResultsCheck/Actions/AuthUserAction"
import PlaceHolderLoader from "../../../helpers/PlaceHolderLoader"

const EsbDashboard = () => {
  const navigate = useNavigate()
  const accessToken = JSON.parse(localStorage.getItem("authUser"))?.accessToken
  const userData = accessToken ? jwtDecode(accessToken) : null
  const userAuthorities = userData?.authorities || []
  const hasAuthority = authority => userAuthorities.includes(authority)
  const dispatch = useDispatch()

  const {
    loading: userByUsernameLoading,
    error: userByusernameError,
    data: userByusernameData,
    userByusernameRefetch,
  } = useQuery(GET_STUDENT, {
    client: uaaGraphQLClient,
    skip: !JSON.parse(localStorage.getItem("authUser"))?.data?.username,
    variables: {
      username: JSON.parse(localStorage.getItem("authUser"))?.data?.username,
    },
    fetchPolicy: "cache-first",
  })
  const studentByUsername = userByusernameData?.getUserByUsername?.data

  useEffect(() => {
    if (studentByUsername) {
      dispatch(authUserData(studentByUsername))
    }
  }, [dispatch, studentByUsername])

  const authUser = useSelector(state => state.authUserDataReducer.data)

  const year = authUser?.student?.registrationNumber?.match(/\d{4}/)[0]

  const visibleSystems = ServicesCards.filter(system => {
    const hasRequiredAuthority = userAuthorities.includes(system.authority)
    const studentExists = authUser?.student

    if (studentExists) {
      if (system.shortName === "SELF-REG") {
        return (
          hasRequiredAuthority &&
          authUser.student.status.name === "UNREGISTERED"
        )
      }

      if (system.shortName === "ONLINE APPLICATION") {
        return hasRequiredAuthority && !authUser.student.status.name
      }

      if (system.shortName === "HOSTEL") {
        return (
          hasRequiredAuthority &&
          (year === "2024" || authUser.student.status.name === "CONTINUING")
        )
      }
    }
    return hasRequiredAuthority
  })

  if (visibleSystems.length === 1) {
    navigate(`${visibleSystems[0].link}`)
  }

  return (
    <div className="card-wrapper">
      <h2>Services</h2>
      {userByUsernameLoading ? (
        <PlaceHolderLoader rows={2} type="table" columSize={12} />
      ) : (
        <div className="row card-container">
          {visibleSystems.map((card, index) => {
            return (
              <Card
                key={index}
                card_image={card.image}
                link={card.link}
                title={card.title}
              />
            )
          })}
        </div>
      )}
    </div>
  )
}

export default EsbDashboard
