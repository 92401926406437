import React from "react"

import { Card, CardBody, Col, Row } from "reactstrap"
import { Link, useLocation } from "react-router-dom"

import { GET_STUDENT } from "Modules/Users/Queries/UserQuery"
import { useQuery } from "@apollo/client"
import {
  uaaGraphQLClient
} from "Modules/Authentication/ApolloClient"
import { jwtDecode } from "jwt-decode"
import PlaceHolderLoader from "../../helpers/PlaceHolderLoader"

const accessToken = JSON.parse(localStorage.getItem("authUser"))?.accessToken
const userData = accessToken ? jwtDecode(accessToken) : null
const userAuthorities = userData?.authorities || []
const hasAuthority = authority => userAuthorities.includes(authority)

const ApplicantDashboard = () => {
  const location = useLocation()
  const {
    loading: userLoading,
    error: userError,
    data: userDetails,
    refetch
  } = useQuery(GET_STUDENT, {
    client: uaaGraphQLClient,
    skip:
      !JSON.parse(localStorage.getItem("authUser"))?.data?.username,
    variables: {
      username: JSON.parse(localStorage.getItem("authUser"))?.data?.username
    },
    fetchPolicy: "network-only"
  })

  const student = userDetails?.getUserByUsername?.data


  const accessToken = JSON.parse(localStorage.getItem("authUser"))?.accessToken
  const userData = accessToken ? jwtDecode(accessToken) : null
  const userAuthorities = userData?.authorities || []

  const hasAuthority = authority => userAuthorities.includes(authority)

  return (
    <div className="container">
      <h1>Dashboard</h1>
      {location?.pathname === "/applicant/profile-information" && (
        <Card>
          <CardBody>
            <Row>
              {userLoading && (
                <Col md={12}>
                  <PlaceHolderLoader rows={4} type="table" columSize={12} />
                </Col>
              )}
              {
                !userLoading && (
                  <Col md={12}>
                    <h3>
                      Welcome:{" "}
                      {student?.firstName +
                        " " +
                        student?.middleName +
                        " " +
                        student?.lastName}
                    </h3>
                    <br />
                    <Link
                      to="/teaching-assessment/courses"
                      className="btn btn-sm btn-primary mt-4"
                    >
                      <span>Click here to start course assessment</span>
                    </Link>
                  </Col>
                )
              }
            </Row>
          </CardBody>
        </Card>
      )
      }

      {
        hasAuthority("VIEW_MY_ACCOMMODATION_MENU") && location?.pathname === "/accommodation/dashboard" && (
          <Row>
            <Col md={12}>
              <Card>
                <CardBody className="border border-2 border-primary" style={{ textAlign: "justify" }}>
                  <h5>Dear {student ?  student?.firstName + " " + student?.lastName : ""},</h5>
                  <p>
                    To ensure a smooth and comfortable experience at <b>Sokoine University of Agriculture</b>, it’s
                    essential to
                    secure your accommodation by completing your payment.
                  </p>
                  <p>
                    Our on-campus housing provides a safe, convenient, and vibrant living environment, making it easier to
                    focus on your studies and enjoy university life to the fullest.
                  </p>
                  <p>
                    If you haven’t yet paid for your accommodation, we encourage you to act now to avoid missing out on
                    the available spaces. Secure your place today and be part of a thriving campus community!
                  </p>
                  <Link
                    to="/accommodation/my-accommodation"
                    className="btn btn-sm btn-primary mt-4"
                  >
                    <span>Click here to proceed</span>
                  </Link>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )
      }
    </div>
  )
}
export default ApplicantDashboard
