// export default ApplicantStatusByProgramme;
import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Label, Input, FormFeedback, Alert } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import LayoutHelper from "helpers/LayoutHelper"
import { useQuery, useLazyQuery } from "@apollo/client"
import PlaceHolderLoader from "helpers/PlaceHolderLoader"
import { limit } from "helpers/UrlHelper"
import { useFormik } from "formik"
import {
  onlineApplicationGraphQLClient,
  registrationGraphQLClient
} from "Modules/Authentication/ApolloClient"
import { GET_ALL_PROGRAMS } from "../../../Programs/Queries/ProgramQuaries"
import Select from "react-select"
import { GET_APPLICANT_CONFIRMATION_BY_PROGRAMME } from "../Queries/ApplicationQueries"
import { ApplicantConfirmedTCU, ApplicationRankingPDF } from "../../../../helpers/ApplicationRankingPDF"
import showToast from "../../../../helpers/ToastHelper"

const breadcrumbItem = [
  {
    title: "Applicant status list",
    path: "",
    isActive: true
  }
]

const ApplicantConfirmationByProgramme = () => {

  const [currentPage, setCurrentPage] = useState(0)
  const [selectedProgramName, setSelectedProgramName] = useState(null)
  const [pageSize, setPageSize] = useState(limit)
  const pagination = { offset: currentPage, limit: limit, search: null }

  const [selectedProgrammeCode, setSelectedProgrammeCode] = useState(null) //Get data for deleting

  const [
    refetchApplicantData,
    { loading: applicantDataLoading, error: dataError, data: applicantData }
  ] = useLazyQuery(GET_APPLICANT_CONFIRMATION_BY_PROGRAMME, {
    client: onlineApplicationGraphQLClient,
    variables: {},
    fetchPolicy: "network-only"
  })

  const [
    searchPrograms,
    { loading: programSearchLoading, error: searchError, data: searchProgram }
  ] = useLazyQuery(GET_ALL_PROGRAMS, { client: registrationGraphQLClient })


  const { loading: programLoading, error: programError, data: programData } = useQuery(GET_ALL_PROGRAMS, {
    client: registrationGraphQLClient,
    variables: {
      pagination: pagination
    }
  })


  let programs = programData?.getAllPrograms?.data?.items
  if (searchProgram?.getAllPrograms?.data)
    programs = searchProgram?.getAllPrograms?.data?.items

  useEffect(() => {
    if (selectedProgrammeCode) {
      refetchApplicantData({
        variables: {
          input: {
            programmeCode: selectedProgrammeCode?.tcuCode
          }
        }
      })
    }
  }, [selectedProgrammeCode])

  const result = applicantData?.getApplicantConfirmationStatusByProgrammeTcu?.data?.applicants

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      programCode: ""
    }
  })

  const search = (value, type) => {
    let data = {
      search: value
    }
    if (value.length >= 2) {
      if (type === "program") {
        return searchPrograms({
          variables: { pagination: { ...pagination, ...data } }
        })
      } else if (type === "status") {
        searchStatus({
          variables: { pagination: { ...pagination, ...data } }
        })
      }
    }
  }

  const startIndex = currentPage * pageSize
  const endIndex = (currentPage + 1) * pageSize

  const onPrintReport = () => {
    if (!result) {
      showToast("\"No applicants found to generate the report.\"", "error");
      return
    }


    const title = `Applicant confirmation for ${selectedProgramName?.name}`

    const pdfData = result.map((item, index) => {
      const formFourIndexNumber = item?.formFourIndexNumber || "N/A"
      const phoneNumber = item?.phoneNumber
      const admissionStatusDescription = item?.admissionStatusDescription || "N/A"

      return [
        (index + 1).toString(), // Ensure it's converted to a string
        formFourIndexNumber,
        admissionStatusDescription,
        phoneNumber
      ]
    })
    ApplicantConfirmedTCU(pdfData, title, selectedProgramName?.name)
  }

  return (
    <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle="Applicant Confirmation Status By Programme">
      <Row>
        <Col>
          <Card>
            <CardBody>
              <button className="btn btn-sm btn-primary float-end" onClick={() => onPrintReport()}>Download report
              </button>
              <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                <Label>Program</Label>
                <Select
                  styles={{
                    menu: base => ({
                      ...base,
                      position: "absolute",
                      zIndex: 9999
                    })
                  }}
                  onChange={e => {
                    validation.setFieldValue("programCode", e.value)
                    setSelectedProgrammeCode(e.value)
                    setSelectedProgramName(e.value)
                  }}
                  onInputChange={e => {
                    search(e, "program")
                  }}
                  options={programs?.map(pro => {
                    if (pro?.tcuCode) {
                      return {
                        value: pro,
                        label: pro?.name + " (" + pro?.code + ")"
                      }
                    }
                    return null
                  }).filter(option => option !== null)}
                  className="select2-selection"
                  isLoading={programLoading || programSearchLoading}
                />
                <Input
                  name="programCode"
                  placeholder=""
                  type="hidden"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.programCode || ""}
                  invalid={
                    !!(
                      validation.touched.programCode &&
                      validation.errors.programCode
                    )
                  }
                />
                {validation.touched.programCode &&
                validation.errors.programCode ? (
                  <FormFeedback type="invalid">
                    {validation.errors.programCode}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="table-rep-plugin">
                <div
                  className="table-responsive mb-0"
                  data-pattern="priority-columns"
                >
                  {
                    applicantDataLoading ? (
                      <PlaceHolderLoader columSize={12} type={"table"} rows={5} />
                    ) : (
                      <Table
                        id="tech-companies-1"
                        className="table table-striped table-bordered"
                      >
                        <Thead>
                          <Tr>
                            <Th data-priority="1">S/N</Th>
                            <Th data-priority="1">Index number</Th>
                            <Th>Description</Th>
                            <Th>Phone No</Th>
                          </Tr>
                        </Thead>
                        {
                          result?.length > 0 ? (
                            <Tbody>
                              {!applicantDataLoading && result && result
                                ?.slice(startIndex, endIndex)
                                .map((applicant, index) => (
                                  <Tr key={applicant?.uid}>
                                    <Td>{startIndex + index + 1}</Td>
                                    <Td>{applicant?.formFourIndexNumber}</Td>
                                    <Td>{applicant?.admissionStatusDescription}</Td>
                                    <Td>{applicant?.phoneNumber}</Td>
                                  </Tr>
                                ))}
                            </Tbody>
                          ) : (
                            <>
                              {
                                applicantData?.getApplicantConfirmationStatusByProgrammeTcu?.message && (
                                  <Td colSpan={3} rowSpan={1}>
                                    <Alert color="danger" className="m-2">
                                      {applicantData?.getApplicantConfirmationStatusByProgrammeTcu?.message}
                                    </Alert>
                                  </Td>
                                )
                              }
                            </>
                          )
                        }
                      </Table>
                    )
                  }
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </LayoutHelper>
  )
}

export default ApplicantConfirmationByProgramme
