import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  HttpLink,
  Observable,
} from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { onError } from "@apollo/client/link/error"
import { RetryLink } from '@apollo/client/link/retry';
import showToast from "helpers/ToastHelper"
import { asimsBaseUrl, uaaBaseUrl, onlineApplicationBaseUrl } from "helpers/UrlHelper"
import { regBaseUrl } from "helpers/UrlHelper"
import { accomodationBaseUrl } from "helpers/UrlHelper"
import { cacheBaseUrl } from "helpers/UrlHelper"
import fetch from "node-fetch"
import { REFRESH_MUTATION } from "./Mutations/LoginMutation"

const redirectToLogout = () => {
  const logoutUrl = "/logout";
  const pageTitle = "Logout"; // Set the title of the page

  history.pushState({}, pageTitle, logoutUrl);
  document.title = pageTitle; // Update the page title
}


const uaaGraphQL = new HttpLink({
  uri: uaaBaseUrl,
  fetch: fetch,
})

const onlineApplicationGraphQL = new HttpLink({
  uri: onlineApplicationBaseUrl,
  fetch: fetch,
})

const registrationGraphQL = new HttpLink({
  uri: regBaseUrl,
  fetch: fetch,
})

const accomodationGraphQL = new HttpLink({
  uri: accomodationBaseUrl,
  fetch: fetch,
})

const cacheGraphQL = new HttpLink({
  uri: cacheBaseUrl,
  fetch: fetch,
})


const asimsGraphQL = new HttpLink({
  uri: asimsBaseUrl, // Replace with your GraphQL endpoint URL
  fetch: fetch,
})

const authLink = setContext((_, { headers }) => {
  const token = JSON.parse(localStorage.getItem("authUser"))?.accessToken

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  }
})

const handleTokenRefresh = async refreshToken => {
  try {
    const { data } = await uaaGraphQLClient.mutate({
      mutation: REFRESH_MUTATION,
      variables: { refreshToken },
    });

    if (data.refreshToken.accessToken !== null) {
      // Token refresh is successful, update the access token in localStorage
      const authUser = JSON.parse(localStorage.getItem("authUser"))
      authUser.accessToken = data.refreshToken.accessToken
      localStorage.setItem("authUser", JSON.stringify(authUser))
      return true
    } else {
      console.log("Failed to get refresh token")
      redirectToLogout()
      return false
    }
  } catch (error) {
    // Handle any errors during the refresh token mutation
    console.error("Error refreshing token:", error)
    return false
  }
}

// Retry link to automatically retry failed requests
const retryLink = new RetryLink({
  attempts: {
    max: 15, // Maximum number of retry attempts
    retryIf: (error) => !!error && !!error.networkError, // Retry on network errors only
  },
  delay: {
    initial: 1000, // Start retrying after 1 second
    max: 5000, // Max delay between retries
    jitter: true, // Adds randomness to avoid retry storms
  },
});

const retryDelay = 1000; // Retry every 1 second
let retryCount = 0;
const maxRetries = 400;

// Track active queries
let activeQueries = [];

const refetchQueries = () => {
  return Promise.all(
    activeQueries.map(({ query, variables }) =>
      registrationGraphQLClient.query({ query, variables })
        .then(result => console.log('Query refetched:', result))
        .catch(error => console.error('Query refetch failed:', error))
    )
  );
};

const retryConnection = () => {
  if (retryCount < maxRetries) {
    retryCount++;
    showToast(`Retrying connection... Attempt No ${retryCount}. Please wait`, 'info');

    // Try refetching queries first
    refetchQueries().then(() => {
      // If refetching does not resolve the issue, reset the store
      registrationGraphQLClient.resetStore()
        .then(() => {
          showToast('Store reset successfully');
          // After resetting, refetch queries again
          refetchQueries();
        })
        .catch(error => {
          console.error('Store reset failed:', error);
        });
    }).catch(error => {
      showToast('Error during query refetching:', error);
      // If refetching fails, reset the store as a fallback
      registrationGraphQLClient.resetStore()
        .then(() => {
          showToast('Store reset successfully');
          // After resetting, refetch queries again
          refetchQueries();
        })
        .catch(error => {
          console.error('Store reset failed:', error);
        });
    });
  } else {
    console.log("Max retries reached. Unable to reconnect.");
  }
};


// Error handling
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) => {
      // showToast(message, "error") Already shown Oops, for all server originated error
      if(!!process.env.REACT_APP_DEBUG_MODE){ // Only show in debug mode
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      }
    })
  }
  if (networkError) {
    showToast("No Internet Connection or Server Not Available!", "error")
    console.log(`[Network error]: ${networkError}`)
    // Start retrying after a delay
    setTimeout(retryConnection, retryDelay);
  }
})

const successLink = new ApolloLink((operation, forward) => {
  return new Observable(observer => {
    forward(operation).subscribe({
      next: async response => {
        const firstDataProperty = Object?.values(response?.data || {})[0]
        const code = firstDataProperty?.code

        if (code === 8009) {
          // Get the refresh token from localStorage
          const refreshToken = JSON.parse(
            localStorage.getItem("authUser")
          )?.refreshToken;

          if (refreshToken) {
            // Attempt to refresh the token and retry the failed request
            const retry = await handleTokenRefresh(refreshToken)

            if (retry) {
              // Retry the failed request with the new access token
              const { headers } = operation.getContext()
              operation.setContext({
                headers: {
                  ...headers,
                  authorization: `Bearer ${
                    localStorage.getItem("authUser").accessToken
                  }`,
                },
              })

              const subscriber = {
                next: observer.next.bind(observer),
                error: observer.error.bind(observer),
                complete: observer.complete.bind(observer),
              }

              forward(operation).subscribe(subscriber)
            } else {
              // Refresh token failed, redirect to logout
              redirectToLogout()
              observer.next(response)
              observer.complete()
            }
          } else {
            // No refresh token available, redirect to logout
            redirectToLogout()
            observer.next(response)
            observer.complete()
          }
        } else if (code === 8003) {
          showToast(
            firstDataProperty?.message + " for " + operation.operationName,
            "error"
          )
          observer.next(response)
          observer.complete()
        } else if(code === 8005 || !!response?.errors) { // show Oops for catched & uncached server errors
          showToast(
            response?.errors ? response?.errors[0].message : firstDataProperty?.message,
            "error"
          )

          observer.next(response)
          observer.complete()
        } else {
          if (operation.query.definitions[0].operation === "mutation") {
            showToast(
              firstDataProperty?.message,
              firstDataProperty?.code === 8000
                ? "success"
                : firstDataProperty?.code === 8005
                  ? "error"
                  : "warning"
            )
          }
          observer.next(response)
          observer.complete()
        }
      },
      error: error => {
        observer.error(error)
      },
    })
  })
});

export const uaaGraphQLClient = new ApolloClient({
  link: ApolloLink.from([errorLink, successLink, authLink, uaaGraphQL]),
  cache: new InMemoryCache(),
});


export const onlineApplicationGraphQLClient = new ApolloClient({
  link: ApolloLink.from([errorLink, successLink, authLink, onlineApplicationGraphQL]),
  cache: new InMemoryCache(),
});

export const registrationGraphQLClient = new ApolloClient({
  link: ApolloLink.from([
    //retryLink,
    errorLink,
    successLink,
    authLink,
    registrationGraphQL,
  ]),
  cache: new InMemoryCache(),
})

// Add a method to track active queries
registrationGraphQLClient.queryManager.addQueryListener = (query, variables) => {
  activeQueries.push({ query, variables });
};

export const accomodationGraphQLClient = new ApolloClient({
  link: ApolloLink.from([
    errorLink,
    successLink,
    authLink,
    accomodationGraphQL,
  ]),
  cache: new InMemoryCache(),
})

export const cacheGraphQLClient = new ApolloClient({
  link: ApolloLink.from([
    errorLink,
    successLink,
    authLink,
    cacheGraphQL,
  ]),
  cache: new InMemoryCache(),
})


export const asimsGraphQLClient = new ApolloClient({
  link: ApolloLink.from([errorLink, successLink, authLink, asimsGraphQL]),
  cache: new InMemoryCache(),
})