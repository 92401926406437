import React, { useState } from "react"

import {
  Row,
  Col,
  Card,
  CardBody,
  CardSubtitle,
  Input,
  FormFeedback,
  Form,
  Table,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { useLazyQuery, useMutation } from "@apollo/client"
import { uaaGraphQLClient } from "../../../Authentication/ApolloClient"
import {
  GET_STUDENT,
  GET_STUDENT_BY_USERNAME,
} from "../../../Users/Queries/UserQuery"
import LayoutHelper from "../../../../helpers/LayoutHelper"
import { useFormik } from "formik"
import * as Yup from "yup"
import SubmitButtonHelper from "../../../../helpers/SubmitButtonHelper"
import PlaceHolderLoader from "../../../../helpers/PlaceHolderLoader"
import { Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table"
import {
  RECOVER_USER_EMAIL,
  RECOVER_USER_PASSWORD,
} from "../../../Users/Mutations/UserMutations"
import { paginationHelper } from "../../../../helpers/Functions/GraphqlUpdateFunction"
import showToast from "../../../../helpers/ToastHelper"
import {
  showDeleteWarning,
  showWaringConf,
} from "../../../../helpers/DeleteWaringHelper"
import { SEND_STUDENT_TO_LDAP } from "Modules/Students/Mutations/LDAP"

const breadcrumbItem = [
  {
    title: "Student Recovery",
    path: "",
    isActive: true,
  },
]
const StudentEmailRecovery = () => {
  const [postRecover] = useMutation(RECOVER_USER_EMAIL, {
    client: uaaGraphQLClient,
  })
  const [reset, setReset] = useState(false)
  // const useS
  const [
    searchStudent,
    { loading: searchLoading, error: searchError, data: searchData },
  ] = useLazyQuery(GET_STUDENT_BY_USERNAME, {
    client: uaaGraphQLClient,
    fetchPolicy: "no-cache",
  })
  let user = searchData?.searchUserByUsername?.data


  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: "",
    },
    validationSchema: Yup.object().shape({
      username: Yup.string().required("Username is required"),
      // academicYearUid: Yup.string().required("Academic year is required"),
    }),
    onSubmit: (values, { setSubmitting }) => {
      // ToastHelper("Waiting for Backend", "info")
      searchStudent({
        variables: {
          username: values.username,
        },
      })
        .then(response => {
          // Handle the response
          setSubmitting(false)
        })
        .catch(error => {
          setSubmitting(false)
          // Handle errors
          console.error("Mutation error:", error)
        })
      // ToastHelper("Service requested successful", "success")
      //navigate('/Students/Payments')
    },
  })

  const updateValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      userUid: user?.uid,
      phoneNumber: user?.phoneNumber,
      email: user?.email ? user?.email : "",
      registrationNumber: user?.student?.registrationNumber,
    },
    validationSchema: Yup.object().shape({
      phoneNumber: Yup.string().required("Phone Number year is required"),
      email: Yup.string().required("E-mail year is required"),
    }),
    onSubmit: (values, { setSubmitting }) => {
      // ToastHelper("Waiting for Backend", "info")
      postRecover({
        variables: {
          inputs: values,
        },
      })
        .then(response => {
          // Handle the response
          setSubmitting(false)
        })
        .catch(error => {
          setSubmitting(false)
          // Handle errors
          console.error("Mutation error:", error)
        })
      // setSubmitting(false)
      // ToastHelper("Service requested successful", "success")
      //navigate('/Students/Payments')
    },
  })

  const [finalizeReset] = useMutation(RECOVER_USER_PASSWORD, {
    client: uaaGraphQLClient,
    variables: { uid: updateValidation.values.userUid },
  })

  const [ldapSend] = useMutation(SEND_STUDENT_TO_LDAP, {
    client: uaaGraphQLClient,
  }) // send to LDAP

  const confirmReset = () => {
    finalizeReset({
      variables: {
        uid: updateValidation.values.userUid,
      },
    })
      .then(response => {
        // console.log(response)
        // Handle the response
        showToast(
          response.data.changePasswordToDefault.message,
          response.data.status ? "success" : "error"
        )
        setReset(false)
      })
      .catch(error => {
        setReset(false)
        // Handle errors
        // console.error('Mutation error:', error);
      })
  }

  const sendStudentToLDAP = () => {
    if (user?.student?.status === "CONTINUING") {
      ldapSend({
        variables: { username: validation.values.username },
      })
        .then(response => {
          // Handle the response
          showToast(
            response.data.sendStudentToLdap.message,
            response.data.status ? "success" : "error"
          )
          setReset(false)
        })
        .catch(error => {
          setReset(false)
          // Handle errors
          // console.error('Mutation error:', error);
        })
    } else {
      showToast("Please Select Student to Recover Password", "warning")
    }
  }

  const handleReset = () => {
    if (updateValidation.values.userUid !== "") {
      showWaringConf(
        () => {
          setReset(true)
          confirmReset()
        },
        "Reset Default Password",
        "Are you sure that you need to reset this user password o default password?\n New password will be Sua12345",
        "warning",
        "Reset",
        () => {
          // console.log("Canceled")
          showToast("Process Terminated", "info")
        }
      )
    } else {
      showToast("Please Select Student to push to LDAP", "warning")
    }
  }
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const toggleDropdown = std => {
    setDropdownOpen(prevState => ({
      ...prevState,
      [std.uid]: !prevState[std.uid],
    }))
  }

  return (
    <LayoutHelper
      breadcrumbItem={breadcrumbItem}
      pageTitle="Recover Student E-mail"
    >
      <Row>
        <Col>
          <Card>
            <CardBody>
              <CardSubtitle className="mb-1">
                <Form
                  onSubmit={e => {
                    e.preventDefault()
                    validation.handleSubmit()
                    return null
                  }}
                >
                  <Row>
                    <Col className="col-lg-6 col-12">
                      <label htmlFor="name" className="col-form-label">
                        Username:
                      </label>
                      <Input
                        type="text"
                        name="username"
                        className="form-control"
                        id="username"
                        placeholder="Enter Username"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.username}
                        invalid={
                          validation.touched.username &&
                          validation.errors.username
                            ? true
                            : false
                        }
                      />
                      {validation.touched.username &&
                      validation.errors.username ? (
                        <FormFeedback type="invalid">
                          {validation.errors.username}
                        </FormFeedback>
                      ) : null}
                    </Col>
                    <Col className="col-lg-2 col-12">
                      <br />
                      <br />
                      <SubmitButtonHelper
                        name="Submit"
                        type="primary"
                        formik={validation}
                      />
                    </Col>
                  </Row>
                </Form>
              </CardSubtitle>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody>
              {searchLoading ? (
                <PlaceHolderLoader type="table" columSize={12} rows={3} />
              ) : (
                <>
                  {user && (
                    <div className="table-rep-plugin">
                      <div
                        className="table-responsive mb-0"
                        data-pattern="priority-columns"
                      >
                        <Form
                          onSubmit={e => {
                            e.preventDefault()
                            updateValidation.handleSubmit()
                            return null
                          }}
                        >
                          <Table
                            id="tech-companies-1"
                            className="table table-striped table-bordered"
                          >
                            <Thead>
                              <Tr>
                                <Th data-priority="0">Registration No</Th>
                                <Th data-priority="0">Full Name</Th>
                                <Th data-priority="0">Sex</Th>
                                <Th data-priority="0">E-mail</Th>
                                <Th data-priority="0">Phone Number</Th>
                                <Th data-priority="0">Student Status</Th>
                                <Th>Action</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              <Td>
                                <Input
                                  type="text"
                                  name="registrationNumber"
                                  className="form-control"
                                  id="registrationNumber"
                                  placeholder="Enter Registration Number"
                                  onChange={updateValidation.handleChange}
                                  onBlur={updateValidation.handleBlur}
                                  value={
                                    updateValidation.values.registrationNumber
                                  }
                                  invalid={
                                    !!(
                                      updateValidation.touched
                                        .registrationNumber &&
                                      updateValidation.errors.registrationNumber
                                    )
                                  }
                                />
                                {updateValidation.touched.registrationNumber &&
                                updateValidation.errors.registrationNumber ? (
                                  <FormFeedback type="invalid">
                                    {updateValidation.errors.registrationNumber}
                                  </FormFeedback>
                                ) : null}
                              </Td>
                              <Td>{`${user?.firstName} ${
                                user?.middleName !== undefined
                                  ? user?.middleName
                                  : ""
                              } ${user?.lastName}`}</Td>
                              <Td>{user?.gender}</Td>
                              <Td>
                                <Input
                                  type="text"
                                  name="email"
                                  className="form-control"
                                  id="email"
                                  placeholder="Enter E-mail"
                                  onChange={updateValidation.handleChange}
                                  onBlur={updateValidation.handleBlur}
                                  value={updateValidation.values.email}
                                  invalid={
                                    !!(
                                      updateValidation.touched.email &&
                                      updateValidation.errors.email
                                    )
                                  }
                                />
                                {updateValidation.touched.email &&
                                updateValidation.errors.email ? (
                                  <FormFeedback type="invalid">
                                    {updateValidation.errors.email}
                                  </FormFeedback>
                                ) : null}
                              </Td>
                              <Td>
                                <Input
                                  type="text"
                                  name="phoneNumber"
                                  className="form-control"
                                  id="phoneNumber"
                                  placeholder="Enter phone Number"
                                  onChange={updateValidation.handleChange}
                                  onBlur={updateValidation.handleBlur}
                                  value={updateValidation.values.phoneNumber}
                                  invalid={
                                    !!(
                                      updateValidation.touched.phoneNumber &&
                                      updateValidation.errors.phoneNumber
                                    )
                                  }
                                />
                                {updateValidation.touched.phoneNumber &&
                                updateValidation.errors.phoneNumber ? (
                                  <FormFeedback type="invalid">
                                    {updateValidation.errors.phoneNumber}
                                  </FormFeedback>
                                ) : null}
                              </Td>
                              <Td>{user?.student?.status}</Td>
                              <Td>
                                {reset ? (
                                  <>
                                    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                                    ...
                                  </>
                                ) : (
                                  <Dropdown
                                    isOpen={dropdownOpen[user.uid]}
                                    toggle={() => toggleDropdown(user)}
                                  >
                                    <DropdownToggle color="" caret>
                                      <i
                                        className="fas fa-ellipsis-v"
                                        style={{ color: "blue" }}
                                      />
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      <DropdownItem
                                        onClick={() => {
                                          handleReset()
                                        }}
                                      >
                                        <i
                                          className="fas fa-key"
                                          style={{
                                            color: "red",
                                            marginRight: "10px",
                                          }}
                                        />
                                        <span>Recover Default Password</span>
                                      </DropdownItem>
                                      {user?.student?.status ===
                                        "CONTINUING" && (
                                        <DropdownItem
                                          onClick={() => {
                                            sendStudentToLDAP()
                                          }}
                                        >
                                          <i
                                            className="fas fa-user"
                                            style={{
                                              color: "green",
                                              marginRight: "10px",
                                            }}
                                          />
                                          <span>Push Student To LDAP</span>
                                        </DropdownItem>
                                      )}
                                    </DropdownMenu>
                                  </Dropdown>
                                )}
                              </Td>
                            </Tbody>
                          </Table>
                          <div className="float-end">
                            {!reset && (
                              <SubmitButtonHelper
                                name="Update"
                                type="primary"
                                formik={updateValidation}
                              />
                            )}
                          </div>
                        </Form>
                      </div>
                    </div>
                  )}
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </LayoutHelper>
  )
}

export default StudentEmailRecovery
