import { Link } from "react-router-dom"
import React from "react"

const ProgramChangeLinks = ({ hasAuthority }) => {
  return (
    <>
        <li>
          <Link to="/programme-change/change-programme">
            <i className="bx bx-transfer"></i>
            <span>Programme Change</span>
          </Link>
        </li>
    </>
  )
}

export default ProgramChangeLinks
