export const DownloadExcelFile = function(base64, file_name) {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download',  file_name+'.xlsx');
    document.body.appendChild(link);
    link.click();
    // Clean up
    URL.revokeObjectURL(url);
    document.body.removeChild(link);
}

export const DownloadPDFFile = function(base64, file_name) {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download',  file_name + '.pdf');
    document.body.appendChild(link);
    link.click();
    // Clean up
    URL.revokeObjectURL(url);
    document.body.removeChild(link);
}