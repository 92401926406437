import React, { createContext, useEffect, useMemo, useState } from "react"
import {
  Alert, Card, CardBody, Col, Form, FormFeedback, Input, Label, Row
} from "reactstrap"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { useMutation, useQuery } from "@apollo/client"
import {
  onlineApplicationGraphQLClient, uaaGraphQLClient
} from "Modules/Authentication/ApolloClient"
import PlaceHolderLoader from "../../helpers/PlaceHolderLoader"
import { GET_APPLICANT_DETAILS } from "../Applicant/Queries/ApplicantQueries"
import {
  GET_ACTIVE_ADMISSION_WINDOW
} from "../OnlineApplication/AccountRegistration/Queries/AcountRegistrationQueries"
import {
  ENABLE_EDIT_APPLICAMT,
  RECHECK_ELIGIBILITY,
  RESTORE_REJECTION_FROM_TCU
} from "../ApplicationWindow/Mutations/ApplicationWindowMutation"
import showToast from "../../helpers/ToastHelper"
import { GET_USER, GET_USER_DOCUMENT } from "../Users/Queries/UserQuery"
import {
  CONFIRM_APPLICANT_TCU,
  PUSH_REGISTERED_APPLICANT
} from "../OnlineApplication/AccountRegistration/Mutations/AccountRegistrationMutations"
import { useFormik } from "formik"
import * as Yup from "yup"
import SubmitButtonHelper from "../../helpers/SubmitButtonHelper"
import { useDispatch } from "react-redux"
import { updateSelectedStatus } from "../Redux/Actions/ApplicantSideBarActions"
import {
  REJECT_SELECTION_TCU,
  REQUEST_CONFIRMATION_CODE_FROM_TCU, RESTORE_APPLICANT_SELECTION_TCU
} from "../OnlineApplication/ProgramSelection/Mutations/ProgramSelectionMutations"
import { GET_APPLICANT_SELECTION } from "../OnlineApplication/ProgramSelection/Queries/ProgramSelectionQueries"
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table"
import { limit } from "../../helpers/UrlHelper"
import { showCancelTCUSelectionWarning, showRestoreCancelTCUSelectionWarning } from "../../helpers/DeleteWaringHelper"
import { GenerateApplicantAdmissionLetter } from "../../helpers/GenerateApplicantAdmissionLetter"
import ApplicantRegistrationForSelectedOne
  from "../OnlineApplication/SelectedApplicantRegistration/View/ApplicantRegistrationForSelectedOne"

export const OnlineDashboardContext = createContext()

const OnlineApplicationDashboard = () => {
  const [currentPage, setCurrentPage] = useState(0)
  const [isCancelWaiting, isSetCancelWaiting] = useState(false)
  const [isStoring, isSetStoring] = useState(false)
  const [isCancelTcuStoring, isSetCancelTcuStoring] = useState(false)
  const [isRefectingEligibility, isSetRefectingEligibility] = useState(false)
  const [pageSize, setPageSize] = useState(limit)
  const startIndex = currentPage * pageSize
  const endIndex = (currentPage + 1) * pageSize
  const dispatch = useDispatch()
  const [requestionConfirmationCodeFromTcu] = useMutation(REQUEST_CONFIRMATION_CODE_FROM_TCU, { client: onlineApplicationGraphQLClient })
  const [pushRegisteredApplicant] = useMutation(PUSH_REGISTERED_APPLICANT,
    { client: onlineApplicationGraphQLClient })

  const [confirmApplicantTCU] = useMutation(CONFIRM_APPLICANT_TCU, { client: onlineApplicationGraphQLClient })
  const [restoreCancelTCU] = useMutation(RESTORE_REJECTION_FROM_TCU, { client: onlineApplicationGraphQLClient })
  const navigate = useNavigate()
  const location = useLocation()
  const ExtractUserAuth = JSON.parse(localStorage.getItem("authUser"))?.data
  const [enableEditLoading, setEnableEditLoading] = useState(false)
  const [enableEdit] = useMutation(ENABLE_EDIT_APPLICAMT, { client: onlineApplicationGraphQLClient })
  const [rejectSelection] = useMutation(REJECT_SELECTION_TCU, { client: onlineApplicationGraphQLClient })
  const [refetchEligibility] = useMutation(RECHECK_ELIGIBILITY, { client: onlineApplicationGraphQLClient })
  const [restoreApplicationSelection] = useMutation(RESTORE_APPLICANT_SELECTION_TCU, { client: onlineApplicationGraphQLClient })

  const { loading: userLoading, error: staffError, data: userData } = useQuery(GET_USER, {
    client: uaaGraphQLClient,
    skip: location?.pathname !== "/applicant/dashboard",
    variables: {
      username: ExtractUserAuth?.username
    },
    fetchPolicy: "network-only"
  })

  const { loading: loadingSignature, error: errorSignature, data: signatureData } = useQuery(GET_USER_DOCUMENT, {
    client: uaaGraphQLClient,
    variables: {
      userUid: "2e5b53e7-3097-4ffe-b2e9-9e7759108d57",
      documentType: "signature"
    },
    fetchPolicy: "no-cache"
  })

  let signaturePad = ""
  if (signatureData?.getUserDocuments?.data?.base64doc)
    signaturePad = "data:image/jpeg;base64," + signatureData?.getUserDocuments?.data?.base64doc


  const user = userData?.getUserByUsername?.data

  const { loading: applicantLoading, error: applicantError, data: applicantData } = useQuery(GET_APPLICANT_DETAILS, {
    client: onlineApplicationGraphQLClient,
    skip: !JSON.parse(localStorage.getItem("authUser"))?.data?.username || location?.pathname !== "/applicant/dashboard",
    variables: {
      userUid: ExtractUserAuth?.uid
    },
    fetchPolicy: "network-only"
  })

  const {
    loading: admissionWindowLoading,
    error: admissionWindowError,
    data: admissionWindowData
  } = useQuery(GET_ACTIVE_ADMISSION_WINDOW, {
    client: onlineApplicationGraphQLClient,
    skip: location?.pathname !== "/applicant/dashboard",
    variables: {
      applicationLevelCode: "BSC"
    },
    fetchPolicy: "network-only"
  })


  const window = admissionWindowData?.getActiveAdmissionWindow?.data

  const applicant = applicantData?.getApplicantDetails.data

  const { loading, error, data, refetch } = useQuery(GET_APPLICANT_SELECTION, {
    client: onlineApplicationGraphQLClient,
    skip: !window?.uid || !applicant?.uid,
    variables: {
      applicantUid: applicant?.uid,
      admissionWindowUid: window?.uid
    },
    fetchPolicy: "network-only"
  })

  const results = data?.getApplicantSelections?.data?.items

  const extractUsername = (username) => {
    return username.split("/").slice(0, 3).join("/")
  }

  const capitalizeText = (text) => {
    if (!text) return ""
    return text.toLowerCase().charAt(0).toUpperCase() + text.slice(1).toLowerCase()
  }


  const pushApplicantFormirkValidator = useFormik({
    enableReinitialize: true,
    initialValues: {
      juniorIdentificationNumber: ""
    },
    validationSchema: Yup.object().shape({
      juniorIdentificationNumber: capitalizeText(user?.country?.name) !== "Tanzania" && Yup.string().required("Junior / Identification Number is required")
    }),

    onSubmit: (values, { setSubmitting }) => {
      const payload = {
        firstName: user?.firstName,
        middleName: user?.middleName,
        lastName: user?.lastName,
        email: user?.email,
        phoneNumber: user?.phoneNumber,
        gender: user?.gender,
        countryId: user?.country?.id,
        formFourIndexNumber: extractUsername(user?.username),
        tanzanian: capitalizeText(user?.country?.name) === "Tanzania" ? true : false,
        userUid: user?.uid,
        juniorIdentificationNumber: values.juniorIdentificationNumber
      }
      pushRegisteredApplicant({
        variables: {
          inputs: {
            ...payload
          }
        },
        refetchQueries: [
          {
            query: GET_APPLICANT_DETAILS,
            variables: {
              userUid: ExtractUserAuth?.uid
            }
          }
        ], onCompleted: ({ pushRegisteredApplicant }) => {
          if (pushRegisteredApplicant?.code === 8000) {
            setSubmitting(false)
            showToast("Successfully applicant information restored!", "success")
          } else {
            setSubmitting(false)
          }
        },
        onError: ({ error }) => {
          setSubmitting(false)
          showToast("Something went wrong: " + error, "error")
        }
      })
    }
  })

  const enableEditing = () => {
    setEnableEditLoading(true)
    enableEdit({
      variables: {
        applicantUid: applicant?.uid
      },
      onCompleted: ({ enableEditApplication }) => {
        setEnableEditLoading(false)
        if (enableEditApplication.code === 8000) {
          navigate("/applicant/program-selection")
        } else {
          return
        }
        showToast(
          enableEditApplication.message,
          enableEditApplication.code === 8000 ? "success" : "error"
        )
      }
    })
  }

  const verificationCodeFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      confirmationCode: "",
      formFourIndexNumber: applicant?.formFourIndexNumber
    },

    validationSchema: Yup.object().shape({
      confirmationCode: Yup.string().required("Verification code is required")
    }),
    onSubmit: (values, { setSubmitting }) => {
      confirmApplicantTCU({
        variables: {
          input: { ...values }
        },
        refetchQueries: [
          {
            query: GET_APPLICANT_DETAILS,
            variables: {
              userUid: ExtractUserAuth?.uid
            }
          }
        ], onCompleted: ({ confirmApplicantTcu }) => {
          if (confirmApplicantTcu?.code === 8000) {
            setSubmitting(false)
            showToast(confirmApplicantTcu?.message, "success")
          } else {
            setSubmitting(false)
          }
        },
        onError: ({ error }) => {
          setSubmitting(false)
          showToast(confirmApplicantTcu?.message + error, "error")
        }
      })
    }
  })

  const requestionConfirmationodeFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      formFourIndexNumber: applicant?.formFourIndexNumber,
      mobileNumber: "",
      emailAddress: user?.email
    },
    validationSchema: Yup.object().shape({
      mobileNumber: Yup.string().required("Mobile number is required")
    }),
    onSubmit: (values, { setSubmitting }) => {
      requestionConfirmationCodeFromTcu({
        variables: {
          input: { ...values }
        },
        refetchQueries: [
          {
            query: GET_APPLICANT_DETAILS,
            variables: {
              userUid: ExtractUserAuth?.uid
            }
          }
        ], onCompleted: ({ requestConfirmationCodeTcu }) => {
          if (requestConfirmationCodeTcu?.code === 8000) {
            setSubmitting(false)
            showToast(requestConfirmationCodeTcu?.message, "success")
          } else {
            setSubmitting(false)
          }
        },
        onError: ({ error }) => {
          setSubmitting(false)
          showToast(requestConfirmationCodeTcu?.message + error, "error")
        }
      })
    }
  })

  const handleTCUSelectionRejection = () => {
    showCancelTCUSelectionWarning(() => {
      isSetCancelWaiting(true)
      rejectSelection({
        variables: {
          input: {
            formFourIndexNumber: applicant?.formFourIndexNumber
          }
        },
        refetchQueries: [
          {
            query: GET_APPLICANT_DETAILS,
            variables: {
              userUid: ExtractUserAuth?.uid
            }
          }
        ], onCompleted: ({ rejectApplicantTcu }) => {
          if (rejectApplicantTcu?.code === 8000) {
            isSetCancelWaiting(false)
            showToast(rejectApplicantTcu?.message, "success")
          } else {
            isSetCancelWaiting(false)
          }
        },
        onError: ({ error }) => {
          isSetCancelWaiting(false)
          showToast(rejectApplicantTcu?.message + error, "error")
        }
      })
    })
  }

  const handleTCUCancelRestore = () => {
    showRestoreCancelTCUSelectionWarning(() => {
      isSetCancelTcuStoring(true)
      restoreCancelTCU({
        variables: {
          input: {
            formFourIndexNumber: applicant?.formFourIndexNumber,
            programmeCode: applicant?.tcuProgrammeCode
          }
        },
        refetchQueries: [
          {
            query: GET_APPLICANT_DETAILS,
            variables: {
              userUid: ExtractUserAuth?.uid
            }
          }
        ], onCompleted: ({ restoreCancelledAdmissionTcu }) => {
          if (restoreCancelledAdmissionTcu?.code === 8000) {
            isSetCancelTcuStoring(false)
            showToast(restoreCancelledAdmissionTcu?.message, "success")
          } else {
            isSetCancelTcuStoring(false)
            showToast(restoreCancelledAdmissionTcu?.message + error, "error")
          }
        },
        onError: ({ error }) => {
          isSetCancelTcuStoring(false)
        }
      })
    })
  }

  const handleTCUSelectionRestore = () => {
    isSetStoring(true)
    restoreApplicationSelection({
      variables: {
        input: {
          formFourIndexNumber: applicant?.formFourIndexNumber
        }
      },
      refetchQueries: [
        {
          query: GET_APPLICANT_DETAILS,
          variables: {
            userUid: ExtractUserAuth?.uid
          }
        }
      ], onCompleted: ({ restoreApplicantTcu }) => {
        if (restoreApplicantTcu?.code === 8000) {
          isSetStoring(false)
          showToast(restoreApplicantTcu?.message, "success")
        } else {
          isSetStoring(false)
          showToast(restoreApplicantTcu?.message + error, "error")
        }
      },
      onError: ({ error }) => {
        isSetStoring(false)
      }
    })
  }
  const selectedStatus = useMemo(() => {
    return applicant?.isTcuSelected
  }, [applicant?.isTcuSelected])

  // Dispatch the action
  useEffect(() => {
    if (selectedStatus !== undefined) {
      dispatch(updateSelectedStatus(selectedStatus))
    }
  }, [dispatch, selectedStatus])

  const onRecheckEligibility = () => {
    isSetRefectingEligibility(true)
    refetchEligibility({
      variables: {
        input: {
          applicantUid: applicant?.uid,
          admissionWindowUid: window?.uid
        }
      },
      refetchQueries: [
        {
          query: GET_APPLICANT_DETAILS,
          variables: {
            userUid: ExtractUserAuth?.uid
          }
        }
      ],
      onCompleted: ({ refreshEligibility }) => {
        if (refreshEligibility?.code === 8000) {
          isSetRefectingEligibility(false)
          showToast(refreshEligibility?.message, "success")
        } else {
          setSubmitting(false)
        }
      },
      onError: ({ error }) => {
        isSetRefectingEligibility(false)
        showToast(error, "error")
      }
    })
  }

  const handleJoiningInstructionPrinting = () => {
    const pdfUrl = process.env.PUBLIC_URL + "/forms/SOKOINE_UNIVERSITY_OF_AGRICULTURE_JOINING_INSTRUCTION_2024-2025.pdf" // Path to your PDF file in the "public" directory
    const a = document.createElement("a")
    a.href = pdfUrl
    a.download = "SOKOINE_UNIVERSITY_OF_AGRICULTURE_JOINING_INSTRUCTION_2024-2025.pdf" // You can change the filename here
    a.style.display = "none"
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  const handleAdmiddionLetterPrinting = () => {
    if (applicant?.campusCode) {
      return GenerateApplicantAdmissionLetter(applicant, signaturePad, applicant?.campusCode)

    } else {
      showToast("Admission letter failed to generate, Please try again later or Contact SUA admission officer for further help!", "info")
    }
  }
  return (
    <OnlineDashboardContext.Provider value={{
      applicant
    }}>
      <div className="container">
        {location?.pathname === "/applicant/dashboard" && (
          <Card>
            <CardBody>
              {/*<Row className="mb-3">*/}
              {/*  <Col md={4}>*/}
              {/*    <button className="btn btn-sm btn-primary">Admission letter</button>*/}
              {/*  </Col>*/}
              {/*  <Col md={4}>*/}
              {/*    <button className="btn btn-sm btn-dark">Joining instruction</button>*/}
              {/*  </Col>*/}
              {/*  <Col md={4}>*/}
              {/*    <button className="btn btn-sm btn-secondary">Admission letter</button>*/}
              {/*  </Col>*/}
              {/*</Row>*/}
              <Row>
                {(applicantLoading || admissionWindowLoading || userLoading || loading) ? (
                  <Col md={12}>
                    <PlaceHolderLoader rows={4} type="table" columSize={12} />
                  </Col>
                ) : (
                  <>
                    {
                      applicant?.isTcuSelected ? (
                        <>
                          {
                            applicant?.isTcuMultiple ? (
                              <>
                                <Row>
                                  {
                                    applicant?.isTcuConfirmed !== true ? (
                                      <Col md={12}>
                                        <h3>
                                          Welcome:{" "}
                                          {user && (
                                            <>
                                              {user?.firstName +
                                                " " +
                                                user?.middleName +
                                                " " +
                                                user?.lastName}
                                            </>
                                          )}
                                        </h3>
                                        {
                                          applicant?.isTcuRejected === true ? (
                                            <Alert color="danger">
                                              You have rejected your selection at Sokoine University of Agriculture! If
                                              you
                                              would like to restore it, please click the Restore button below. Thank
                                              you!
                                            </Alert>
                                          ) : (
                                            <Form
                                              onSubmit={e => {
                                                e.preventDefault()
                                                verificationCodeFormik.handleSubmit()
                                                return null
                                              }}
                                            >
                                              <div className="alert alert-success">
                                                <p>
                                                  Congratulations! You have been successfully selected for multiple
                                                  selections.
                                                  Please
                                                  proceed
                                                  to confirm.
                                                </p>
                                                <strong>Programme name: </strong>
                                                <b>{applicant?.tcuSelectedProgramName}</b>
                                              </div>
                                              <div className="modal-body mt-4">
                                                <div className="mb-3">
                                                  <Label>Enter confirmation code received from TCU</Label>
                                                  <Input
                                                    name="confirmationCode"
                                                    placeholder="Enter code"
                                                    type="text"
                                                    rows={2}
                                                    onChange={verificationCodeFormik.handleChange}
                                                    onBlur={verificationCodeFormik.handleBlur}
                                                    invalid={
                                                      !!(
                                                        verificationCodeFormik.touched.confirmationCode &&
                                                        verificationCodeFormik.errors.confirmationCode
                                                      )
                                                    }
                                                  />
                                                  {verificationCodeFormik.touched.confirmationCode &&
                                                  verificationCodeFormik.errors.confirmationCode ? (
                                                    <FormFeedback type="invalid">
                                                      {verificationCodeFormik.errors.juniorIdentificationNumber}
                                                    </FormFeedback>
                                                  ) : null}
                                                </div>
                                              </div>
                                              <SubmitButtonHelper name="Confirm" type="primary"
                                                                  formik={verificationCodeFormik} />
                                            </Form>
                                          )
                                        }
                                      </Col>
                                    ) : applicant?.isTcuConfirmed === true && (
                                      <>
                                        <Col md={6}>
                                          <Alert color="success">
                                            Congratulations! You have been successfully selected at Sokoine University
                                            of
                                            Agriculture.
                                            <br />
                                            <strong>Programme name: </strong> <b>{applicant?.tcuSelectedProgramName}</b>
                                          </Alert>
                                          <Row className="mt-4">
                                            <Col md={12}>
                                              <strong>Restore cancel(If cancelled)</strong>
                                              <br />
                                              <button disabled={isCancelTcuStoring} className="btn btn-sm btn-primary"
                                                      onClick={() => handleTCUCancelRestore()}>
                                                {isCancelTcuStoring ? "Please while restoring..." : "Restore cancelled selection"}
                                              </button>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col className="col-md-12">
                                              <div className="mt-4">
                                                <Row>
                                                  <Col md={6}>
                                                    <button className="btn btn-info btn-sm"
                                                            onClick={() => handleAdmiddionLetterPrinting()}><i
                                                      className="fa fa-print"></i> Print
                                                      admission letter
                                                    </button>
                                                  </Col>
                                                  <Col md={6}>
                                                    <button className="btn btn-dark btn-sm float-end"
                                                            onClick={() => handleJoiningInstructionPrinting()}><i
                                                      className="fa fa-print"></i> Print joining instruction
                                                    </button>
                                                  </Col>
                                                </Row>
                                              </div>
                                            </Col>
                                          </Row>
                                        </Col>
                                        <Col md={6} style={{ borderLeft: "4px solid #006400", paddingLeft: "10px" }}>
                                          <ApplicantRegistrationForSelectedOne />
                                        </Col>
                                      </>
                                    )
                                  }
                                </Row>
                                <Row>
                                  {
                                    applicant?.isTcuRejected === false && applicant?.isTcuConfirmed !== true && (
                                      <Col md={4}>
                                        <div className="mt-4 text-center">
                                          <strong>Do not have TCU code yet?</strong>
                                          <Form
                                            onSubmit={e => {
                                              e.preventDefault()
                                              requestionConfirmationodeFormik.handleSubmit()
                                              return null
                                            }}
                                          >
                                            <div className="mb-2">
                                              <Input
                                                type="text"
                                                name="mobileNumber"
                                                className="form-control"
                                                id="phoneNumber"
                                                placeholder="Enter Mobile Number"
                                                onChange={requestionConfirmationodeFormik.handleChange}
                                                onBlur={requestionConfirmationodeFormik.handleBlur}
                                                value={requestionConfirmationodeFormik.values.mobileNumber || ""}
                                                invalid={
                                                  !!(requestionConfirmationodeFormik.touched.mobileNumber && requestionConfirmationodeFormik.errors.mobileNumber)
                                                }
                                              />
                                              {requestionConfirmationodeFormik.touched.mobileNumber && requestionConfirmationodeFormik.errors.mobileNumber ? (
                                                <FormFeedback type="invalid">
                                                  {requestionConfirmationodeFormik.errors.mobileNumber}
                                                </FormFeedback>
                                              ) : null}
                                            </div>

                                            <SubmitButtonHelper name="Request code" type="dark"
                                                                formik={requestionConfirmationodeFormik} />
                                          </Form>
                                        </div>
                                      </Col>
                                    )
                                  }
                                  {
                                    applicant?.isTcuRejected === false && (
                                      <Col md={4}>
                                        <div className="mt-4 text-center">
                                          <strong>Do you want to reject selection?</strong>
                                          <br />
                                          <button disabled={isCancelWaiting} className="btn btn-danger"
                                                  onClick={() => handleTCUSelectionRejection()}>
                                            {isCancelWaiting ? "Please while cancelling..." : "Reject selection"}
                                          </button>
                                        </div>
                                      </Col>
                                    )
                                  }
                                  {
                                    applicant?.isTcuRejected === true && (
                                      <Col className={4}>
                                        <div className="mt-4 text-center">
                                          <strong>Do you want to store selection?</strong>
                                          <br />
                                          <button disabled={isStoring} className="btn btn-secondary"
                                                  onClick={() => handleTCUSelectionRestore()}>
                                            {isStoring ? "Please while restoring..." : "Restore selection"}
                                          </button>
                                        </div>
                                      </Col>
                                    )
                                  }
                                </Row>
                              </>
                            ) : (
                              <Row>
                                <Col md={12}>
                                  <h3>
                                    Welcome:{" "}
                                    {user && (
                                      <>
                                        {user?.firstName +
                                          " " +
                                          user?.middleName +
                                          " " +
                                          user?.lastName}
                                      </>
                                    )}
                                  </h3>
                                  <div>
                                    {
                                      applicant?.isTcuRejected === true ? (
                                        <Alert color="danger">
                                          You have rejected your selection at Sokoine University of Agriculture! If you
                                          would like to restore it, please click the Restore button below. Thank you!
                                        </Alert>
                                      ) : (
                                        <>
                                          <Row>
                                            <Col md={6}>
                                              <Alert color="success">
                                                Congratulations! You have been successfully selected at Sokoine
                                                University
                                                of
                                                Agriculture.
                                                <br />
                                                <strong>Programme name: </strong>
                                                <b>{applicant?.tcuSelectedProgramName}</b>
                                              </Alert>

                                              <Row>
                                                {
                                                  applicant?.isTcuRejected === false && applicant?.applicationLevelCode === "BSC" && (
                                                    <Col md={6}>
                                                      <div className="mt-4">
                                                        <strong>Do you want to reject selection?</strong>
                                                        <br />
                                                        <button disabled={isCancelWaiting}
                                                                className="btn btn-sm btn-danger"
                                                                onClick={() => handleTCUSelectionRejection()}>
                                                          {isCancelWaiting ? "Please while cancelling..." : "Reject your selection"}
                                                        </button>
                                                      </div>
                                                    </Col>
                                                  )
                                                }
                                                <Col md={6}>
                                                  <div className="mt-4">
                                                    <strong className="float-end">Restore cancel(If cancelled)</strong>
                                                    <br />
                                                    <button disabled={isCancelTcuStoring}
                                                            className="btn btn-sm btn-primary float-end"
                                                            onClick={() => handleTCUCancelRestore()}>
                                                      {isCancelTcuStoring ? "Please while restoring..." : "Restore selection"}
                                                    </button>
                                                  </div>
                                                </Col>
                                              </Row>

                                              <Row className="mt-4">
                                                <Col md={12}>
                                                  <button className="btn btn-info btn-sm"
                                                          onClick={() => handleAdmiddionLetterPrinting()}><i
                                                    className="fa fa-print"></i> Print
                                                    admission letter
                                                  </button>
                                                  <br />
                                                  <button className="btn btn-dark btn-sm mt-4"
                                                          onClick={() => handleJoiningInstructionPrinting()}><i
                                                    className="fa fa-print"></i> Print joining instruction
                                                  </button>
                                                </Col>
                                              </Row>
                                            </Col>
                                            <Col md={6}
                                                 style={{ borderLeft: "4px solid #006400", paddingLeft: "10px" }}>
                                              <ApplicantRegistrationForSelectedOne />
                                            </Col>
                                          </Row>
                                        </>
                                      )
                                    }
                                  </div>
                                </Col>
                              </Row>
                            )
                          }
                        </>
                      ) : (
                        <Row>
                          <>
                            {
                              window ? (
                                <>
                                  <Col md={12}>
                                    <h3>
                                      Welcome:{" "}
                                      {user && (
                                        <>
                                          {user?.firstName +
                                            " " +
                                            user?.middleName +
                                            " " +
                                            user?.lastName}
                                        </>
                                      )}
                                    </h3>
                                    {applicant && applicant?.submissionStatus === false ? (
                                      <>
                                        <p className="alert alert-danger">
                                          Thank you for your interest in joining Sokoine University of Agriculture and
                                          for taking the time to submit your application. We regret to inform you that
                                          you have not been selected. However, you are welcome to reapply if the
                                          application window opens again in the future.
                                        </p>
                                        <Link
                                          to="/applicant/profile-information"
                                          className="btn btn-sm btn-primary mt-4"
                                        >
                                          <span>Click here to proceed online application</span>
                                        </Link>
                                      </>
                                    ) : applicant && applicant?.submissionStatus === true ? (
                                      <>
                                        <Row>
                                          <Col md={12}>
                                            <p className="alert alert-success">You have successfully submitted your
                                              application for the <b>{window?.description}</b>. Please wait for the
                                              selection results!</p>
                                            <p className="text-danger">If you have not satisfied with the existing
                                              Eligibility Status, please click Recheck Eligibility below!</p>
                                            <p className="text-danger">
                                              Make sure that you have retrieved Form Four results together with a-level
                                              or
                                              diploma results.
                                            </p>
                                            <button disabled={isRefectingEligibility}
                                                    className="btn btn-sm btn-info mb-2"
                                                    onClick={() => onRecheckEligibility()}
                                            >{
                                              isRefectingEligibility ? (
                                                <>Please wait while rechecking eligibility...</>
                                              ) : (
                                                <>Recheck Eligibility</>
                                              )
                                            }</button>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col md={12}>
                                            <Table
                                              id="tech-companies-1"
                                              className="table table-striped table-bordered"
                                              style={{ border: "2px solid #63a69f" }}
                                            >
                                              <Thead>
                                                <Tr>
                                                  <Th data-priority="1">S/N</Th>
                                                  <Th data-priority="1">Programme Name</Th>
                                                  <Th data-priority="1">Eligibility</Th>
                                                  <Th data-priority="1">Choice No.</Th>
                                                </Tr>
                                              </Thead>
                                              <Tbody>
                                                {results
                                                  ?.slice(startIndex, endIndex)
                                                  .map((resultRow, index) => (
                                                    <Tr key={resultRow?.uid}>
                                                      <Td>{startIndex + index + 1}</Td>
                                                      <Td>{resultRow?.programCapacity?.programName}</Td>
                                                      <Td>{resultRow?.rejectionReason === "ELIGIBLE" || resultRow.rejectionReason === "Selected" ? (
                                                        <b className="text-success">{`ELIGIBLE`}</b>
                                                      ) : (
                                                        <b className="text-danger">{resultRow?.rejectionReason}</b>
                                                      )}</Td>
                                                      <Td>{resultRow?.choice}</Td>
                                                    </Tr>
                                                  ))}
                                              </Tbody>
                                            </Table>
                                          </Col>
                                        </Row>

                                        <Row>
                                          <Col md={12}>
                                            <button disabled={enableEditLoading} onClick={enableEditing}
                                                    className="btn btn-primary btn-sm">
                                              {enableEditLoading ? (
                                                <>Please wait...</>
                                              ) : (
                                                <>Do you want to edit your information? click here</>
                                              )}
                                            </button>
                                          </Col>
                                        </Row>
                                      </>
                                    ) : (!applicant) && (
                                      <Form
                                        onSubmit={e => {
                                          e.preventDefault()
                                          pushApplicantFormirkValidator.handleSubmit()
                                          return null
                                        }}
                                      >
                                        <div className="alert alert-info"><h3>IMPORTANT NOTE!</h3></div>
                                        {
                                          capitalizeText(user?.country?.name) !== "Tanzania" && (
                                            <div className="modal-body mt-4">
                                              <div className="mb-3">
                                                <Label>Senior/Junior Identification Number (Ordinary level)</Label>
                                                <Input
                                                  name="juniorIdentificationNumber"
                                                  placeholder="Select Region"
                                                  type="text"
                                                  rows={2}
                                                  onChange={pushApplicantFormirkValidator.handleChange}
                                                  onBlur={pushApplicantFormirkValidator.handleBlur}
                                                  invalid={
                                                    !!(
                                                      pushApplicantFormirkValidator.touched.juniorIdentificationNumber &&
                                                      pushApplicantFormirkValidator.errors.juniorIdentificationNumber
                                                    )
                                                  }
                                                />
                                                {pushApplicantFormirkValidator.touched.juniorIdentificationNumber &&
                                                pushApplicantFormirkValidator.errors.juniorIdentificationNumber ? (
                                                  <FormFeedback type="invalid">
                                                    {pushApplicantFormirkValidator.errors.juniorIdentificationNumber}
                                                  </FormFeedback>
                                                ) : null}
                                              </div>
                                            </div>
                                          )
                                        }
                                        <SubmitButtonHelper name="Please click here to proceed" type="primary"
                                                            formik={pushApplicantFormirkValidator} />
                                      </Form>
                                    )}
                                  </Col>
                                </>
                              ) : (
                                <Alert color="danger">
                                  {admissionWindowData?.getActiveAdmissionWindow?.message}
                                </Alert>
                              )
                            }
                          </>
                        </Row>
                      )
                    }
                  </>
                )}
              </Row>
            </CardBody>
          </Card>
        )}
      </div>
    </OnlineDashboardContext.Provider>

  )
}
export default OnlineApplicationDashboard