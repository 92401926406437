import React, { Component } from "react"
import { Carousel, CarouselIndicators, CarouselItem } from "reactstrap"
import "assets/scss/carousel.scss"
import img3 from "assets/images/carouselImages/admnistration_block.jpg"
import img4 from "assets/images/carouselImages/admnistration_block.png"
import img5 from "assets/images/carouselImages/admnistration_block_night.jpg"
import advertisement from "assets/images/carouselImages/advertizement.jpeg"

const items = [
  {
    src: advertisement,
    altText: "Slide 1",
    caption: "Slide 1",
  },
  {
    src: img5,
    altText: "Slide 4",
    caption: "Slide 4",
  },
  {
    src: img4,
    altText: "Slide 3",
    caption: "Slide 3",
  },
  {
    src: img3,
    altText: "Slide 2",
    caption: "Slide 2",
  }
]

class Slidewithindicator extends Component {
  constructor(props) {
    super(props)
    this.state = { activeIndex: 0 }
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
    this.goToIndex = this.goToIndex.bind(this)
    this.onExiting = this.onExiting.bind(this)
    this.onExited = this.onExited.bind(this)
  }

  onExiting() {
    this.animating = true
  }

  onExited() {
    this.animating = false
  }

  next() {
    if (this.animating) return
    const nextIndex =
      this.state.activeIndex === items.length - 1
        ? 0
        : this.state.activeIndex + 1
    this.setState({ activeIndex: nextIndex })
  }

  previous() {
    if (this.animating) return
    const nextIndex =
      this.state.activeIndex === 0
        ? items.length - 1
        : this.state.activeIndex - 1
    this.setState({ activeIndex: nextIndex })
  }

  goToIndex(newIndex) {
    if (this.animating) return
    this.setState({ activeIndex: newIndex })
  }

  render() {
    const { activeIndex } = this.state

    const slides = items.map(item => {
      return (

        <CarouselItem
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={item.src}
        >
          {/*<h1>*/}
          {/*  <center><b>Sokoine University of Agriculture (SUA)</b></center>*/}
          {/*</h1>*/}

          {/*<h3>*/}
          {/*  <center>Enterprise Service Bus</center>*/}
          {/*</h3>*/}

          {/*<h3>*/}
          {/*  <center>SUA-ESB</center>*/}
          {/*</h3>*/}
          <img
            src={item.src}
            className="d-block img-fluid logoImage"
            alt={item.altText}
            style={{
              width: "100%",
              objectFit: "contain",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
              paddingTop: "15%",
              paddingLeft: "5%",
              paddingRight: "0%",
              paddingBottom: "15%",
              borderRadius: "15px"
            }}
          />

        </CarouselItem>
      )
    })

    return (
      <React.Fragment>
        <Carousel
          activeIndex={activeIndex}
          next={this.next}
          previous={this.previous}
        >
          <CarouselIndicators
            items={items}
            activeIndex={activeIndex}
            onClickHandler={this.goToIndex}
          />
          {slides}
        </Carousel>
      </React.Fragment>
    )
  }
}

export default Slidewithindicator
