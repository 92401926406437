import { Link } from "react-router-dom"
import React from "react"

const AccommodationLinks = ({ hasAuthority }) => {
  return (
    <>
      {hasAuthority("VIEW_ACCOMODATION_MENU") && (
        <>
          {hasAuthority("VIEW_HOSTEL_MENU") && (
            <li>
              <Link to="/accommodation/hostels">
                <i className="bx bx-building-house"></i>
                <span>Hostel</span>
              </Link>
            </li>
          )}

          {hasAuthority("VIEW_ROOM_CAPACITY_MENU") && (
            <li>
              <Link to="/accommodation/room-capacity">
                <i className="bx bx-group"></i>
                <span>Room Capacity</span>
              </Link>
            </li>
          )}

          {hasAuthority("VIEW_ROOM_ALLOCATION_MENU") && (
            <li>
              <Link to="/accommodation/allocation-and-boarding">
                <i className="bx bx-bed"></i>
                <span>Allocation & Boarding</span>
              </Link>
            </li>
          )}

          {hasAuthority("VIEW_MY_ACCOMMODATION_MENU") && (
            <li>
              <Link to="/accommodation/my-accommodation">
                <i className="bx bx-bed"></i>
                <span>My Accommodation</span>
              </Link>
            </li>
          )}

          {hasAuthority("VIEW_ROOM_CAPACITY_MENU") && (
            <li>
              <Link to="/accommodation/allocation-payment-status">
                <i className="bx bx-dollar-circle"></i>
                <span>Allocation Payments</span>
              </Link>
            </li>
          )}
        </>
      )}
    </>
  )
}

export default AccommodationLinks
