import React from "react"
import jsPDF from "jspdf"
import "jspdf-autotable"
import { useQuery } from "@apollo/client"
import { uaaGraphQLClient } from "Modules/Authentication/ApolloClient"
import showToast from "helpers/ToastHelper"

const sua_logo = process.env.PUBLIC_URL + "/logo.jpeg"

const pasport_size = process.env.PUBLIC_URL + "/pasport_size.jpg"

export const RegistrationStudentForm = (
  student,
  studentNextOfKin,
  programName,
  studentByUsername
) => {
  let entryCate  = "";

  const extractedPart = student?.student?.registrationNumber?.split('/')[1];
  if (extractedPart === "D"){
    entryCate = "DIRECT"
  }else if (extractedPart === "E"){
    entryCate = "EQUIVALENT"
  }
  console.log("Export: ",extractedPart)
  if(programName){
    try {


      const currentDate = new Date()
      const options = { year: "numeric", month: "long", day: "numeric" }
      const formattedDate = currentDate.toLocaleDateString(undefined, options)
  
      const doc = new jsPDF()
  
      // Load both images before creating the PDF
      doc.addImage(sua_logo, "PNG", 10, 10, 30, 30) // Adjust position and size as needed
  
      doc.setFontSize(13)
      doc.setFont("Roboto", "bold")
      doc.text("SOKOINE UNIVERSITY OF AGRICULTURE", 100, 20, { align: "center" }) // Adjust position as needed
  
      doc.setFontSize(10)
      doc.setFont("Roboto", "bold")
      doc.text("OFFICE OF THE DEPUTY VICE CHANCELLOR", 100, 30, {
        align: "center",
      })

      doc.setFontSize(8)
      doc.setFont("Roboto", "bold")
      doc.text("(ACADEMIC, RESEARCH AND CONSULTANCY)", 100, 35, {
        align: "center",
      })
  
      doc.setFontSize(8)
      doc.setFont("Roboto", "bold")
      doc.text("P.O. Box 3000, CHUO KIKUU, MOROGORO TANZANIA)", 100, 40, {
        align: "center",
      })
  
      doc.setFontSize(7)
      doc.setFont("Roboto", "bold")
      doc.text(
        "Tel. +255-23-2603511/4, Dir. +255-23-2603236; Fax. +255-23-2604652)",
        100,
        45,
        { align: "center" }
      )
  
      doc.setFontSize(7)
      doc.setFont("Roboto", "bold")
      doc.text(
        "E-mail: dvc@sua.ac.tz or admission@sua.ac.tz)",
        100,
        50,
        { align: "center" }
      )
  
      // Add the right image
      doc.addImage(pasport_size, "PNG", 170, 10, 30, 30) // Adjust position and size as needed
  
      doc.setFontSize(11)
      doc.setFont("Roboto", "bold")
      doc.text("REGISTRATION FORM FOR UNDERGRADUATE CANDIDATES", 100, 60, {
        align: "center",
      })
  
      doc.setFontSize(11)
      doc.setFont("Roboto", "normal")
      doc.text("1. SURNAME: ", 20, 70, { align: "left" })
      doc.setFont("Roboto", "bold")
      doc.text(student?.lastName, 80, 70, { align: "left" })
  
      doc.setFontSize(11)
      doc.setFont("Roboto", "normal")
      doc.text("2. FIRST NAME IN FULL: ", 20, 80, { align: "left" })
      doc.setFont("Roboto", "bold")
      doc.text(student?.firstName, 80, 80, { align: "left" })
  
      doc.setFontSize(11)
      doc.setFont("Roboto", "normal")
      doc.text("3. MIDDLE NAMES IN FULL: ", 20, 90, { align: "left" })
      doc.setFont("Roboto", "bold")
      doc.text(student?.middleName, 80, 90, { align: "left" })
  
      doc.setFontSize(11)
      doc.setFont("Roboto", "normal")
      doc.text("4. DEGREE PROGRAMME: ", 20, 100, { align: "left" })
      doc.setFont("Roboto", "bold")
      doc.text(programName, 80, 100, { align: "left" })
  
      doc.setFontSize(11)
      doc.setFont("Roboto", "normal")
      doc.text("5. REGISTRATION No. ", 20, 110, { align: "left" })
      doc.setFont("Roboto", "bold")
      doc.text(studentByUsername?.student?.registrationNumber, 80, 110, { align: "left" })
  
      doc.setFontSize(11)
      doc.setFont("Roboto", "normal")
      doc.text("6. Date of Birth: ", 20, 120, { align: "left" })
      doc.setFont("Roboto", "bold")
      doc.text(studentByUsername?.dateOfBirth, 80, 120, { align: "left" })
  
      doc.setFontSize(11)
      doc.setFont("Roboto", "normal")
      doc.text("7. Place of Birth: ", 20, 130, { align: "left" })
      doc.setFont("Roboto", "bold")
      doc.text(studentByUsername?.placeOfBirth, 80, 130, { align: "left" })
  
      doc.setFontSize(11)
      doc.setFont("Roboto", "normal")
      doc.text("8. Marital status: ", 20, 140, { align: "left" })
      doc.setFont("Roboto", "bold")
      doc.text(studentByUsername?.maritalStatus?.name, 80, 140, { align: "left" })
  
      doc.setFontSize(11)
      doc.setFont("Roboto", "normal")
      doc.text("9. Sex: ", 20, 150, { align: "left" })
      doc.setFont("Roboto", "bold")
      doc.text(studentByUsername?.gender, 80, 150, { align: "left" })
  
      doc.setFontSize(11)
      doc.setFont("Roboto", "normal")
      doc.text("10. Citizenship: ", 20, 160, { align: "left" })
      doc.setFont("Roboto", "bold")
      doc.text(studentByUsername?.country?.name, 80, 160, { align: "left" })
  
      doc.setFontSize(11)
      doc.setFont("Roboto", "normal")
      doc.text("11. Country of Residence: ", 20, 170, { align: "left" })
      doc.setFont("Roboto", "bold")
      doc.text(studentByUsername?.country?.name, 80, 170, { align: "left" })
  
      doc.setFontSize(11)
      doc.setFont("Roboto", "normal")
      doc.text("12. Contact Address: ", 20, 180, { align: "left" })
      doc.setFont("Roboto", "bold")
      doc.text(
        studentByUsername?.physicalAddress +
          " | " +
        studentByUsername?.email +
          " | " +
        studentByUsername?.phoneNumber,
        80,
        180,
        { align: "left" }
      )
  
      doc.setFontSize(11)
      doc.setFont("Roboto", "normal")
      doc.text("13. Name of next of kin: ", 20, 190, { align: "left" })
      doc.setFont("Roboto", "bold")
      doc.text(studentNextOfKin, 80, 190, { align: "left" })
  
      doc.setFontSize(11)
      doc.setFont("Roboto", "normal") // Correct the font name to "Roboto"
  
      doc.setFontSize(11)
      doc.setFont("Roboto", "normal")
      doc.text("14. Do you have any disabilities? ", 20, 200, {
        align: "left",
      })
      doc.setFont("Roboto", "bold")
  
      if (studentByUsername?.isDisabled) {
        const disability = studentByUsername.userDisabilities[0].name
        doc.text(`YES : ${disability}`, 80, 200, { align: "left" })
      } else {
        doc.text("NO", 80, 200, { align: "left" })
      }
  
      doc.setFontSize(11)
      doc.setFont("Roboto", "normal")
      doc.text("15. Category of studentship: ", 20, 210, { align: "left" })
      doc.setFont("Roboto", "bold")
      doc.text(studentByUsername?.student?.entryCategory?.name ||  entryCate, 80, 210, { align: "left" })
  
      doc.setFontSize(10)
      doc.setFont("Roboto", "normal")
      doc.text(
        "16. Have you been officially released by your employer? YES/NO (where applicable) \n If yes, attach the documentary evidence ",
        20,
        220,
        { align: "left" }
      )
      doc.setFont("Roboto", "bold")
      doc.text("…………………………………..", 140, 220, { align: "left" })
  
      doc.setFontSize(10)
      doc.setFont("Roboto", "normal")
      doc.text("17. Financial Sponsor: ", 20, 230, { align: "left" })
      doc.setFont("Roboto", "bold")
      studentByUsername?.student?.sponsors.forEach((sponsor, index) => {
        doc.text(sponsor.name, 80, 230, { align: "left" })
      })
      // doc.text("", 60, 210, { align: "left" })
  
      doc.setFontSize(10)
      doc.setFont("Roboto", "bold")
      doc.text("18. STATEMENT BY APPLICANT", 20, 240, { align: "left" })
      doc.setFont("Roboto", "normal")
      doc.text(
        "\n I certify that the information given above is true and correct to be best of my knowledge.",
        30,
        240,
        { align: "left" }
      )
  
      doc.setFontSize(10)
      doc.setFont("Roboto", "bold")
      doc.text("Signature of Applicant:", 90, 260, { align: "left" })
  
      doc.setFontSize(10)
      doc.setFont("Roboto", "bold")
      doc.text("Date:", 100, 270, { align: "left" })
  
      doc.setFontSize(6)
      doc.setFont("Roboto", "bold")
      doc.setTextColor("green")
      doc.text("Printed by SUA-ESB " + "Date: " + formattedDate, 90, 295, {
        align: "center",
      })
  
      // Save the PDF
      doc.save(student?.firstName + "_" + student?.lastName + ".pdf")
    } catch (error) {
      showToast("Something went wrong try again later! " + error, "info")
    }
  }else{
    showToast("Registration Service failed to provide a Program name, please try again later or contact the help center.", "info")
  }
}
