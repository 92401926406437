import {useMutation, useQuery} from "@apollo/client";
import {GET_STUDENT} from "../../Users/Queries/UserQuery";
import {registrationGraphQLClient, uaaGraphQLClient} from "../../Authentication/ApolloClient";
import {GET_CONTROL_NUMBER, GET_FEE_STRUCTURE} from "../../Students/Queries/Payment/StudentPaymentQueries";
import {LoaderPage} from "../../../helpers/LoaderPage";
import LayoutHelper from "../../../helpers/LayoutHelper";
import {Card, CardBody, CardSubtitle, Col, Form, Row} from "reactstrap";
import React, {useContext, useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import SubmitButtonHelper from "../../../helpers/SubmitButtonHelper";
import {useFormik} from "formik";
import showToast from "../../../helpers/ToastHelper";
import {REFRESH_MUTATION} from "../../Authentication/Mutations/LoginMutation";
import {STUDENT_FINAL_CONFIRMATION} from "../../Students/Mutations/StudentFinalConfirmation";
import { GET_USER_NEXT_OF_KIN } from "../../Students/Queries/NextOfKin/NextOfkinQueries"

const breadcrumbItem = [
    {
        title: "Self registration",
        path: "",
        isActive: true,
    },
]

const SelfRegistrationConfirmation = () => {
    const navigate = useNavigate()
    const {
        loading: userLoading,
        error: userError,
        data: userDetails,
        refetch,
    } = useQuery(GET_STUDENT, {
        client: uaaGraphQLClient,
        variables: {
            username: JSON.parse(localStorage.getItem("authUser"))?.data?.username,
        },
        fetchPolicy: "network-only",
    })

    const user = userDetails?.getUserByUsername?.data


    const {
        loading: loadingFeeStructureControlNumbers,
        error: errorFeeStructure,
        data: feeStructureControlNumber,
        refetch: refetchControlNUmbers,
    } = useQuery(GET_CONTROL_NUMBER, {
        client: registrationGraphQLClient,
        skip:
            !user?.student?.registrationNumber,
        variables: {
            registrationNumber: user?.student?.registrationNumber,
        },
        fetchPolicy: "network-only",
    })

    const feeWithStructureControlNumber =
        feeStructureControlNumber?.getControlNumbers?.data




    const refreshToken = JSON.parse(
        localStorage.getItem("authUser")
    )?.refreshToken



    const [fetchAssignedPermissions, setFetchAssignedPermissions] =
        useState(false)

    const {
        data: accessTokenData,
        loading: accessTokenLoading,
    } = useMutation(REFRESH_MUTATION, {
        client: uaaGraphQLClient,
        variables: { refreshToken },
        skip: !user?.uid || !fetchAssignedPermissions,
        onError: error => {
            console.error("Error fetching assigned permissions:", error)
        },
    })

    const [Student] = useMutation(STUDENT_FINAL_CONFIRMATION, {
        client: uaaGraphQLClient,
    })

    const { loading, error, data } = useQuery(GET_USER_NEXT_OF_KIN, {
        client: uaaGraphQLClient,
        skip: !user?.uid,
        variables: {
            userUid: user?.uid,
        },
        fetchPolicy: 'network-only',
    })

    const result = data?.getUserNextOfKins.data

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            userUid: user ? user?.uid : "",
        },

        onSubmit: (values, { resetForm }) => {
            Student({
                variables: {
                    input: { ...values },
                },

                onCompleted: ({ studentSelfRegistration }) => {
                    if (studentSelfRegistration.code === 8000) {
                        //Grab permissions for this student
                        setFetchAssignedPermissions(true)
                        // toggleTab(6)
                    } else {
                        console.log("Failed to update data")
                    }
                    showToast(
                        studentSelfRegistration.message,
                        studentSelfRegistration.code === 8000 ? "success" : "error"
                    )
                    validation.resetForm()
                },
                onError: error => {
                    // Handle errors
                    console.error("Mutation error:", error)
                },
            })
        },
    })

    const studentStatus = user?.student?.status?.name

    useEffect(() => {
        if (!accessTokenLoading && accessTokenData) {

            if (!!accessTokenData.refreshToken.accessToken) {

                const authUser = JSON.parse(localStorage.getItem("authUser"))
                authUser.accessToken = accessTokenData.refreshToken.accessToken
                localStorage.setItem("authUser", JSON.stringify(authUser))

                navigate("/")
            } else {
                showToast("Failed to update your profile", "error")
            }

        }
    }, [accessTokenLoading, accessTokenData])

    if(userLoading) return <LoaderPage breadcrumbItem={breadcrumbItem} title={"Registration"} description={"Fetching User Details"} />
    if(loading) return <LoaderPage breadcrumbItem={breadcrumbItem} title={"Validating"} description={"Validating other information"} />
    if(loadingFeeStructureControlNumbers) return <LoaderPage breadcrumbItem={breadcrumbItem} title={"Registration"} description={"Fetching Control Number Information"} />
    if(!feeWithStructureControlNumber) navigate("/self-registration/payments")

    console.log(result)
    if (result?.length < 1){
        showToast("Please fill up next of kin information", "info")
        navigate("/self-registration/next-of-kin-information");
    }
    // console.log("Fees", fees)
    // console.log("controls", feeWithStructureControlNumber)
    return (
        <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle={"Confirm Information's"}>
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <CardSubtitle>
                                <h4>Registration Confirmation</h4>
                            </CardSubtitle>
                            <Form
                                onSubmit={e => {
                                    e.preventDefault()
                                    validation.handleSubmit()
                                    return null
                                }}
                            >
                                <Row>
                                    <Col lg="12">
                                        <div className="mb-3">
                                            <p className="text-muted text-center">
                                                <h5>Confirm Your Details</h5>
                                            </p>
                                        </div>
                                        <div className="table-responsive">
                                            <table
                                                id="tech-companies-1"
                                                className="table table-striped table-bordered"
                                                style={{ border: "2px solid #63a69f" }}
                                            >
                                                <tbody>
                                                <tr>
                                                    <th>Full name</th>
                                                    <td>
                                                        {user?.firstName +
                                                            " " +
                                                            user?.middleName +
                                                            " " +
                                                            user?.lastName}
                                                    </td>
                                                    {/* </tr>
                  <tr> */}
                                                    <th>Gender</th>
                                                    <td>{user?.gender}</td>
                                                </tr>
                                                <tr>
                                                    <th>Marital Status</th>
                                                    <td>{user?.maritalStatus?.name}</td>
                                                    {/* </tr>
                  <tr> */}
                                                    <th>Email</th>
                                                    <td>{user?.email}</td>
                                                </tr>
                                                <tr>
                                                    <th>Phone</th>
                                                    <td>{user?.phoneNumber}</td>
                                                    {/* </tr>
                  <tr> */}
                                                    <th>Place of Birth</th>
                                                    <td>{user?.placeOfBirth}</td>
                                                </tr>
                                                <tr>
                                                    <th>Date of Birth</th>
                                                    <td>{user?.dateOfBirth}</td>
                                                    {/* </tr>
                  <tr> */}
                                                    <th>Postal Address</th>
                                                    <td>{user?.postalAddress}</td>
                                                </tr>
                                                <tr>
                                                    <th>Physical Address</th>
                                                    <td>{user?.physicalAddress}</td>
                                                    {/* </tr>
                  <tr> */}
                                                    <th>NIDA No</th>
                                                    <td>{user?.nin}</td>
                                                </tr>
                                                <tr>
                                                    <th>Country</th>
                                                    <td>{user?.country?.name}</td>
                                                    {/* </tr>
                  <tr> */}
                                                    <th>Region</th>
                                                    <td>
                                                        {user?.student?.district?.region?.name}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>District</th>
                                                    <td>{user?.student?.district?.name}</td>
                                                    {/* </tr>
                  <tr> */}
                                                    <th>Bank Account</th>
                                                    <td>{user?.student?.accountNumber}</td>
                                                </tr>
                                                <tr>
                                                    <th>Bank Type Name</th>
                                                    <td>{user?.student?.bank?.name}</td>
                                                    {/* </tr>
                  <tr> */}
                                                    <th>Sponsors</th>
                                                    <td>
                                                        {user?.student?.sponsors?.map(sponsor => (
                                                            <ul key={sponsor?.uid}>
                                                                <li>{sponsor?.name}</li>
                                                            </ul>
                                                        ))}
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                            {studentStatus && studentStatus == "UNREGISTERED" && (
                                                <div className="mb-3">
                                                    <p className="text-muted text-center">
                                                        If all information provided are correct then Click Confirm
                                                        button below
                                                    </p>
                                                    <div className="text-center mt-3">
                                                        <SubmitButtonHelper
                                                            type="primary"
                                                            name="Confirm ?"
                                                            formik={validation}
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                                <div className="wizard">
                                    <div className="actions">
                                        <ul>
                                            <li className="previous">
                                                <Link to="#" onClick={() => navigate('/self-registration/hostel')}>
                                                    {`< Previous`}
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </LayoutHelper>
    )
}
export default SelfRegistrationConfirmation
