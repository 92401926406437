import { Link } from "react-router-dom"
import React from "react"

const RegistrationLinks = ({ hasAuthority }) => {
  return (
    <>
      <li>
        <Link to="/self-registration/dashboard">
          <i className="bx bx-home-circle"></i>
          <span>Dashboard</span>
        </Link>
      </li>

      {hasAuthority("VIEW_REGISTRATION_MENU") && (
          <>
              <li>
                  <Link to="/self-registration/payments">
                      <i className="bx bxs-dollar-circle"></i>
                      <span>Payment & Fees</span>
                  </Link>
              </li>
              <li>
                  <Link to="/self-registration/my-information">
                      <i className="bx bxs-user-badge"></i>
                      <span>My Information</span>
                  </Link>
              </li>
              <li>
                  <Link to="/self-registration/contact-information">
                      <i className="bx bxs-contact"></i>
                      <span>Contact Details</span>
                  </Link>
              </li>
              <li>
                  <Link to="/self-registration/next-of-kin-information">
                      <i className="bx bx-group"></i>
                      <span>Next Of Kin Information</span>
                  </Link>
              </li>
              {/*<li>*/}
              {/*    <Link to="/self-registration/hostel">*/}
              {/*        <i className="bx bx-building-house"></i>*/}
              {/*        <span>Hostel</span>*/}
              {/*    </Link>*/}
              {/*</li>*/}
              <li>
                  <Link to="/self-registration/confirmation">
                      <i className="bx bx-check-circle"></i>
                      <span>Confirmation</span>
                  </Link>
              </li>
          </>
      )}
    </>
  )
}

export default RegistrationLinks
