import React, { useEffect, useState } from "react"
import LayoutHelper from "../../../../helpers/LayoutHelper"
import "flatpickr/dist/themes/material_blue.css"
import { useNavigate } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Row
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import PlaceHolderLoader from "../../../../helpers/PlaceHolderLoader"
import ToastHelper from "../../../../helpers/ToastHelper"
import Select from "react-select"
import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import SubmitButtonHelper from "../../../../helpers/SubmitButtonHelper"
import { CREATE_STAFF } from "Modules/Users/Mutations/UserMutations"
import { GET_STUDENT } from "../../../Users/Queries/UserQuery"
import { registrationGraphQLClient, uaaGraphQLClient } from "../../../Authentication/ApolloClient"
import { REQUEST_PROBATION_CONTROL_NUMBER } from "../../Queries/Payment/StudentPaymentQueries"
import showToast from "../../../../helpers/ToastHelper"

const breadcrumbItem = [
  {
    title: "Payments Service",
    path: "/Students/Payments",
    isActive: false
  },
  {
    title: "Request Service",
    path: "",
    isActive: true
  }
]

const StudentPaymentServices = props => {

  const {
    loading: loadingData,
    error: userByusernameError,
    data: userByusernameData,
    userByusernameRefetch,
  } = useQuery(GET_STUDENT, {
    client: uaaGraphQLClient,
    skip: !JSON.parse(localStorage.getItem("authUser"))?.data?.username,
    variables: {
      username: JSON.parse(localStorage.getItem("authUser"))?.data?.username,
    },
    fetchPolicy: "no-cache",
  })
  const user = userByusernameData?.getUserByUsername?.data
  const [refetchProbation,
    {
      loading: controlNumberLoading,
      error: error,
      data: controlNumber
    }] = useLazyQuery(REQUEST_PROBATION_CONTROL_NUMBER, {
    client: registrationGraphQLClient,
    variables: {},
    fetchPolicy: "network-only"
  })

  const [payment] = useMutation(CREATE_STAFF)

  const navigate = useNavigate()
  const onchangeValues = (e, input) => {
    validationType.setFieldValue(input, e.value)
  }

  // Form validation
  const validationType = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      service: ""
    },
    validationSchema: Yup.object().shape({
      service: Yup.number().required("Service is required")
    }),
    onSubmit: values => {
      if (values.service) {
        refetchProbation({
          variables: {
            registrationNumber: user?.student?.registrationNumber,
            studentName: user?.firstName + " " + user?.middleName + " " + user?.lastName
          },
          onCompleted: ({ requestProbationControlNumber }) => {
            if (requestProbationControlNumber?.code === 8000) {
              showToast(requestProbationControlNumber?.message, "success")
              validationType.setSubmitting(false)
            } else {
              showToast(requestProbationControlNumber?.message, "error")
              validationType.setSubmitting(false)
            }
          },
          onError: (error) => {
            validationType.setSubmitting(false)
          }
        })
      }
      payment({ variables: values })
        .then((response) => {
          // Handle the response
          console.log("Mutation response:", response)
        })
        .catch((error) => {
          // Handle errors
          console.error("Mutation error:", error)
        })
      ToastHelper("Service requested successful", "success")
      validationType.resetForm
      //navigate('/Students/Payments')
    }

  })


  const [services, setServices] = useState([]),
    [fetching, setFetching] = useState(true)

  useEffect(() => {
    // setFetching(true)
    setFetching(false)
    setTimeout(() => {
      fetchData()
    }, 1000)
  }, [])
  //
  const fetchData = () => {
    // API Call
    setServices([])
    setFetching(false)
    // setServicesImage('image_path')
  }

  const ServiceOpt = [
    { label: "Probation Control Number", value: 6 },
    { label: "Certificate Certification (10,000 TZS / 10 USD)", value: 5 },
    { label: "English Certificate (10,000 TZS / 10 USD)", value: 4 },
    { label: "Full Transcript (20,000 TZS / 20 USD)", value: 3 },
    { label: "Graduation Gown (20,000 TZS / 20 USD)", value: 1 },
    { label: "Partial Transcript (10,000 TZS / 10 USD)", value: 2 }
  ]

  const controlNumberResults = controlNumber?.requestProbationControlNumber?.data


  return (
    <LayoutHelper
      breadcrumbItem={breadcrumbItem}
      pageTitle="Request Control Numbers"
    >
      <Row>
        {fetching || controlNumberLoading ? (
          <PlaceHolderLoader buttonShown={true} columSize={6} />
        ) : (
          <Col lg={6}>
            <Card>
              <CardBody>
                <Form
                  onSubmit={e => {
                    e.preventDefault()
                    validationType.handleSubmit()
                    return false
                  }}
                >
                  <div className="mb-3">
                    <Label>Payment Services</Label>
                    <Select
                      // value={selectedGroup}
                      onChange={e => onchangeValues(e, "service")}
                      options={ServiceOpt}
                      className="select2-selection"
                    />

                    <Input
                      name="service"
                      placeholder="Enter yourAddresses"
                      type="hidden"
                      rows={3}
                      onChange={validationType.handleChange}
                      onBlur={validationType.handleBlur}
                      value={validationType.values.service || ""}
                      invalid={
                        !!(
                          validationType.touched.service &&
                          validationType.errors.service
                        )
                      }
                    />
                    {validationType.touched.service &&
                    validationType.errors.service ? (
                      <FormFeedback type="invalid">
                        {validationType.errors.service}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <SubmitButtonHelper type={"primary"} name={"Submit Request"} formik={validationType} />
                </Form>
              </CardBody>
            </Card>
          </Col>
        )}
      </Row>
      <Row>
        <Col md={12}>
          {controlNumberLoading ? (
            <PlaceHolderLoader buttonShown={true} columSize={6} />
          ) : (
            <Card>
              <CardBody>
                <table className="table table-bordered table-striped">
                  <thead>
                  <tr>
                    <th>S/No</th>
                    <th>Fee name</th>
                    <th>Amount</th>
                    <th>Control number</th>
                    <th>Currency</th>
                    <th>Academic year</th>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    controlNumberResults?.map((controlNumber, index) => (
                      <tr key={controlNumber?.feeName}>
                        <td>{0 + index + 1}</td>
                        <td>{controlNumber?.feeName}</td>
                        <td>{controlNumber?.amount}</td>
                        <td>{controlNumber?.controlNumber}</td>
                        <td>{controlNumber?.currency}</td>
                        <td>{controlNumber?.academicYear}</td>
                      </tr>
                    ))
                  }
                  </tbody>
                </table>
              </CardBody>
            </Card>
          )
          }
        </Col>
      </Row>
    </LayoutHelper>
  )
}

export default StudentPaymentServices