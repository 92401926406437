import { Link } from "react-router-dom"
import React from "react"
import { useSelector } from "react-redux"
import sidebarReducer from "../../../Modules/Redux/Reducers/ApplicantSideBarReducers"

// VIEW_OAS_MODULE
const ApplicantLinks = ({ hasAuthority }) => {
  const selectedStatus = useSelector((state) => state.sidebarReducer.selectedStatus)
  console.log("HERE now ", selectedStatus)
  return (
    <>
      {/*{*/}
      {/*  hasAuthority("VIEW_OAS_MODULE") && (*/}
      <>

        <li>
          <Link to="/applicant/dashboard">
            <i className="bx bx-home-circle"></i>
            <span>Dashboard</span>
          </Link>
        </li>
        <li>
          <Link to="/applicant/profile-information"
                className={selectedStatus === true ? "disabled-link" : ""}
                onClick={(e) => {
                  if (selectedStatus === true) {
                    e.preventDefault() // Prevent navigation
                  }
                }}
          >
            <i className="bx bx-user-circle"></i>
            <span>Update Information</span>
          </Link>
        </li>
        <li>
          <Link to="/applicant/application-method"
                className={selectedStatus === true ? "disabled-link" : ""}
                onClick={(e) => {
                  if (selectedStatus === true) {
                    e.preventDefault() // Prevent navigation
                  }
                }}
          >
            <i className="bx bx-door-open"></i>
            <span>Application Method</span>
          </Link>
        </li>
        <li>
          <Link to="/applicant/payment"
                className={selectedStatus === true ? "disabled-link" : ""}
                onClick={(e) => {
                  if (selectedStatus === true) {
                    e.preventDefault() // Prevent navigation
                  }
                }}
          >
            <i className="bx bx-dollar"></i>
            <span>Payments</span>
          </Link>
        </li>
        <li>
          <Link to="/applicant/entry-qualification"
                className={selectedStatus === true ? "disabled-link" : ""}
                onClick={(e) => {
                  if (selectedStatus === true) {
                    e.preventDefault() // Prevent navigation
                  }
                }}
          >
            <i className="bx bx-door-open"></i>
            <span>Entry Qualification</span>
          </Link>
        </li>
        <li>
          <Link to="/applicant/program-selection"
                className={selectedStatus === true ? "disabled-link" : ""}
                onClick={(e) => {
                  if (selectedStatus === true) {
                    e.preventDefault() // Prevent navigation
                  }
                }}
          >
            <i className="bx bx-list-check"></i>
            <span>Program selection</span>
          </Link>
        </li>
        <li>
          <Link to="/applicant/confirmation"
                className={selectedStatus === true ? "disabled-link" : ""}
                onClick={(e) => {
                  if (selectedStatus === true) {
                    e.preventDefault() // Prevent navigation
                  }
                }}
          >
            <i className="bx bx-check"></i>
            <span>Confirmation</span>
          </Link>
        </li>

      </>
      {/*  )*/}
      {/*}*/}
    </>
  )
}

export default ApplicantLinks
