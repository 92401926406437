import React, { useState, useEffect, createContext } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
} from "reactstrap"
import classnames from "classnames"
import { GET_STUDENT } from "Modules/Users/Queries/UserQuery"
import { useQuery } from "@apollo/client"
import LayoutHelper from "helpers/LayoutHelper"
import { uaaGraphQLClient } from "Modules/Authentication/ApolloClient"
import NhifPersonalInformationTab from "./NhifPersonalInformationTab"
import RequestControlNumberTab from "./RequestControlNumberTab"

export const StudentProfileContext = createContext() // Create data to transfer to child modal


const NhifRegistration = () => {

  const [StudentData, setStudentData] = useState(null)
  const [activeTab, setactiveTab] = useState(1)
  const [passedSteps, setPassedSteps] = useState([1])

  const renderStepContent = tabId => {
    switch (tabId) {
      case 1:
        return <NhifPersonalInformationTab />
      case 2:
        return <RequestControlNumberTab />
      default:
        return null
    }
  }

  const breadcrumbItem = [
    {
      title: "NHIF registration",
      path: "",
      isActive: true,
    },
  ]

  const toggleTab = tab => {
    handleSubmit()
    if (activeTab !== tab) {
      const modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 2) {
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
    }
  }

  const {
    loading: loadingData,
    error: userByusernameError,
    data: userByusernameData,
    userByusernameRefetch,
  } = useQuery(GET_STUDENT, {
    client: uaaGraphQLClient,
    skip: !JSON.parse(localStorage.getItem("authUser"))?.data?.username,
    variables: {
      username: JSON.parse(localStorage.getItem("authUser"))?.data?.username,
    },
    fetchPolicy: "cache-first",
  })

  useEffect(() => {
    const studentDetails = userByusernameData?.getUserByUsername?.data
    setStudentData(studentDetails)
  }, [userByusernameData]);

  const handleSubmit = values => {
  }

  return (
    <StudentProfileContext.Provider
      value={{
        StudentData,
        setStudentData,
        toggleTab,
        handleSubmit,
        activeTab,
        passedSteps,
      }}
    >
      <LayoutHelper
        breadcrumbItem={breadcrumbItem}
        pageTitle="NHIF Registration"
      >
        <div className="page-content">
          <div className="mt-1">
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div className="wizard">
                      <div className="steps clearfix">
                        <ul style={{ whiteSpace: "nowrap" }}>
                          <NavItem
                            className={classnames({
                              current: activeTab === 1,
                            })}
                          >
                            <NavLink
                              className={classnames({
                                current: activeTab === 1,
                              })}
                              onClick={() => toggleTab(1)}
                              // disabled={!(passedSteps || []).includes(1)}
                            >
                              <span className="number">1.</span> NHIF Control Number Request
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({
                              current: activeTab === 2,
                            })}
                          >
                            
                            <NavLink
                              className={classnames({
                                active: activeTab === 2,
                              })}
                              onClick={() => toggleTab(2)}
                              // disabled={!(passedSteps || []).includes(2)}
                            >
                              <span className="number">2.</span> View NHIF Control number.
                            </NavLink>
                          </NavItem>
                        </ul>
                      </div>
                      <div className="content clearfix">
                        <TabContent activeTab={activeTab} className="body">
                          {renderStepContent(activeTab)}
                        </TabContent>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </LayoutHelper>
    </StudentProfileContext.Provider>
  )
}

export default NhifRegistration
