import React, { useState } from "react"
import {
  Alert, Button,
  Card,
  CardBody,
  CardSubtitle, CardText,
  Col,
  Form,
  Row
} from "reactstrap"
import LayoutHelper from "../../../helpers/LayoutHelper"
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table"
import SubmitButtonHelper from "../../../helpers/SubmitButtonHelper"
import { useMutation, useQuery } from "@apollo/client"
import { GET_APPLICANT_CERTIFICATE_RESULT, GET_APPLICANT_DETAILS } from "../Queries/ApplicantQueries"
import { onlineApplicationGraphQLClient, uaaGraphQLClient } from "../../Authentication/ApolloClient"
import { useFormik } from "formik"
import showToast from "../../../helpers/ToastHelper"
import { SAVE_CONFIRM_APPLICATION } from "../../OnlineApplication/ProgramSelection/Mutations/ProgramSelectionMutations"
import { DateConversion } from "../../../helpers/ConvertDate"
import {
  GET_ACTIVE_ADMISSION_WINDOWS,
  GET_APPLICANT_SELECTION
} from "../../OnlineApplication/ProgramSelection/Queries/ProgramSelectionQueries"
import { limit } from "../../../helpers/UrlHelper"
import { Link, useNavigate } from "react-router-dom"
import { GET_STUDENT } from "../../Users/Queries/UserQuery"

const breadcrumbItem = [
  {
    title: "Confirm Application",
    path: "",
    isActive: true
  }
]

const ApplicantConfirmation = () => {
  const navigate = useNavigate()
  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(limit)
  const startIndex = currentPage * pageSize
  const endIndex = (currentPage + 1) * pageSize
  const ExtractUserAuth = JSON.parse(localStorage.getItem("authUser"))?.data

  const { loading: applicantLoading, error: applicantError, data: applicantData } = useQuery(GET_APPLICANT_DETAILS, {
    client: onlineApplicationGraphQLClient,
    variables: {
      userUid: ExtractUserAuth?.uid
    },
    fetchPolicy: "network-only"
  })
  const applicant = applicantData?.getApplicantDetails.data
  const [confirm] = useMutation(SAVE_CONFIRM_APPLICATION, {
    client: onlineApplicationGraphQLClient
  })
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      userUid: applicant ? applicant?.uid : ""
    },

    onSubmit: (values, { setSubmitting }) => {
      confirm({
        refetchQueries: [
          {
            query: GET_APPLICANT_DETAILS,
            variables: {
              userUid: ExtractUserAuth?.uid
            }
          }
        ],
        variables: {
          applicantUid: applicant?.uid
        },
        onCompleted: ({ saveConfirmApplication }) => {
          if (saveConfirmApplication.code === 8000) {
            setSubmitting(false)
            navigate("/applicant/dashboard")
          }
          setSubmitting(false)
          showToast(
            saveConfirmApplication.message,
            saveConfirmApplication.code === 8000 ? "success" : "error"
          )
        },
        onError: error => {
          setSubmitting(false)
          console.error("Mutation error:", error)
        }
      })
    }
  })

  const {
    loading: activeWindowLoading,
    error: activeWindowError,
    data: activeWindowData,
    refetch: refetchActiveAdmissionWindow
  } = useQuery(GET_ACTIVE_ADMISSION_WINDOWS, {
    client: onlineApplicationGraphQLClient,
    skip: !applicantData?.getApplicantDetails?.data?.applicationLevelCode,
    variables: {
      applicationLevelCode:
      applicantData?.getApplicantDetails?.data?.applicationLevelCode
    },
    fetchPolicy: "network-only"
  })

  const activeWindow = activeWindowData?.getActiveAdmissionWindow?.data

  const { loading, error, data, refetch } = useQuery(GET_APPLICANT_SELECTION, {
    client: onlineApplicationGraphQLClient,
    skip: !activeWindow?.uid || !applicant?.uid,
    variables: {
      applicantUid: applicant?.uid,
      admissionWindowUid: activeWindow?.uid
    },
    fetchPolicy: "network-only"
  })

  const {
    loading: userLoading,
    error: userError,
    data: userDetails,
    refetch: userRefetch
  } = useQuery(GET_STUDENT, {
    client: uaaGraphQLClient,
    variables: {
      username: JSON.parse(localStorage.getItem("authUser"))?.data?.username
    },
    fetchPolicy: "cache-first"
  })

  const applicantInfo = userDetails?.getUserByUsername?.data


  const {
    loading: applicantResultLoading,
    error: applicantResultError,
    data: applicantResultData
  } = useQuery(GET_APPLICANT_CERTIFICATE_RESULT, {
    client: onlineApplicationGraphQLClient,
    skip: !applicantInfo?.uid,
    variables: {
      userUid: applicantInfo?.uid
    },
    fetchPolicy: "network-only"
  })

  const results = data?.getApplicantSelections?.data?.items

  const certificateResults = applicantResultData?.getApplicantCertificate?.data?.items

  if (results?.length < 1){
    showToast("Please make program selection first so that you can proceed", 'warning');
    navigate("/applicant/program-selection");
  }

  return (
    <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle={"Application confirmation"}>
      <Row>
        <Col>
          <Card>
            <CardBody>


              {
                applicantLoading ||
                activeWindowLoading || loading || userLoading || applicantResultLoading
                  ? (
                    <div className="text-center">
                      <i className="fas fa-spinner fa-spin fa-5x text-success align-middle me-2" />
                      <br />
                      <strong>Please wait...</strong>
                    </div>
                  ) : (
                    <>
                      {
                        activeWindow ? (
                          <Form
                            onSubmit={e => {
                              e.preventDefault()
                              validation.handleSubmit()
                              return null
                            }}
                          >

                            <p className="text-muted text-center p-3">
                              {
                                applicant?.submissionStatus === false ? (
                                  <b>Please read and confirm all details careful before submitting your application</b>

                                ) : applicant?.submissionStatus === true &&(
                                  (
                                    <Row>
                                      <Col md={12} className="alert alert-success">
                                        <b>You have successfully submitted the application.
                                          Please wait for selection!</b>
                                      </Col>
                                    </Row>
                                  )
                                )
                              }
                            </p>
                            <CardSubtitle>
                              <h4>1. Basic Details</h4>
                            </CardSubtitle>
                            <Row>
                              <Col md={12}>
                                <div className="table-responsive">
                                  <table
                                    id="tech-companies-1"
                                    className="table table-striped table-bordered"
                                    style={{ border: "2px solid #63a69f" }}
                                  >
                                    <tbody>
                                    <tr>
                                      <th>Full name</th>
                                      <td>
                                        {applicant?.firstName +
                                          " " +
                                          applicant?.middleName +
                                          " " +
                                          applicant?.lastName}
                                      </td>
                                      <th>Gender</th>
                                      <td>{applicant?.gender}</td>
                                    </tr>
                                    <tr>
                                      <th>Email</th>
                                      <td>{applicant?.email}</td>
                                      <th>Physical Address</th>
                                      <td>{applicant?.physicalAddress}</td>
                                    </tr>
                                    <tr>
                                      <th>Phone</th>
                                      <td>{applicant?.phoneNumber}</td>
                                      <th>Date of Birth</th>
                                      <td>{DateConversion(applicant?.birthDate)}</td>
                                    </tr>
                                    <tr></tr>
                                    <tr>
                                      <th>Is Applicant Tanzania</th>
                                      <td>{applicant?.tanzanian === true ? "Yes" : "No"}</td>
                                      <th></th>
                                      <td></td>
                                    </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </Col>
                            </Row>


                            <CardSubtitle>
                              <h4>2. Results</h4>
                            </CardSubtitle>
                            <Row>
                              <Col md={12}>
                                <CardText>
                                  {certificateResults?.length > 0 && <div className="table-rep-plugin">
                                    <div
                                      className="table-responsive mb-0"
                                      data-pattern="priority-columns"
                                    >
                                      <Table
                                        id="tech-companies-1"
                                        className="table table-striped table-bordered"
                                        style={{ border: "2px solid #63a69f" }}
                                      >
                                        <Thead>
                                          <Tr>
                                            <Th>S/No</Th>
                                            <Th>INDEX NUMBER</Th>
                                            <Th>INSTITUTION NAME</Th>
                                            <Th>COMPLETION YEAR</Th>
                                            <Th>RESULTS</Th>
                                            <Th>Subject & Grades</Th>
                                          </Tr>
                                        </Thead>
                                        <Tbody>
                                          {certificateResults?.map((cert, index) => (
                                            <Tr key={cert?.uid || index}>
                                              <Td>{index + 1}</Td>
                                              <Td>{cert?.indexNumber || "N/A"}</Td>
                                              <Td>
                                                {cert.avn ? (
                                                  <>
                                                    <strong>{cert?.institutionName || "N/A"}</strong> <br />
                                                    <strong>Programme: </strong> {cert?.institutionProgram || "N/A"}
                                                  </>
                                                ) : (
                                                  cert?.institutionName || "N/A"
                                                )}
                                              </Td>
                                              <Td>{cert?.completionYear || "N/A"}</Td>
                                              <Td>
                                                {cert.results.length === 0 ? (
                                                  <>
                                                    <strong>GPA: </strong> {cert.gpa || "N/A"}, <strong>Point: </strong> {cert.point || "N/A"}
                                                  </>
                                                ) : (
                                                  <>
                                                    <strong>Division: </strong> {cert.division || "N/A"}, <strong>Point: </strong> {cert.point || "N/A"}
                                                  </>
                                                )}
                                              </Td>
                                              <Td>
                                                {cert?.results?.map((result, resultIndex) => (
                                                  <small key={resultIndex}>
                                                    {result?.subjectName + " : " + result?.grade + (resultIndex !== cert.results.length - 1 ? ", " : "")}
                                                  </small>
                                                ))}
                                              </Td>
                                            </Tr>
                                          ))}
                                        </Tbody>

                                      </Table>
                                    </div>
                                  </div>}
                                </CardText>
                              </Col>
                            </Row>


                            <CardSubtitle>
                              <h4>3. Program Selection</h4>
                            </CardSubtitle>
                            <Row>
                              <Col md={12}>
                                <Table
                                  id="tech-companies-1"
                                  className="table table-striped table-bordered"
                                  style={{ border: "2px solid #63a69f" }}
                                >
                                  <Thead>
                                    <Tr>
                                      <Th data-priority="1">S/N</Th>
                                      <Th data-priority="1">Programme Name</Th>
                                      <Th data-priority="1">Eligibility</Th>
                                      <Th data-priority="1">Choice Number</Th>
                                    </Tr>
                                  </Thead>
                                  <Tbody>
                                    {results
                                      ?.slice(startIndex, endIndex)
                                      .map((resultRow, index) => (
                                        <Tr key={resultRow?.uid}>
                                          <Td>{startIndex + index + 1}</Td>
                                          <Td>{resultRow?.programCapacity?.programName}</Td>
                                          <Td className="text-center">{resultRow?.rejectionReason ? resultRow?.rejectionReason : "--"}</Td>
                                          <Td>{resultRow?.choice}</Td>
                                        </Tr>
                                      ))}
                                  </Tbody>
                                </Table>
                              </Col>
                            </Row>


                            <Row>
                              <Col md={12}>
                                <div className="mb-3">
                                  <p className="text-muted text-center">
                                    {
                                      applicant?.submissionStatus === false && (
                                        <b>If all information provided are correct then Click Confirm button below </b>
                                      )
                                    }
                                  </p>
                                    <div className="text-center mt-3">
                                      <>
                                        {
                                          applicant?.submissionStatus === false && (
                                            <>
                                              <Link to="/applicant/program-selection"
                                                    className="btn btn-md btn-primary mx-2">
                                                {`< Previous`}
                                              </Link>
                                              <SubmitButtonHelper
                                                type="primary"
                                                name="Submit"
                                                formik={validation}
                                              />
                                            </>

                                          )
                                        }
                                      </>
                                    </div>
                                </div>
                              </Col>
                            </Row>


                          </Form>
                        ) : (
                          <Alert color="danger">
                            {`No Active Admission window currently!`}
                          </Alert>
                        )
                      }
                    </>
                  )
              }

            </CardBody>
          </Card>
        </Col>
      </Row>
    </LayoutHelper>
  )
}

export default ApplicantConfirmation