import { gql } from "@apollo/client"

const CREATE_APPLICATION_WINDOW = gql`
    mutation RegisterAdmissionWindow($inputs: [AdmissionWindowInput!]!) {
        registerAdmissionWindow(inputs: $inputs) {
            status
            code
            message
            data {
                totalCount
                items{
                    uid
                    description
                    batchNumber
                    startDate
                    endDate
                }
            }
        }
    }
`

const UPDATE_APPLICATION_WINDOW = gql`
    mutation UpdateAdmissionWindow($inputs: AdmissionWindowInput!) {
        updateAdmissionWindow(inputs: $inputs) {
            status
            code
            message
        }
    }
`

const DELETE_APPLICATION_WINDOW = gql`
    mutation RemoveAdmissionWindow($uid: String!) {
        removeAdmissionWindow(uid: $uid) {
            message
            code
            status
        }
    }
`

const ENABLE_EDIT_APPLICAMT = gql`
    mutation EnableEditApplication($applicantUid: String!){
        enableEditApplication(applicantUid:$applicantUid){
            status
            code
            message
            data
        }
    }
`;

const RECHECK_ELIGIBILITY = gql `
    mutation RecheckEligibility($input: EligibilityCheckNode!){
        refreshEligibility(input:$input){
            status
            code
            message
        }
    }
`

const RESTORE_REJECTION_FROM_TCU = gql`
mutation restoreCancelledAdmissionTcu($input: TCURestoreAdmissionRequestNode!){
  restoreCancelledAdmissionTcu(input:$input){
    status
    code
    message
  }
}
`
export { CREATE_APPLICATION_WINDOW, RESTORE_REJECTION_FROM_TCU, RECHECK_ELIGIBILITY, DELETE_APPLICATION_WINDOW, ENABLE_EDIT_APPLICAMT, UPDATE_APPLICATION_WINDOW }
