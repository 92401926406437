//REGISTE
import { gql } from "@apollo/client"


const GET_APPLICATION_METHOD_BY_LEVEL = gql`
    query GetApplicationMethodByLevel($levelUid: String!){
      getApplicationMethodByLevel(levelUid: $levelUid){
        status
        code
        message
        data{
          uid
          name
          code
        }
      }
    }
`

const GET_NECTA_APPLICANT_RESULTS = gql`
  query GetNectaApplicantResults($input: CandidateResultRequestNode!) {
   getNectaApplicantResults(input:$input){
    status
    code
    message
    data{
      particulars{
        indexNumber
        firstName
        middleName
        lastName
        sex
        centerNumber
        centerName
      }
      results{
        division
        points
      }
      subjects{
        subjectCode
        subjectName
        grade
      }
    }
  }

}
`

const GET_AVN_APPLICANT_RESULTS = gql`
  query getNacteResults($input: CandidateParticularRequestNode!) {
  getNacteResults(input:$input){
    status
    code
    message
    data{
        sex
      indexNumber
      firstName
      middleName
      lastName
      lastName
      avn
      division
      point
      gpaClassification
      gpa
      programmeName
      centerName
      completionYear
      registrationNumber
      gpaClassification
    }
  }

}
`

const GET_APPLICANT_CERTIFICATE_RESULT = gql`
  query getApplicantCertificate ($userUid: String!){
   getApplicantCertificate(userUid: $userUid){
    status
    code
    message
    data{
      items{
      applicantUid
      indexNumber
      institutionName
      completionYear
        avn
        division
        point
        gpa
        gpaClassification
        institutionProgram
        certificateType{
          code
          name
        }
      results{
        grade
        subjectName
        subjectCode
      }
      }
    }
  }

}
`

const GET_APPLICATION_LEVELS = gql`
  query GetApplicationLevel {
    getApplicationLevel{
    status
    message
    code
    data{
      uid
      name
      code
    }
  }

}
`

const GET_APPLICANT_DETAILS = gql`
    query GetApplicantDetails($userUid: String!){
        getApplicantDetails(userUid: $userUid){
            status
            code
            message
            data{
                uid
                email
                isTcuRejected
                tcuRejectionDate
                firstName
                middleName
                lastName
                phoneNumber
                gender
                tanzanian
                username
                campusName
                campusCode
                formFourIndexNumber
                qualificationMethodId
                qualificationMethodUid
                applicationLevelId
                tcuProgrammeCode
                applicationLevelUid
                applicationLevel
                qualificationMethod
                qualificationMethodCode
                applicationLevelCode
                physicalAddress
                birthDate
                tcuSelectedProgramName
                isTcuSelected
                suaProgrammeCode
                isTcuMultiple
                tcuStatusDescription
                submissionStatus
                isTcuConfirmed
            }
        }

    }
`

const GET_APPLICANT_CONTROL_NUMBER = gql`
  query GetApplicantControlNumber($applicantUid: String!){
     getApplicantControlNumber(applicantUid: $applicantUid){
        status
        code
        message
        data{
          controlNumber
          feeName
          academicYear
          amount
          currency
        }
     }
}
`

const GET_APPLICANT_PAYMENT_STATUS = gql`
  query GetApplicantPaymentStatus($applicantUid: String!){
     getApplicantPaymentStatus(applicantUid:$applicantUid){
        status
        code
        message
        data
    }
}
`
export {
  GET_NECTA_APPLICANT_RESULTS,
  GET_APPLICANT_CERTIFICATE_RESULT,
  GET_APPLICANT_DETAILS,
  GET_APPLICATION_LEVELS,
  GET_APPLICATION_METHOD_BY_LEVEL,
  GET_AVN_APPLICANT_RESULTS,
  GET_APPLICANT_CONTROL_NUMBER,
  GET_APPLICANT_PAYMENT_STATUS
}