import React, { useContext, useEffect, useRef, useState } from "react"
import { Modal, Form, Input, FormFeedback, Button, InputGroup } from "reactstrap"
import Select from "react-select"
import * as Yup from "yup"
import { CHANGE_ROOM_ALLOCATION, CREATE_COUNTRY, CREATE_ROOM_CAPACITIES } from "../Mutations/AccommodationMutations"
import { useFormik } from "formik"
import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import { limit } from "helpers/UrlHelper"
import showToast from "helpers/ToastHelper"
import { GET_BLOCK_ROOMS, GET_HOSTELS, GET_HOSTEL_BLOCKS, GET_ROOM_CAPACITIES, GET_VACANT_ROOM_CAPACITIES } from "../Queries/AccommodationQueries"
import { AccommodationListContext } from "./AccommodationList"

import {
  createUpdateFunction,
  paginationHelper,
} from "helpers/Functions/GraphqlUpdateFunction"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import { uaaGraphQLClient, registrationGraphQLClient, accomodationGraphQLClient } from "Modules/Authentication/ApolloClient"
import { GET_ACADEMICYEARS } from "Modules/AcademicYears/Queries/AcademicYearsQueries"
import { GET_PROGRAM_CATEGORIES } from "Modules/ProgramCategories/Queries/ProgramCategoryQueries"

const AccChangeRoomModal = () => {
  const { selectedRequest, setSelectedRequest, openChangeRoomModal, setOpenChangeRoomModal, callFetchStudentRequests } = useContext(AccommodationListContext)
  const [change_room_allocation] = useMutation(CHANGE_ROOM_ALLOCATION, {client: accomodationGraphQLClient})
  const formRef = useRef()

  console.log("Mbanga", selectedRequest)
  // Selected options for hostel, block & rooms
  const [selectedHostelUid, setSelectedHostelUid] = useState(null)
  const [selectedBlockUid, setSelectedBlockUid] = useState(null)
  const [selectedRooms, setSelectedRooms] = useState(null)

  // Conditionally fetched options for blocks & rooms
  const [blocks_options, setBlocksOptions] = useState([]);
  const [rooms_options, setRoomsOptions] = useState([]);

  // Options fetching queries
  const [currentPage, setCurrentPage] = useState(0)
  const pagination = { offset: currentPage, limit: limit, search: null }

  const [getHostels, { loading: hostels_loading, error: hostels_error, data: hostels_data, refetch: hostels_ref }] = useLazyQuery(GET_HOSTELS, {
    client:accomodationGraphQLClient,
    variables: {
      pagination,
    },
    fetchPolicy: 'cache-first'
  })

  const { loading: loading_blocks, error: error_blocks, data:data_blocks, refetch: refetch_blocks } = useQuery(GET_HOSTEL_BLOCKS, {
    client:accomodationGraphQLClient,
    skip: !(openChangeRoomModal && selectedHostelUid?.value),
    variables: {
      hostelUid: selectedHostelUid?.value,
    },
    fetchPolicy: 'cache-first'
  })

  const { loading: loading_rooms, error: error_rooms, data:data_rooms, refetch: refetch_rooms } = useQuery(GET_VACANT_ROOM_CAPACITIES, {
    client:accomodationGraphQLClient,
    skip: !selectedBlockUid?.value,
    variables: {
      input: {
        blockUid: selectedBlockUid?.value
      }
    },
    fetchPolicy: 'cache-first',
  })
  
  const hostels_options = hostels_data?.getHostels.data?.items

  // Hooks to triger options quering on dependant options change
  useEffect(() => {
    if(selectedHostelUid?.value){
      const fetchData = async () => {
        try {
          await refetch_blocks();
        } catch (error) {
          if(process.env.REACT_APP_DEBUG_MODE){
            console.error('Error fetching blocks:', error);
          }
        }
      };

      fetchData();
    }
  }, [selectedHostelUid])

  // When blocks fetched
  useEffect(() => {
    const block_items = data_blocks?.getBlocksByHostel.data?.items
    if(block_items?.length){
      setBlocksOptions(block_items?.map(block => ({
        value: block.uid,
        label: block.name + ' ['+ block.code +']'
      })))

    }
  }, [data_blocks])

  // When block selected
  useEffect(() => {
    if(selectedBlockUid?.value){
      const fetchData = async () => {
        try {
          await refetch_rooms();
        } catch (error) {
          if(process.env.REACT_APP_DEBUG_MODE){
            console.error('Error fetching rooms:', error);
          }
        }
      };

      fetchData();
    }
  }, [selectedBlockUid])

  // When rooms fecthed
  useEffect(() => {
    if(data_rooms?.getVacantRoomCapacities.data?.items?.length){

      const roomCapacities = data_rooms?.getVacantRoomCapacities.data?.items?.filter((roomCapacity) => (
        roomCapacity.gender === selectedRequest.student.gender &&
        roomCapacity.programCategory === selectedRequest.programCategory
      )).map(roomCapacity => ({
        value: roomCapacity?.uid,
        label: `${roomCapacity?.room?.code} (${roomCapacity.programCategory} | ${roomCapacity.gender} | Vacants: ${roomCapacity?.vacant})`
      }))
      
      if(process.env.REACT_APP_DEBUG_MODE){
        console.log("Room Capacities", roomCapacities);
      }

      setRoomsOptions(roomCapacities)

    }
  }, [data_rooms])
  
  // A callback when modal opened to initialize values of selected RoomCapacity
  const handleModalOpened = () => {
    // Reset previous options
    setRoomsOptions([])
    setBlocksOptions([])
    getHostels();

    // For hostel
    if(selectedRequest?.room?.block?.hostel && hostels_options?.length){
      const hostel = hostels_options.filter((hostel) => hostel.uid == selectedRequest.room.block.hostel?.uid)
      
      if(hostel?.length){
        setSelectedHostelUid({
          value: hostel[0].uid,
          label: hostel[0].name + ' ['+ hostel[0].code +']'
        })
      }
    } else {
      setSelectedHostelUid(null)
    }
  }

  // A callback to reset form
  const resetForm = () => {
    if (formRef.current) {
      formRef.current.resetForm()
    }
  }

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      roomAllocationUid: selectedRequest?.roomAllocation?.room ? selectedRequest?.roomAllocation?.rooms?.uid : null
    },

    validationSchema: Yup.object().shape({
      roomCapacityUid: Yup.string().required("You must select a room to re-allocate"),
    }),

    onSubmit: (values, {setSubmitting}) => {
      change_room_allocation({
        variables: {
          input: { 
            ...values, 
            roomAllocationUid: selectedRequest?.roomAllocation?.uid,
            studentUid: selectedRequest?.studentUid
          },
        },
        client:accomodationGraphQLClient,
        onCompleted: ({ changeRoomAllocation }) => {
          if (changeRoomAllocation.code === 8000) {
            validation.resetForm()
            setOpenChangeRoomModal(false)
            setSelectedRequest(false)
            callFetchStudentRequests()
          } else {
            setOpenChangeRoomModal(true)
            setSubmitting(false)
          }
          showToast(
            changeRoomAllocation.message,
            changeRoomAllocation.code === 8000 ? "success" : "error"
          )
        },
        onError: error => {
          setSubmitting(false)
          if(process.env.REACT_APP_DEBUG_MODE){
            console.error("Mutation error:", error)
          }
        },
      })
    },
  })
  return (
    <div className="text-sm-end mt-3">
      <Modal
        isOpen={openChangeRoomModal}
        onOpened={handleModalOpened}
        backdrop={"static"}
        id="staticBackdrop"
        size="lg"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            Student Room Re-allocation
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setOpenChangeRoomModal(false)
            }}
            aria-label="Close"
          ></button>
        </div>

        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return null
          }}
        >
          <div className="modal-body">
            
            <div className="row">
              <div className="col-12">
                
                  <div className="mb-1">
                    <label htmlFor="remarks" className="col-form-label w-100">
                      Student Info <span className="text-danger">*</span>:
                    </label>
                    <Input
                      type="text"
                      value={`${selectedRequest?.student?.fullName} | ${selectedRequest?.student?.gender} | ${selectedRequest?.student?.registrationNumber}`}
                      placeholder="Student info"
                      disabled
                    />
                  </div>

                <div className="mb-1">
                  <label htmlFor="hostel" className="col-form-label">
                    Hostel <span className="text-danger">*</span>:
                  </label>
                  <Select
                    id="hostel"
                    placeholder="Select Hostel"
                    value={selectedHostelUid}
                    onChange={selectedOption => {
                      setBlocksOptions(null)
                      setRoomsOptions(null)
                      setSelectedBlockUid(null)
                      setSelectedRooms(null)
                      setSelectedHostelUid(selectedOption)
                    }}
                    options={hostels_options?.map(hostel => ({
                      value: hostel.uid,
                      label: hostel.name + ' ['+ hostel.code +']',
                    }))}
                    className="select2-selection"
                    styles={{
                      menu: base => ({
                        ...base,
                        zIndex: 9999,
                      }),
                    }}
                  />
                  {validation.touched.hostel && validation.errors.hostel ? (
                    <FormFeedback type="invalid">
                      {validation.errors.hostel}
                    </FormFeedback>
                  ) : null}
                </div>
                
                <div className="mb-1">
                  <label htmlFor="block" className="col-form-label w-100">
                    Block <span className="text-danger">*</span>:
                    {loading_blocks? <i className="fas fa-spinner fa-spin" style={{float: 'right'}}></i> : ''}
                  </label>
                  
                  <Select
                    id="block"
                    placeholder="Select Block"
                    isDisabled={!blocks_options?.length}// || selectedRequest}
                    value={selectedBlockUid}
                    onChange={selectedOption => {
                      setRoomsOptions([])
                      setSelectedRooms([])
                      setSelectedBlockUid(selectedOption)
                    }}
                    options={blocks_options}
                    className="select2-selection"
                    styles={{
                      menu: base => ({
                        ...base,
                        zIndex: 9999,
                      }),
                    }}
                  />
                  {validation.touched.block && validation.errors.block ? (
                    <FormFeedback type="invalid">
                      {validation.errors.block}
                    </FormFeedback>
                  ) : null}

                </div>

                <div className="mb-1">
                  <label htmlFor="roomCapacityUid" className="col-form-label w-100">
                    Room to Re-allocate <span className="text-danger">*</span>:
                    {loading_rooms? <i className="fas fa-spinner fa-spin" style={{float: 'right'}}></i> : ''}
                  </label>
                  <Select
                    id="roomCapacityUid"
                    name="roomCapacityUid"
                    placeholder="Select Room"
                    required
                    isDisabled={!rooms_options?.length}
                    // value={
                    //   selectedRequest?.roomAllocation?.roomCapacity?.room? 
                    //   [{
                    //     value: selectedRequest?.roomAllocation?.roomCapacity?.room?.uid,
                    //     label: selectedRequest?.roomAllocation?.roomCapacity?.room?.code
                    //   }] : selectedRooms
                    // }
                    onChange={option => {
                      validation.setFieldValue("roomCapacityUid", option.value || null)
                    }}
                    options={rooms_options}
                    className="select2-selection"
                    styles={{
                      menu: base => ({
                        ...base,
                        zIndex: 9999,
                      }),
                    }}
                  />
                  {validation.touched.roomCapacityUid && validation.errors.roomCapacityUid ? (
                    <FormFeedback type="invalid">
                      {validation.errors.roomCapacityUid}
                    </FormFeedback>
                  ) : null}
                </div>

              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                setOpenChangeRoomModal(false)
                setSelectedRequest(false)
                resetForm() // Reset the form
              }}
            >
              Close
            </button>
            <SubmitButtonHelper
              name="Submit"
              type="primary"
              formik={validation}
            />
          </div>
        </Form>
      </Modal>
    </div>
  )
}

export default AccChangeRoomModal
