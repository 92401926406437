import React, { createContext, useContext, useEffect, useState } from "react"
import { Button, Card, CardBody, CardHeader, CardText, CardTitle, Col, Modal, Row } from "reactstrap"
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table"
import { EntryQualificationConext } from "./EntryQualification"
import ResultTypeModel from "./Foreigners/ResultTypeModel"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { useDispatch } from "react-redux"
import { updateFormFourStatus } from "../../../Redux/FormResultsCheck/Actions/FormFourActions"

export const ApplicantCertificateResultsContext = createContext() // Create data to transfer to child modal

const ApplicantCertificateResults = (props) => {
  const dispatch = useDispatch()
  const { user } = props
  const [open, setOpen] = useState(false)
  const [results, setResults] = useState(null)
  const { add, setAdd, applicant, certificates } = useContext(EntryQualificationConext)

  const requiredQualificationMethod = applicant?.qualificationMethodCode
  const certificateExists = certificates?.some(data => data?.certificateType?.code === requiredQualificationMethod)

  const formFourResult = certificates?.find(item => item.certificateType.code === "CSEE")?.results
  const ACSEERESULTS = certificates?.find(item => item.certificateType.code === "ACSEE")?.results
  // Dispatch the action
  useEffect(() => {
    if (formFourResult !== undefined) {
      dispatch(updateFormFourStatus(formFourResult))
    }
  }, [dispatch, formFourResult])
  console.log("count",formFourResult?.length)

  return (
    <Row>
      <Col lg={12}>
        <Card outline color="success" className="border">
          <CardHeader className="bg-transparent">
            <h5 className="my-0 text-success">
              <i className="mdi mdi-library-shelves me-3" />
              QUALIFICATIONS
              {!applicant?.submissionStatus &&
                <>
                  {applicant.tanzanian ?
                    <button onClick={() => {
                      setAdd(!add)
                    }} className="btn btn-primary float-end">Add Entry New Qualification</button> :
                    <ResultTypeModel user={user} />}
                </>
              }
            </h5>
          </CardHeader>
          <CardBody>
            <CardTitle className="mt-0">MY QUALIFICATIONS</CardTitle>

            <CardText>
              {certificates.length > 0 && <div className="table-rep-plugin">
                <div
                  className="table-responsive mb-0"
                  data-pattern="priority-columns"
                >
                  <Table
                    id="tech-companies-1"
                    className="table table-striped table-bordered"
                  >
                    <Thead>
                      <Tr>
                        <Th>S/No</Th>
                        <Th>INDEX NUMBER</Th>
                        <Th>INSTITUTION NAME</Th>
                        <Th>COMPLETION YEAR</Th>
                        <Th>RESULTS</Th>
                        <Th>ACTION</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {certificates?.map((cert, index) => (
                        <Tr key={cert?.uid}>
                          <Td>{index + 1}</Td>
                          <Td>{cert?.indexNumber}</Td>
                          <Td>
                            {
                              cert.avn ?
                                <>
                                  <strong>{cert?.institutionName}</strong> <br />
                                  <strong>Programme: </strong> {cert?.institutionProgram}
                                </> :
                                <>
                                  {cert?.institutionName}
                                </>
                            }
                          </Td>
                          <Td>{cert?.completionYear}</Td>
                          <Td>
                            {
                              cert.results.length === 0 ? <>
                                  <strong>GPA: </strong> {cert.gpa}, <strong>Point: </strong> {cert.point}</> :
                                <>
                                  <strong>Division: </strong> {cert.division}, <strong>Point: </strong> {cert.point}
                                </>

                            }
                          </Td>
                          <Th>
                            {cert.results.length > 0 &&
                              <Button className={"btn btn-sm btn-success"} title={"View Results"} onClick={() => {
                                setResults(cert)
                                // console.log(ce)
                                setOpen(true)
                              }}>
                                <i className={"fa fa-eye"} />
                              </Button>
                            }
                          </Th>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                  {certificateExists ? (
                      <>
                        {
                          requiredQualificationMethod === "ACSEE" && ACSEERESULTS?.length < 1 ? (
                            <div className="alert alert-danger ">
                              <strong>NOTE! </strong> You can not proceed before
                              adding <strong>{requiredQualificationMethod}</strong> certificate result
                            </div>
                          ) : (
                            <div className="text-center mt-4">
                              <Link
                                to="/applicant/payment"
                                className="btn btn-primary btn-sm mx-2"
                              >
                                <i className="mdi mdi-arrow-left ms-1"></i> Previous
                              </Link>
                              {
                                formFourResult?.length > 0 ? (
                                  <Link
                                    to="/applicant/program-selection"
                                    className="btn btn-primary  btn-sm"
                                  >
                                    Proceed <i className="mdi mdi-arrow-right ms-1"></i>
                                  </Link>
                                ): (
                                  <div className="alert alert-danger mt-2">
                                    <strong>NOTE! </strong> You can not proceed before
                                    adding Form four certificate result
                                  </div>
                                )
                              }
                            </div>
                          )
                        }
                      </>
                    )
                    :
                    <div className="alert alert-danger ">
                      <strong>NOTE! </strong> You can not proceed before
                      adding <strong>{requiredQualificationMethod}</strong> certificate result
                    </div>}
                </div>
              </div>}
            </CardText>
          </CardBody>
        </Card>
      </Col>


      {results &&
        <Modal
          isOpen={open}
          backdrop={"static"}
          id="staticBackdrop"
        >
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">
              RESULTS
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                setOpen(false)
                setResults(null)
              }}
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">

            <Card outline color="success" className="border">
              <CardHeader className="bg-transparent">
                <h5 className="my-0 text-success">
                  <i className="mdi mdi-account-check me-3" />
                  {results?.indexNumber} {" "} RESULTS
                </h5>
              </CardHeader>
              <CardBody>
                <CardTitle className="mt-0">Results</CardTitle>
                <CardText>
                  <strong>Institution: </strong> {results?.institutionName} <br />
                  <strong>Completion Year: </strong> {results?.completionYear}
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table table-striped table-bordered"
                      >
                        <Thead>
                          <Tr>
                            <Th>S/No</Th>
                            <Th>SUBJECT</Th>
                            <Th>GRADE</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {results?.results.map((sub, index) => (
                            <Tr key={`id----${index + 1}`}>
                              <Td>{index + 1}</Td>
                              <Td>{sub?.subjectName}</Td>
                              <Td>{sub?.grade}</Td>
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                    </div>
                  </div>
                  <strong>Division: </strong> {results?.division} - <strong>Point: </strong> {results?.point}
                  <div className="text-center">
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={() => {
                        setOpen(false)
                        setResults(null)
                      }}
                    >
                      Close
                    </button>
                  </div>
                </CardText>
              </CardBody>
            </Card>
          </div>


        </Modal>}


    </Row>
  )
}
ApplicantCertificateResults.propTypes = {
  user: PropTypes.any.isRequired
  // certificates: PropTypes.any.isRequired
}
export default ApplicantCertificateResults