import { useLazyQuery, useQuery } from "@apollo/client"
import { GENERATE_ACADEMIC_UNIT_RESULTS_XLS } from "../Queries/ResultReportQueries"
import { registrationGraphQLClient } from "../../../Authentication/ApolloClient"
import { useFormik } from "formik"
import * as Yup from "yup"
import { DownloadExcelFile } from "../../../../helpers/DownloadExcelFile"
import showToast from "../../../../helpers/ToastHelper"
import React, { useState, useEffect } from "react"
import { GET_ALL_PROGRAMS } from "../../../Programs/Queries/ProgramQuaries"
import { GET_ACADEMICYEARS } from "../../../AcademicYears/Queries/AcademicYearsQueries"
import { paginationHelper } from "../../../../helpers/Functions/GraphqlUpdateFunction"
import LayoutHelper from "../../../../helpers/LayoutHelper"
import {
  Card,
  CardBody,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
  Progress,
} from "reactstrap"
import Select from "react-select"
import SubmitButtonHelper from "../../../../helpers/SubmitButtonHelper"

const breadcrumbItem = [
  {
    title: "Academic Unit Result",
    path: "",
    isActive: true,
  },
]

const AcademicUnitResult = () => {
  const [generateReport] = useLazyQuery(GENERATE_ACADEMIC_UNIT_RESULTS_XLS, {
    client: registrationGraphQLClient,
    onError: error => {
      // Handle network errors here
      if (error.networkError) {
        showToast("Network error, trying to reconnect...", "warning")
        // Keep the progress bar active during retries
        setIsLoading(true)
      } else {
        showToast("An error occurred. Please try again.", "error")
        setIsLoading(false) // Stop loading on other errors
      }
    },
    onCompleted: ({ generateAcademicUnitResults }) => {
      // Handle success here
      setIsLoading(false)
      clearInterval(progressIntervalId) // Stop progress tracking
      clearTimeout(timeoutId) // Stop timeout tracking
      // Your completion logic (e.g., downloading the file)
      if (generateAcademicUnitResults?.code === 8000) {
        DownloadExcelFile(
          generateAcademicUnitResults?.data?.base64Data,
          generateAcademicUnitResults?.data?.fileName
        )
      }
      showToast(
        generateAcademicUnitResults.message,
        generateAcademicUnitResults.code === 8000 ? "success" : "error"
      )
    },
  })

  const [timeoutWarning, setTimeoutWarning] = useState(false)
  const [timeoutProgress, setTimeoutProgress] = useState(0) // State to track the percentage of time elapsed
  const [isLoading, setIsLoading] = useState(false) // Loading state to show the progress bar
  const TIMEOUT_LIMIT = 2400000 // 2400 seconds timeout
  const PROGRESS_INTERVAL = 100 // Update every 100ms (0.1s)

  const examTypes = [
    {
      value: 1,
      label: "First Sitting",
    },
    {
      value: 2,
      label: "Probation",
    },
    {
      value: 3,
      label: "Third Attempt",
    },
  ]
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      programUid: "",
      academicYearUid: "",
      yearOfStudy: "",
      numberOfSitting: "",
    },
    validationSchema: Yup.object().shape({
      programUid: Yup.string().required("Program is required"),
      numberOfSitting: Yup.string().required("This required"),
      academicYearUid: Yup.string().required("Academic year is required"),
      yearOfStudy: Yup.number().required("Study Year is required"),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setTimeoutWarning(false)
      setTimeoutProgress(0) // Reset progress
      setIsLoading(true) // Show progress bar when submitting

      // Set timeout and progress interval to handle long processing
      const timeoutId = setTimeout(() => {
        setTimeoutWarning(true)
        setSubmitting(false)
        setIsLoading(false) // Hide progress bar on timeout
        showToast("Request is taking too long. Please try again.", "error")
      }, TIMEOUT_LIMIT)

      const progressIntervalId = setInterval(() => {
        setTimeoutProgress(prev => {
          const newProgress = prev + (PROGRESS_INTERVAL / TIMEOUT_LIMIT) * 100
          return newProgress >= 100 ? 100 : newProgress
        })
      }, PROGRESS_INTERVAL)

      generateReport({
        variables: {
          programUid: values.programUid,
          academicYearUid: values.academicYearUid,
          semester: values.semester,
          yearOfStudy: values.yearOfStudy,
          numberOfSitting: values.numberOfSitting,
        },
        fetchPolicy: "no-cache",
        client: registrationGraphQLClient,
        onCompleted: ({ generateAcademicUnitResults }) => {
          clearTimeout(timeoutId) // Clear timeout if request completes
          clearInterval(progressIntervalId) // Clear the progress interval
          setIsLoading(false) // Hide progress bar on success or error

          if (generateAcademicUnitResults?.code === 8000) {
            DownloadExcelFile(
              generateAcademicUnitResults?.data?.base64Data,
              generateAcademicUnitResults?.data?.fileName
            )
          }
          showToast(
            generateAcademicUnitResults.message,
            generateAcademicUnitResults.code === 8000 ? "success" : "error"
          )
          setSubmitting(false)
        },
        onError: error => {
          // clearTimeout(timeoutId);  // Clear timeout on error
          // clearInterval(progressIntervalId);  // Clear progress interval on error
          setIsLoading(true) // Hide progress bar on error
          console.error("Mutation error:", error)
          showToast(
            "An error occurred. Please wait before retrying again.",
            "error"
          )
          setSubmitting(true)
        },
      })
    },
  })

  // Display timeout warning if true
  useEffect(() => {
    if (timeoutWarning) {
      showToast(
        "The request is taking too long. Please try again later.",
        "warning"
      )
    }
  }, [timeoutWarning])

  useEffect(() => {
    if (isLoading) {
      // Increment progress percentage during loading
      const progressIntervalId = setInterval(() => {
        setTimeoutProgress(prev => {
          const newProgress = prev + (PROGRESS_INTERVAL / TIMEOUT_LIMIT) * 100
          return newProgress >= 100 ? 100 : newProgress
        })
      }, PROGRESS_INTERVAL)

      // Clear intervals on unmount
      return () => {
        clearInterval(progressIntervalId)
      }
    }
  }, [isLoading])

  const [yearOfStudy, setYearOfStudy] = useState([])

  const pagination = { offset: 0, limit: 5000, search: null }

  const { loading: programLoading, data: programData } = useQuery(
    GET_ALL_PROGRAMS,
    {
      client: registrationGraphQLClient,
      variables: { pagination },
    }
  )

  let programs = programData?.getAllPrograms?.data?.items || []

  const { loading: academicYearLoading, data: academicYear } = useQuery(
    GET_ACADEMICYEARS,
    {
      client: registrationGraphQLClient,
      variables: { pagination: paginationHelper },
    }
  )

  let academicYears = academicYear?.getAcademicYears?.data?.items || []

  const search = (value, type) => {
    if (type === "program") {
      if (value !== undefined && value.length >= 4) {
        searchPrograms({
          variables: { pagination: { ...pagination, search: value } },
        })
      }
    }
  }

  return (
    <LayoutHelper
      breadcrumbItem={breadcrumbItem}
      pageTitle="Academic Unit Result"
    >
      <Row>
        <Col lg={6}>
          <Card>
            <CardBody>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Row>
                  <Col lg={12}>
                    <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                      <Label>Program</Label>
                      <Select
                        styles={{
                          menu: base => ({
                            ...base,
                            position: "absolute",
                            zIndex: 9999,
                          }),
                        }}
                        onChange={e => {
                          if (e) {
                            validation.setFieldValue("programUid", e.value)
                            let study_duration = []
                            setYearOfStudy([])
                            for (let i = 1; i <= e.duration; i++) {
                              study_duration.push({
                                label: "Year " + i,
                                value: i,
                              })
                            }
                            setYearOfStudy(study_duration)
                          } else {
                            validation.setFieldValue("programUid", "")
                          }
                        }}
                        onInputChange={e => search(e, "program")}
                        options={programs.map(pro => ({
                          value: pro.uid,
                          duration: pro.duration,
                          label: pro.name + " (" + pro.registrationCode + ")",
                        }))}
                        className="select2-selection"
                        isClearable={true}
                        isLoading={programLoading}
                      />
                      <Input
                        name="programUid"
                        type="hidden"
                        onChange={validation.handleChange}
                        value={validation.values.programUid}
                        invalid={
                          !!(
                            validation.touched.programUid &&
                            validation.errors.programUid
                          )
                        }
                      />
                      {validation.touched.programUid &&
                      validation.errors.programUid ? (
                        <FormFeedback type="invalid">
                          {validation.errors.programUid}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Academic Year</Label>
                      <Select
                        onChange={e => {
                          if (e) {
                            validation.setFieldValue("academicYearUid", e.value)
                          } else {
                            validation.setFieldValue("academicYearUid", "")
                          }
                        }}
                        onInputChange={e => search(e, "academicYear")}
                        options={academicYears.map(year => ({
                          value: year.uid,
                          label: year.name,
                        }))}
                        className="select2-selection"
                        isLoading={academicYearLoading}
                        isClearable={true}
                      />
                      <Input
                        name="academicYearUid"
                        type="hidden"
                        onChange={validation.handleChange}
                        value={validation.values.academicYearUid}
                        invalid={
                          !!(
                            validation.touched.academicYearUid &&
                            validation.errors.academicYearUid
                          )
                        }
                      />
                      {validation.touched.academicYearUid &&
                      validation.errors.academicYearUid ? (
                        <FormFeedback type="invalid">
                          {validation.errors.academicYearUid}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Study Year</Label>
                      <Select
                        onChange={e => {
                          validation.setFieldValue("yearOfStudy", e.value)
                        }}
                        options={yearOfStudy}
                        className="select2-selection"
                      />
                      <Input
                        name="yearOfStudy"
                        type="hidden"
                        onChange={validation.handleChange}
                        value={validation.values.yearOfStudy || ""}
                        invalid={
                          !!(
                            validation.touched.yearOfStudy &&
                            validation.errors.yearOfStudy
                          )
                        }
                      />
                      {validation.touched.yearOfStudy &&
                      validation.errors.yearOfStudy ? (
                        <FormFeedback type="invalid">
                          {validation.errors.yearOfStudy}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="mb-3">
                      <Label>Examination Type</Label>
                      <Select
                        onChange={e => {
                          validation.setFieldValue("numberOfSitting", e.value)
                        }}
                        options={examTypes}
                        className="select2-selection"
                      />
                      <Input
                        name="yearOfStudy"
                        type="hidden"
                        onChange={validation.handleChange}
                        value={validation.values.numberOfSitting || ""}
                        invalid={
                          !!(
                            validation.touched.numberOfSitting &&
                            validation.errors.numberOfSitting
                          )
                        }
                      />
                      {validation.touched.numberOfSitting &&
                      validation.errors.numberOfSitting ? (
                        <FormFeedback type="invalid">
                          {validation.errors.numberOfSitting}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="float-end">
                      <SubmitButtonHelper
                        type={"primary"}
                        name={"Submit"}
                        formik={validation}
                      />
                    </div>
                  </Col>
                </Row>

                {/* Progress Bar */}
                {isLoading && (
                  <div className="my-3">
                    <Progress value={timeoutProgress.toFixed(2)} />
                    <p className="text-center mt-2">
                      {timeoutProgress.toFixed(2)}% complete
                    </p>
                  </div>
                )}
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </LayoutHelper>
  )
}

export default AcademicUnitResult
