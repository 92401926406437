import React from "react"
import jsPDF from "jspdf"
import "jspdf-autotable"
import { useQuery } from "@apollo/client"
import { uaaGraphQLClient } from "Modules/Authentication/ApolloClient"
import showToast from "helpers/ToastHelper"

const sua_logo = process.env.PUBLIC_URL + "/logo.jpeg"

const nembo = process.env.PUBLIC_URL + "/nembo.png"

export const GenerateApplicantAdmissionLetter = (
  applicant,
  signaturePad,
  campusCode
) => {

  const currentDate = new Date()
  const formattedDate = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric"
  }).format(currentDate)

  try {

    const currentDate = new Date()
    const options = { year: "numeric", month: "long", day: "numeric" }
    const formattedDate = currentDate.toLocaleDateString(undefined, options)

    const doc = new jsPDF()

    const pageWidth = doc.internal.pageSize.getWidth()

    // Load both images before creating the PDF
    doc.addImage(nembo, "PNG", 10, 10, 20, 20) // Adjust position and size as needed

    doc.setFontSize(11)
    doc.setFont("Roboto", "bold")
    doc.text("UNITED REPUBLIC OF TANZANIA", 100, 10, { align: "center" }) // Adjust position as needed

    doc.setFontSize(9)
    doc.setFont("Roboto", "bold")
    doc.text("MINISTRY OF EDUCATION, SCIENCE AND TECHNOLOGY", 100, 15, {
      align: "center"
    })

    doc.setFontSize(9)
    doc.setFont("Roboto", "bold")
    doc.text("SOKOINE UNIVERSITY OF AGRICULTURE", 100, 20, {
      align: "center"
    })

    doc.setFontSize(9)
    doc.setFont("Roboto", "bold")
    doc.text("DIRECTORATE OF UNDERGRADUATE STUDIES", 100, 25, {
      align: "center"
    })

    doc.setFontSize(10)
    doc.setFont("Roboto")
    doc.text(
      "P.O. Box 3000, Chuo Kikuu, Morogoro, Tanzania",
      100,
      30,
      { align: "center" }
    )

    doc.setFontSize(10)
    doc.setFont("Roboto")
    doc.text(
      "TEL: +255-023-2640006/9 or +255-23-2640010",
      100,
      35,
      { align: "center" }
    )

    doc.setFontSize(10)
    doc.setFont("Roboto")
    doc.text(
      "Email: dus@sua.ac.tz; admission@sua.ac.tz",
      100,
      40,
      { align: "center" }
    )

    // Add the right image
    doc.addImage(sua_logo, "PNG", 170, 10, 20, 20) // Adjust position and size as needed

    doc.line(10, 42, pageWidth - 10, 42)

    doc.setFontSize(10)
    doc.setFont("Roboto", "normal")
    doc.text(
      "Please refer to:",
      10,
      45,
      { align: "left" }
    )


    doc.setFontSize(10)
    doc.setFont("Roboto", "bold")
    doc.text(
      "Our Ref:",
      10,
      50,
      { align: "left" }
    )
    doc.setFontSize(10)
    doc.setFont("Roboto", "normal")
    doc.text(
      "SUA/ADM/A.1/8A VOL.II",
      45,
      50,
      { align: "center" }
    )
    doc.setFontSize(10)
    doc.setFont("Roboto", "bold")
    doc.text(
      "Date: ",
      170,
      50,
      { align: "center" }
    )
    doc.setFontSize(10)
    doc.setFont("Roboto", "normal")
    doc.text(
      `${formattedDate}.`,
      190,
      50,
      { align: "center" }
    )

    doc.setFontSize(10)
    doc.setFont("Roboto", "normal")
    doc.text(
      "Mr. /Miss./Ms./Mrs: ",
      10,
      60,
      { align: "left" }
    )

    doc.setFontSize(10)
    doc.setFont("Roboto", "bold")
    doc.text(
      applicant?.firstName + " " + applicant?.middleName + " " + applicant?.lastName,
      70,
      60,
      { align: "center" }
    )

    doc.setFontSize(8)
    doc.setFont("Roboto", "bold")
    doc.text(
      "RE: ",
      30,
      70,
      { align: "left" }
    )
    doc.setFontSize(8)
    doc.setFont("Roboto", "bold")
    doc.text(
      `ADMISSION INTO ${applicant?.applicationLevelCode == "BSC" ? "UNDERGRADUATE DEGREE" : "CERTIFICATE AND DIPLOMA"} PROGRAMMES AT SUA, ${campusCode === "MPC" ? "MIZENGO PINDA CAMPUS, KATAVI" : "MOROGORO CAMPUSES"}`,
      campusCode === "MPC"? 110 : 105,
      70,
      { align: "center" }
    )

    if (campusCode === "MPC"){
      doc.line(25, 72, pageWidth - 25, 72)

    }else{
      doc.line(40, 72, pageWidth - 40, 72)

    }

    doc.setFontSize(10)  // Set font size for the paragraph text
    doc.setFont("Roboto", "normal")  // Set the font style to normal

    const para1 = `I am pleased to inform you that you have been selected to join ${applicant?.tcuSelectedProgramName.toUpperCase()} at Sokoine University of Agriculture for the 2024/2025 academic year. Your studies will begin on October 28, 2024, following successful registration and orientation. It is crucial for all first-year students to attend the orientation, which will be held from October 21 to October 27, 2024.`
    doc.text(para1, 10, 85, { align: "justify", maxWidth: 190 }) // First paragraph position

    doc.setFontSize(10)
    doc.setFont("Roboto", "normal")
    const para2 = campusCode === "MPC" ? applicant?.applicationLevelCode == "BSC" ? "Please be informed that all students selected to join BSc. Bee Resources Management are required to report to Mizengo Pinda Campus, in Katavi Region." : "Please be informed that all students selected to join Certificate in Tour guiding and Hunting operations and Diploma in Crop Production are supposed to report at Mizengo Pinda Campus (MPC) – Katavi Region." : applicant?.applicationLevelCode == "BSC" ? `Please note that students enrolled in the following programs: BSc. Environmental Science and Management, Bachelor of Tourism Management, Bachelor of Rural Development, BSc. Agricultural Economics and Agribusiness, BSc. Information Technology, and all BSc. with Education programs are required to report to Solomon Mahlangu Campus (SMC) in Mazimbu. Students in other programs should report to Edward Moringe Campus (EMC), formerly known as Main Campus (MC).` : "Please be informed that all students selected to join Certificate in Information Technology and Diploma in Information Technology are supposed to report at Solomon Mahlangu Campus (SMC) – Mazimbu, whereas the rest will be reporting at the Edward Moringe (EMC) formerly known as Main Campus (MC). "
    doc.text(para2, 10, 105, { align: "justify", maxWidth: 190 }) // Second paragraph position, adjust Y for spacing

    doc.setFontSize(10)
    doc.setFont("Roboto", "normal")
    const para3 = campusCode === "MPC" ? "SUA-Mizengo Pinda Campus is located at Kibaoni, about 100 km South of Mpanda town if you approach from the North and 150 km North of Sumbawanga town if you approach from Tunduma. The Campus is within a walking distance from Kibaoni bus stop." : `Upon arrival in Morogoro (Msamvu bus terminal), you are advised to take a taxi to SMC/SUA Main Campus, which costs approximately Tshs 10,000. Alternatively, you can use commuter buses or "Daladala" buses from Msamvu bus terminal to either campus, costing around Tshs 1,000.`
    doc.text(para3, 10, 125, { align: "justify", maxWidth: 190 }) // Third paragraph position, adjust Y for spacing

    doc.setFontSize(10)
    doc.setFont("Roboto", "normal")
    const para4 = campusCode === "MPC" ? `You are advised to pay fees through Banks or Mobile money services after downloading an invoice from SUA Student Information System (ESB) at http://esb.sua.ac.tz/login  using login information issued at the time of application.` : `Please ensure that you pay your fees through banks or mobile money services after downloading an invoice from the SUA Student Information System (ESB) at http://esb.sua.ac.tz/login  using the login information provided during your application. The fees that must be paid before registration are:`
    doc.textWithLink(para4, 10, 140, { align: "justify", maxWidth: 190 }) // Third paragraph position, adjust Y for spacing

    doc.setFontSize(10)
    doc.setFont("Roboto", "bold")
    doc.text(
      "TUITION FEES",
      10,
      155,
      { align: "left" }
    )

    doc.setFontSize(10)
    doc.setFont("Roboto", "normal")
    doc.text(
      applicant?.applicationLevelCode == "BSC" ? "1. Science-based degree programs: " : "Diploma programs",
      20,
      160,
      { align: "left" }
    )
    doc.setFontSize(10)
    doc.setFont("Roboto", "normal")
    doc.text(
      applicant?.applicationLevelCode == "BSC" ? "Tshs 1,263,000 per annum" : " Tshs. 900,000/=  per annum",
      80,
      160,
      { align: "left" }
    )

    doc.setFontSize(10)
    doc.setFont("Roboto", "normal")
    doc.text(
      applicant?.applicationLevelCode == "BSC" ? "2. Non-science-based degree programs: " : "Certificate programs",
      20,
      165,
      { align: "left" }
    )
    doc.setFontSize(10)
    doc.setFont("Roboto", "normal")
    doc.text(
      applicant?.applicationLevelCode == "BSC" ? "Tshs 1,000,000 per annum" : "Tshs.  800,000/=  per annum",
      80,
      165,
      { align: "left" }
    )
    doc.setFontSize(10)
    doc.setFont("Roboto", "bold")
    doc.text(
      "OTHER FEES",
      10,
      170,
      { align: "left" }
    )

    if (applicant?.applicationLevelCode == "BSC" && campusCode === "MPC") {
      doc.setFontSize(10)
      doc.setFont("Roboto", "normal")
      doc.text(
        "1. Students Union Fee: ",
        20,
        175,
        { align: "left" }
      )
      doc.setFontSize(10)
      doc.setFont("Roboto", "normal")
      doc.text(
        "Tshs 10,000 per annum",
        120,
        175,
        { align: "right" }
      )

      doc.setFontSize(10)
      doc.setFont("Roboto", "normal")
      doc.text(
        "2. Library Cost: ",
        20,
        180,
        { align: "left" }
      )
      doc.setFontSize(10)
      doc.setFont("Roboto", "normal")
      doc.text(
        "Tshs 50,000 per annum",
        120,
        180,
        { align: "right" }
      )

      doc.setFontSize(10)
      doc.setFont("Roboto", "normal")
      doc.text(
        "3. Registration Fee: ",
        20,
        185,
        { align: "left" }
      )
      doc.setFontSize(10)
      doc.setFont("Roboto", "normal")
      doc.text(
        "Tshs 10,000 per semester",
        120,
        185,
        { align: "right" }
      )

      doc.setFontSize(10)
      doc.setFont("Roboto", "normal")
      doc.text(
        "4. Examination Fee: ",
        20,
        190,
        { align: "left" }
      )
      doc.setFontSize(10)
      doc.setFont("Roboto", "normal")
      doc.text(
        "Tshs 30,000 per semester",
        120,
        190,
        { align: "right" }
      )

      doc.setFontSize(10)
      doc.setFont("Roboto", "normal")
      doc.text(
        "5. Identity Card: ",
        20,
        195,
        { align: "left" }
      )
      doc.setFontSize(10)
      doc.setFont("Roboto", "normal")
      doc.text(
        "Tshs 10,000 (one-time payment)",
        120,
        195,
        { align: "right" }
      )

      doc.setFontSize(10)
      doc.setFont("Roboto", "normal")
      doc.text(
        "6. Caution Money: ",
        20,
        200,
        { align: "left" }
      )
      doc.setFontSize(10)
      doc.setFont("Roboto", "normal")
      doc.text(
        "Tshs 50,000 (one-time payment)",
        120,
        200,
        { align: "right" }
      )

      doc.setFontSize(10)
      doc.setFont("Roboto", "normal")
      doc.text(
        "7. TCU Quality Assurance Fee: ",
        20,
        205,
        { align: "left" }
      )
      doc.setFontSize(10)
      doc.setFont("Roboto", "normal")
      doc.text(
        "Tshs 20,000 per annum",
        120,
        205,
        { align: "right" }
      )

      doc.setFontSize(10)
      doc.setFont("Roboto", "normal")
      doc.text(
        "8. NHIF Student Health Insurance: ",
        20,
        210,
        { align: "left" }
      )
      doc.setFontSize(10)
      doc.setFont("Roboto", "normal")
      doc.text(
        "Tshs 50,400 per annum",
        120,
        210,
        { align: "right" }
      )
    } else {
      doc.setFontSize(10)
      doc.setFont("Roboto", "normal")
      doc.text(
        "1. Students Union Fee: ",
        20,
        175,
        { align: "left" }
      )
      doc.setFontSize(10)
      doc.setFont("Roboto", "normal")
      doc.text(
        "Tshs 10,000 per annum",
        120,
        175,
        { align: "right" }
      )

      doc.setFontSize(10)
      doc.setFont("Roboto", "normal")
      doc.text(
        "2. Library Cost: ",
        20,
        180,
        { align: "left" }
      )
      doc.setFontSize(10)
      doc.setFont("Roboto", "normal")
      doc.text(
        "Tshs 50,000 per annum",
        120,
        180,
        { align: "right" }
      )

      doc.setFontSize(10)
      doc.setFont("Roboto", "normal")
      doc.text(
        "3. Registration Fee: ",
        20,
        185,
        { align: "left" }
      )
      doc.setFontSize(10)
      doc.setFont("Roboto", "normal")
      doc.text(
        "Tshs 10,000 per semester",
        120,
        185,
        { align: "right" }
      )

      doc.setFontSize(10)
      doc.setFont("Roboto", "normal")
      doc.text(
        "4. Examination Fee: ",
        20,
        190,
        { align: "left" }
      )
      doc.setFontSize(10)
      doc.setFont("Roboto", "normal")
      doc.text(
        "Tshs 30,000 per semester",
        120,
        190,
        { align: "right" }
      )

      doc.setFontSize(10)
      doc.setFont("Roboto", "normal")
      doc.text(
        "5. Identity Card: ",
        20,
        195,
        { align: "left" }
      )
      doc.setFontSize(10)
      doc.setFont("Roboto", "normal")
      doc.text(
        "Tshs 10,000 (one-time payment)",
        120,
        195,
        { align: "right" }
      )

      doc.setFontSize(10)
      doc.setFont("Roboto", "normal")
      doc.text(
        "6. Caution Money: ",
        20,
        200,
        { align: "left" }
      )
      doc.setFontSize(10)
      doc.setFont("Roboto", "normal")
      doc.text(
        "Tshs 50,000 (one-time payment)",
        120,
        200,
        { align: "right" }
      )

      doc.setFontSize(10)
      doc.setFont("Roboto", "normal")
      doc.text(
        "7. TCU Quality Assurance Fee: ",
        20,
        205,
        { align: "left" }
      )
      doc.setFontSize(10)
      doc.setFont("Roboto", "normal")
      doc.text(
        "Tshs 20,000 per annum",
        120,
        205,
        { align: "right" }
      )

      doc.setFontSize(10)
      doc.setFont("Roboto", "normal")
      doc.text(
        "8. NHIF Student Health Insurance: ",
        20,
        210,
        { align: "left" }
      )
      doc.setFontSize(10)
      doc.setFont("Roboto", "normal")
      doc.text(
        "Tshs 50,400 per annum",
        120,
        210,
        { align: "right" }
      )

      doc.setFontSize(10)
      doc.setFont("Roboto", "normal")
      doc.text(
        "9. Medical fee ",
        20,
        215,
        { align: "left" }
      )
      doc.setFontSize(10)
      doc.setFont("Roboto", "normal")
      doc.text(
        " Tshs. 10,000/=  paid once",
        120,
        215,
        { align: "right" }
      )
    }

    doc.setFontSize(10)
    doc.setFont("Roboto", "normal")
    const para5 = `Accommodation is available in university hostels but may be limited. Students allocated university accommodation will need to pay the accommodation fee through banks or mobile money after downloading the accommodation invoice and obtaining a control number from SUA-ESB using the login details provided during your application.`
    doc.text(para5, 10, 220, { align: "justify", maxWidth: 190 }) // Third paragraph position, adjust Y for spacing

    doc.setFontSize(10)
    doc.setFont("Roboto", "normal")
    const para6 = `You will need to present copies of your academic certificates (birth certificate, secondary school, and diploma credentials, if applicable) for registration.`
    doc.text(para6, 10, 235, { align: "justify", maxWidth: 190 }) // Third paragraph position, adjust Y for spacing

    doc.setFontSize(10)
    doc.setFont("Roboto", "normal")
    const para7 = `Students with special needs are encouraged to visit the special needs desk available on all campuses for assistance in adapting to the university environment.`
    doc.text(para7, 10, 247, { align: "justify", maxWidth: 190 }) // Third paragraph position, adjust Y for spacing

    doc.setFontSize(10)
    doc.setFont("Roboto", "normal")
    const para8 = `On behalf of the Management of Sokoine University of Agriculture, congratulations on your selection to join our university community.`
    doc.text(para8, 10, 257, { align: "justify", maxWidth: 190 }) // Third paragraph position, adjust Y for spacing


    doc.setFontSize(10)
    doc.setFont("Roboto", "normal")
    const para9 = `You are warmly welcome,`
    doc.text(para9, 100, 270, { align: "center", maxWidth: 190 }) // Third paragraph position, adjust Y for spacing

    const imageWidth = 10 // Adjust according to your image dimensions
    const imageHeight = 10 // Adjust according to your image dimensions
    const imageX = 100 // Adjust the X position to align the image to the right
    const imageY = 275 // Adjust the Y position below the text

    signaturePad ? doc.addImage(
      signaturePad,
      "PNG", // Image type
      imageX,
      imageY,
      imageWidth,
      imageHeight
    ) : null

    doc.setFontSize(10)
    doc.setFont("Roboto", "normal")
    const para10 = `Dr. Hamis J.  Tindwa.,`
    doc.text(para10, 100, 285, { align: "center", maxWidth: 190 }) // Third paragraph position, adjust Y for spacing

    doc.setFontSize(10)
    doc.setFont("Roboto", "bold")
    const para11 = `DIRECTOR OF UNDERGRADUATE STUDIES`
    doc.text(para11, 100, 290, { align: "center", maxWidth: 190 }) // Third paragraph position, adjust Y for spacing

    doc.line(60, 292, pageWidth - 60, 292)

    // Save the PDF
    doc.save(applicant?.firstName + "_" + applicant?.lastName + ".pdf")
  } catch (error) {
    showToast("Something went wrong try again lator! ", "info")
  }
}
