import {useMutation, useQuery} from "@apollo/client";
import {GET_STUDENT} from "../../Users/Queries/UserQuery";
import {registrationGraphQLClient, uaaGraphQLClient} from "../../Authentication/ApolloClient";
import {GET_CONTROL_NUMBER, GET_FEE_STRUCTURE} from "../../Students/Queries/Payment/StudentPaymentQueries";
import {LoaderPage} from "../../../helpers/LoaderPage";
import LayoutHelper from "../../../helpers/LayoutHelper";
import {Card, CardBody, CardSubtitle, Col, Form, FormFeedback, Label, Row} from "reactstrap";
import React from "react";
import {Link, useNavigate} from "react-router-dom";
import SubmitButtonHelper from "../../../helpers/SubmitButtonHelper";
import {useFormik} from "formik";
import * as Yup from "yup";
import {GET_ACCOMMODATION_STATUS} from "../../Students/Queries/Accommodation/StudentAccommodationQueries";
import showToast from "../../../helpers/ToastHelper";
import {UPDATE_ACCOMMODATION_STATUS} from "../../Students/Mutations/StudentAccommodationStatusInputQueries";

const breadcrumbItem = [
    {
        title: "Self registration",
        path: "",
        isActive: true,
    },
]

const SelfRegistrationHostel = () => {
    const navigate = useNavigate()
    const {
        loading: userLoading,
        error: userError,
        data: userDetails,
        refetch,
    } = useQuery(GET_STUDENT, {
        client: uaaGraphQLClient,
        variables: {
            username: JSON.parse(localStorage.getItem("authUser"))?.data?.username,
        },
        fetchPolicy: "network-only",
    })

    const user = userDetails?.getUserByUsername?.data

    const [update] = useMutation(UPDATE_ACCOMMODATION_STATUS, {
        client: uaaGraphQLClient,
    })

    const {
        loading: loadingFeeStructureControlNumbers,
        error: errorFeeStructure,
        data: feeStructureControlNumber,
        refetch: refetchControlNUmbers,
    } = useQuery(GET_CONTROL_NUMBER, {
        client: registrationGraphQLClient,
        skip:
            !user?.student?.registrationNumber,
        variables: {
            registrationNumber: user?.student?.registrationNumber,
        },
        fetchPolicy: "network-only",
    })

    const feeWithStructureControlNumber =
        feeStructureControlNumber?.getControlNumbers?.data

    const {
        loading: accommodationLoading,
        error: accommodationError,
        data: accommodationData,
        refetch: accommodationRefetch,
    } = useQuery(GET_ACCOMMODATION_STATUS, {
        client: uaaGraphQLClient,
        skip: !user?.student?.studyYear,
        variables: {
            input: {
                userUid: user?.uid,
                studyYear: parseInt(user?.student?.studyYear),
            },
        },
    })

    const accommodationStatus = accommodationData?.getAccommodationStatus?.data

    
    
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            status: accommodationStatus ? accommodationStatus : null,
            studyYear: user
                ? parseInt(user?.student?.studyYear)
                : null,
            userId: user?.uid,
        },

        validationSchema: Yup.object().shape({
            status: Yup.string().nullable().required("Please choose option"),
        }),

        onSubmit: (values, { resetForm }) => {
            const postData = {
                status: values.status,
                studyYear: user
                    ? parseInt(user?.student?.studyYear)
                    : null,
                userUid: user?.uid,
            }
            update({
                client: uaaGraphQLClient,
                variables: {
                    input: { ...postData },
                },
                refetchQueries: [
                    {
                        client: uaaGraphQLClient,
                        query: GET_ACCOMMODATION_STATUS,
                        variables: {
                            input: {
                                userUid: user?.uid,
                                studyYear: parseInt(user?.student?.studyYear),
                            },
                        },
                    },
                ],

                onCompleted: ({ updateAccommodationStatus }) => {
                    showToast(
                        updateAccommodationStatus.message,
                        updateAccommodationStatus.code === 8000 ? "success" : "error"
                    )
                    if (updateAccommodationStatus.code === 8000) {
                        navigate('/self-registration/confirmation')
                    }
                },
                onError: error => {
                    // Handle errors
                    console.error("Mutation error:", error)
                },
            })
        },
    })

    if(userLoading) return <LoaderPage breadcrumbItem={breadcrumbItem} title={"Registration"} description={"Fetching User Details"} />
    if(loadingFeeStructureControlNumbers) return <LoaderPage breadcrumbItem={breadcrumbItem} title={"Registration"} description={"Fetching Control Number Information"} />
    // if(!feeWithStructureControlNumber) navigate("/self-registration/payments")
    if(accommodationLoading) return <LoaderPage breadcrumbItem={breadcrumbItem} title={"Registration"} description={"Fetching Accommodation Information"} />

    // console.log("Fees", fees)
    // console.log("controls", feeWithStructureControlNumber)
    return (
        <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle={"Hostel Information's"}>
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <CardSubtitle>
                                <h4>Hostel Details</h4>
                            </CardSubtitle>
                            <Form
                                onSubmit={e => {
                                    e.preventDefault()
                                    validation.handleSubmit()
                                    return null
                                }}
                            >
                                <Row>
                                    <Col lg="12">
                                        <div className="mb-3">
                                            <Label>Do you want Accomodation ?</Label>
                                            <select
                                                className="form-control"
                                                name="status"
                                                onChange={
                                                    (e) => {
                                                        validation.setFieldValue('status',!!parseInt(e.target.value))
                                                        console.log(validation.values.status)
                                                    }
                                                }
                                                onBlur={validation.handleBlur}
                                            >
                                                <option> --Select--</option>
                                                <option value="1" selected={validation.values.status == true} >Yes</option>
                                                <option value="0"  selected={validation.values.status == false}>No</option>
                                            </select>

                                            {validation.touched.status && validation.errors.status ? (
                                                <FormFeedback type="invalid">
                                                    {validation.errors.status}
                                                </FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                </Row>
                                <div className="wizard">
                                    <div className="actions">
                                        <ul>
                                            <li className="next">
                                                <SubmitButtonHelper
                                                    type="primary"
                                                    name="Next >"
                                                    formik={validation}
                                                />
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </LayoutHelper>
    )
}
export default SelfRegistrationHostel