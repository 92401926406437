import {useMutation, useQuery} from "@apollo/client";
import {GET_STUDENT} from "../../Users/Queries/UserQuery";
import {registrationGraphQLClient, uaaGraphQLClient} from "../../Authentication/ApolloClient";
import {GET_CONTROL_NUMBER, GET_FEE_STRUCTURE} from "../../Students/Queries/Payment/StudentPaymentQueries";
import {LoaderPage} from "../../../helpers/LoaderPage";
import LayoutHelper from "../../../helpers/LayoutHelper";
import {Card, CardBody, CardFooter, CardSubtitle, Col, Row} from "reactstrap";
import React, {createContext, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {DELETE_USER_NEXT_OF_KIN, GET_USER_NEXT_OF_KIN} from "../../Students/Queries/NextOfKin/NextOfkinQueries";
import {showDeleteWarning} from "../../../helpers/DeleteWaringHelper";
import showToast from "../../../helpers/ToastHelper";
import {Table, Tbody, Td, Th, Thead, Tr} from "react-super-responsive-table";
import DropDownActionHelper from "../../../helpers/DropDownActionHelper";
import SelfRegistrationNextOfKinModal from "./SelfRegistrationNextOfKinModel";

const breadcrumbItem = [
    {
        title: "Self registration",
        path: "",
        isActive: true,
    },
]

export const SelfRegistrationNextOfKinContext = createContext() //Create data to transfer to child modal


const SelfRegistrationNextOfKin = () => {
    const [updateNextOfKinData, setUpdateNextOfKinData] = useState(null) //Get data for editing
    const [selectedNextOfKin, setSelectedNextOfKin] = useState(null) //Get data for deleting
    const navigate = useNavigate()
    const {
        loading: userLoading,
        error: userError,
        data: userDetails,
        refetch,
    } = useQuery(GET_STUDENT, {
        client: uaaGraphQLClient,
        variables: {
            username: JSON.parse(localStorage.getItem("authUser"))?.data?.username,
        },
        fetchPolicy: "network-only",
    })

    const user = userDetails?.getUserByUsername?.data

    const { loading, error, data } = useQuery(GET_USER_NEXT_OF_KIN, {
        client: uaaGraphQLClient,
        skip: !user?.uid,
        variables: {
            userUid: user?.uid,
        },
        fetchPolicy: 'network-only',
    })

    const [deleteNextOfKin] = useMutation(DELETE_USER_NEXT_OF_KIN, {

        client: uaaGraphQLClient,

        variables: { uid: selectedNextOfKin?.uid },

        refetchQueries: [
            {
                query: GET_USER_NEXT_OF_KIN,
                variables: {
                    userUid: user?.uid,
                },
            },
        ],
        fetchPolicy: "network-only"
    })
    const handleDeleteNextOfKin = (nextOfKin) => {
        setSelectedNextOfKin(nextOfKin)
        showDeleteWarning(() => {
            performNextOfKinDeletion()
        })
    }

    const performNextOfKinDeletion = async () => {
        try {
            const response = await deleteNextOfKin()
            showToast(response.data.removeChildren.message,
                response.data.removeChildren.code === 8000 ? "success" : "error")
        } catch (error) {
            console.error("Error deleting user:", error)
        }
    }
    
    
    const {
        loading: loadingFeeStructureControlNumbers,
        error: errorFeeStructure,
        data: feeStructureControlNumber,
        refetch: refetchControlNUmbers,
    } = useQuery(GET_CONTROL_NUMBER, {
        client: registrationGraphQLClient,
        skip:
            !user?.student?.registrationNumber,
        variables: {
            registrationNumber: user?.student?.registrationNumber,
        },
        fetchPolicy: "network-only",
    })

    const feeWithStructureControlNumber =
        feeStructureControlNumber?.getControlNumbers?.data


    const result = data?.getUserNextOfKins.data

    if(userLoading) return <LoaderPage breadcrumbItem={breadcrumbItem} title={"Registration"} description={"Fetching User Details"} />
    if(loadingFeeStructureControlNumbers) return <LoaderPage breadcrumbItem={breadcrumbItem} title={"Registration"} description={"Fetching Control Number Information"} />
    if(!feeWithStructureControlNumber) navigate("/self-registration/payments")
    if(loading || !user?.uid) return <LoaderPage breadcrumbItem={breadcrumbItem} title={"Registration"} description={"Fetching Next Of Kin Information"} />

    if (!user?.physicalAddress	|| !user?.postalAddress){
        showToast("Please update contact details", "info")
        navigate("/self-registration/contact-information");
    }
    // console.log("Fees", fees)
    // console.log("controls", feeWithStructureControlNumber)
    return (
        <SelfRegistrationNextOfKinContext.Provider value={{ updateNextOfKinData, user, setUpdateNextOfKinData }}>
            <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle={"Other Information's"}>
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <CardSubtitle>
                                    <h4>Nex Of Kin Details</h4>
                                </CardSubtitle>
                                <Row>
                                    <Col>
                                        <Card>
                                            <CardBody>
                                                <CardSubtitle className="mb-1">
                                                    <Row>
                                                        <Col className="col-lg-12 col-sm-12 col-md-12 pull-right">
                                                            <SelfRegistrationNextOfKinModal />
                                                        </Col>
                                                    </Row>
                                                </CardSubtitle>

                                                {!loading && !error && (
                                                    <div className="table-rep-plugin">
                                                        <div
                                                            className="mb-0"
                                                            data-pattern="priority-columns"
                                                        >
                                                            <Table
                                                                id="tech-companies-1"
                                                                className="table table-striped table-bordered"
                                                            >
                                                                <Thead>
                                                                    <Tr>
                                                                        <Th data-priority="1">S/N</Th>
                                                                        <Th data-priority="1">Full Name</Th>
                                                                        <Th data-priority="1">Kinship</Th>
                                                                        <Th data-priority="1">Place of Domicile</Th>
                                                                        <Th data-priority="1">Phone</Th>
                                                                        <Th data-priority="1">Email</Th>
                                                                        <Th data-priority="3">Action</Th>
                                                                    </Tr>
                                                                </Thead>
                                                                <Tbody>
                                                                    {result?.map((nextOfKin, index) => (
                                                                        <Tr key={nextOfKin?.uid}>
                                                                            <Td>{index + 1}</Td>
                                                                            <Td>{nextOfKin?.fullName}</Td>
                                                                            <Td>{nextOfKin?.kinship?.name}</Td>
                                                                            <Td>{nextOfKin?.placeOfDomicile}</Td>
                                                                            <Td>{nextOfKin?.phoneNumber}</Td>
                                                                            <Td>{nextOfKin?.email}</Td>
                                                                            <Td>
                                                                                <DropDownActionHelper
                                                                                    data={nextOfKin}
                                                                                    onUpdate={setUpdateNextOfKinData}
                                                                                    onDelete={handleDeleteNextOfKin}
                                                                                />
                                                                            </Td>
                                                                        </Tr>
                                                                    ))}
                                                                </Tbody>
                                                            </Table>
                                                        </div>
                                                    </div>
                                                )}
                                            </CardBody>
                                            {result &&
                                                <CardFooter>
                                                    <div className="text-center mt-4">
                                                        <Link
                                                            to="/self-registration/confirmation"
                                                            className="btn btn-primary  btn-sm"
                                                        >
                                                            Proceed <i className="mdi mdi-arrow-right ms-1"></i>
                                                        </Link>
                                                    </div>
                                                </CardFooter>
                                            }
                                        </Card>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </LayoutHelper>
        </SelfRegistrationNextOfKinContext.Provider>
    )
}
export default SelfRegistrationNextOfKin