import React, { useContext, useEffect, useState } from "react"
import {
  Modal,
  Form,
  Input,
  FormFeedback,
  Button,
  Row,
  Col,
  Label,
  CardTitle,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import showToast from "helpers/ToastHelper"
import { useMutation, useQuery } from "@apollo/client"
import { onlineApplicationGraphQLClient } from "Modules/Authentication/ApolloClient"
import Select from "react-select"
import { REGISTER_PROGRAM_SELECTION } from "../Mutations/ProgramSelectionMutations"
import {
  GET_APPLICANT_SELECTED_WINDOW,
  GET_CURRENT_WINDOW_APPLICANT_SELECTIONS,
  GET_PROGRAM_CATEGORY_BY_CATEGORY_CODE,
  VERIFY_APPLICANT_TCU
} from "../Queries/ProgramSelectionQueries"
import { limit } from "../../../../helpers/UrlHelper"
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table"
import { useNavigate } from "react-router-dom"
import { ProgramSelectionContext } from "./ProgramSelection"

const ProgramSelectionModal = () => {
  const { applicant, admissionWindow, refetchSelections, choiceNumbers } = useContext(
    ProgramSelectionContext
  )

  const [programPayload, setProgramPayload] = useState([])
  const [postLoading, setPostLoading] = useState(false)
  const [choiceNumber, setChoiceNumber] = useState("")
  const [availableChoices, setAvailableChoices] = useState([])
  const [currentPage, setCurrentPage] = useState(0)
  const [selectedPrograms, setSelectedPrograms] = useState([])
  const [payLoad, setPayload] = useState({})
  const pagination = { offset: currentPage, limit: limit, search: null }
  const [modalOpen, setModalOpen] = useState(false)
  const navigate = useNavigate()

  const [programSelectionMutation] = useMutation(REGISTER_PROGRAM_SELECTION, {
    client: onlineApplicationGraphQLClient,
  })

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      program: selectedPrograms?.length > 0 ? selectedPrograms : null,
    },
    validationSchema: Yup.object().shape({
      program: Yup.string()
        .required("Program selection is required")
        .min(1, "Program selection is required"),
    }),
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setPayload({
        ...payLoad,
        program: selectedPrograms,
      })
      const postData = {
        program: selectedPrograms,
      }
    },
  })

  const {
    loading,
    error,
    data,
    refetch: refetchProgramCapacity,
  } = useQuery(GET_PROGRAM_CATEGORY_BY_CATEGORY_CODE, {
    client: onlineApplicationGraphQLClient,
    skip: !applicant?.applicationLevelCode,
    variables: {
      programCategoryCode: applicant?.applicationLevelCode,
    },
    fetchPolicy: "network-only",
  })

  const {
    loading: tcuVerifyLoading,
    error: tcuError,
    data: tcuData,
    refetch: refetchTcuVerification,
  } = useQuery(VERIFY_APPLICANT_TCU, {
    client: onlineApplicationGraphQLClient,
    skip: !applicant?.formFourIndexNumber,
    variables: {
      input: {
        formFourIndexNumber: applicant?.formFourIndexNumber,
      },
    },
    fetchPolicy: "network-only",
  })

  const programList = data?.getProgramCapacityByCategoryCode?.data?.items

  const activeAdmissionWindow = admissionWindow

  useEffect(() => {
    const maxChoices = activeAdmissionWindow?.admissionYear?.numberOfChoices

    const choices = Array.from(
      { length: Math.min(maxChoices) },
      (_, i) => i + 1
    )
    setAvailableChoices(choices)
  }, [programList])

  const handleSelectProgram = program => {
    if (!selectedPrograms.some(p => p.programUid === program.programUid)) {
      if (choiceNumber) {
        const newProgram = {
          programUid: program.programUid,
          programName: program.programName,
          choiceNumber: Number(choiceNumber),
          admissionWindowUid: activeAdmissionWindow?.uid,
          applicantUid: applicant?.uid,
          programCapacityUid: program?.uid,
        }

        const updatedPrograms = [...selectedPrograms, newProgram]
        updatedPrograms.sort((a, b) => a.choiceNumber - b.choiceNumber)

        const updatedChoices = availableChoices.filter(
          choice => choice !== Number(choiceNumber)
        )

        setSelectedPrograms(updatedPrograms)
        setAvailableChoices(updatedChoices)
        setChoiceNumber("") // Reset choice number selection

        const newProgramPayload = updatedPrograms.map(p => ({
          choice: p.choiceNumber,
          admissionWindowUid: p.admissionWindowUid,
          applicantUid: p.applicantUid,
          programCapacityUid: p.programCapacityUid,
        })) // extract payload for POST
        setProgramPayload(newProgramPayload)
      } else {
        showToast("Please select a choice number!", "warning")
      }
    } else {
      showToast("Program already selected, try another program!", "warning")
    }
  }
  const handleRemoveProgram = programUid => {
    const removedProgram = selectedPrograms.find(
      p => p.programUid === programUid
    )
    const updatedPrograms = selectedPrograms.filter(
      p => p.programUid !== programUid
    )
    const updatedChoices = [
      ...availableChoices,
      removedProgram.choiceNumber,
    ].sort((a, b) => a - b)

    setSelectedPrograms(updatedPrograms)
    setAvailableChoices(updatedChoices)

    const newProgramPayload = updatedPrograms.map(p => ({
      choice: p.choiceNumber,
      admissionWindowUid: p.admissionWindowUid,
      applicantUid: p.applicantUid,
      programCapacityUid: p.programCapacityUid,
    }))

    setProgramPayload(newProgramPayload)
  }

  const handleNext = () => {
    if (programPayload?.length > 0) {
      setPostLoading(true)
      programSelectionMutation({
        variables: {
          inputs: programPayload,
        },
        refetchQueries: [
          {
            query: GET_APPLICANT_SELECTED_WINDOW,
            variables: {
              applicantUid: applicant?.uid,
            },
          },
        ],
        onCompleted: ({ registerProgramSelection }) => {
          if (registerProgramSelection.code === 8000) {
            showToast(registerProgramSelection?.message, "success")
            setPostLoading(false)
            setModalOpen(false)
          } else {
            setPostLoading(false)
          }
        },
        onError: error => {
          // Handle errors
          setPostLoading(false)
          console.error("Mutation error:", error)
        },
      })
    } else {
      showToast("Please select at lest one program to proceed", "warning")
    }
  }

  const tcuVerification = tcuData?.verifyApplicantTcu

  return (
    <div className="text-sm-end mt-3">
      <div className="card-radio">
        <div>
          <i className="font-size-24 text-primary align-middle me-2" />{" "}
          <Button
            type="button"
            color="primary"
            className="btn mb-2 me-2 bx-pull-right"
            onClick={() => setModalOpen(true)}
          >
            <i className="mdi mdi-plus-circle-outline me-1" />
            Select Program
          </Button>
        </div>
      </div>
      <Modal
        isOpen={modalOpen}
        backdrop={"static"}
        id="staticBackdrop"
        size="lg"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            {`Program Selection`}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setModalOpen(false)
              setSelectedPrograms([])
            }}
            aria-label="Close"
          ></button>
        </div>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return null
          }}
        >
          <div className="modal-body mt-4">
            <Row>
              {!loading &&
              !tcuVerifyLoading &&
              tcuVerification?.code === 8000 ? (
                <>
                  <Col md={4}>
                    <div className="mb-3">
                      <label htmlFor="choiceNumber">Choice Number: </label>
                      <select
                        id="choiceNumber"
                        value={choiceNumber}
                        className={`form-control`}
                        onChange={e => setChoiceNumber(e.target.value)}
                      >
                        <option value="">--Select a Choice Number--</option>
                        {availableChoices.map(choice => (
                          <option key={choice} value={choice}>
                            {choice}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                  <Col md={8}>
                    <div className="mb-3">
                      <Label>Select Program of Study</Label>
                      <Select
                        name="programName"
                        onChange={e => {
                          handleSelectProgram(
                            programList?.find(p => p?.programUid === e.value)
                          )
                          validation.setFieldValue("programName", e.value)
                        }}
                        options={programList
                          ?.map(pro => {
                            if (choiceNumber !== "" && pro?.isActive === true) {
                              return {
                                value: pro?.programUid,
                                label: pro?.programName,
                              }
                            }
                            return null
                          })
                          .filter(option => option !== null)}
                        className="select2-selection"
                        styles={{
                          menu: base => ({
                            ...base,
                            position: "absolute",
                            zIndex: 9999,
                          }),
                        }}
                        defaultValue={
                          payLoad?.programName && payLoad?.programName
                        }
                        defaultInputValue={
                          payLoad?.programName && payLoad?.programName
                        }
                      />
                      <Input
                        name="programName"
                        placeholder="Select Region"
                        type="hidden"
                        rows={2}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={
                          (payLoad?.programName && payLoad?.programName) ||
                          (payLoad?.programName && payLoad?.programName) ||
                          ""
                        }
                        invalid={
                          !!(
                            validation.touched.programName &&
                            validation.errors.programName
                          )
                        }
                      />
                      {validation.touched.programName &&
                      validation.errors.programName ? (
                        <FormFeedback type="invalid">
                          {validation.errors.programName}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </>
              ) : (
                <>
                  {!loading &&
                    !tcuVerifyLoading &&
                    tcuVerification?.code !== 8000 && (
                      <div className="alert alert-danger">
                        {tcuVerification?.message}
                      </div>
                    )}
                </>
              )}
            </Row>
            <Row>
              <Col md={12}>
                <div className="mt-3">
                  <CardTitle>Selected Programs</CardTitle>
                  <Table
                    className={`table table-bordered table-striped`}
                    style={{ border: "2px solid #63a69f" }}
                  >
                    <Thead>
                      <Tr>
                        <Th>Program name</Th>
                        <Th>Choice Number</Th>
                        <Th>Action</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {selectedPrograms?.map((program, index) => (
                        <Tr key={index}>
                          <Td>{program?.programName}</Td>
                          <Td className={`text-center`}>
                            {program?.choiceNumber}
                          </Td>
                          <Td>
                            <button
                              className={`btn btn-sm btn-danger`}
                              onClick={() =>
                                handleRemoveProgram(program?.programUid)
                              }
                            >
                              <i className={`fa fa-times`}></i>
                            </button>
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </div>
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                setModalOpen(false)
                setSelectedPrograms([])
              }}
            >
              Close
            </button>
            {tcuVerification?.code === 8000 && (
              <button onClick={handleNext} className="btn btn-primary">
                {postLoading ? (
                  <>
                    <i className="fas fa-spinner fa-spin fa-1x"> </i> Please
                    wait...
                  </>
                ) : (
                  "Next >"
                )}
              </button>
            )}
          </div>
        </Form>
      </Modal>
    </div>
  )
}

export default ProgramSelectionModal
