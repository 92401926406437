import { gql } from "@apollo/client"

const GET_APPLICANT_DETAILSS = gql`
    query GetApplicantDetailss($userUid: String!) {
        getApplicantDetails(userUid: $userUid) {
            status
            code
            message
            data {
                uid
                email
                firstName
                middleName
                lastName
                phoneNumber
                gender
                tanzanian
                formFourIndexNumber
                applicationLevel
                applicationLevelCode
                applicationLevelId
                qualificationMethod
                qualificationMethodId
                qualificationMethodCode
                birthDate
                physicalAddress
                formSixIndexNumber
                submissionStatus
            }
        }
    }
`

const VERIFY_APPLICANT_TCU = gql`
  query VerifyApplicantTcu($input: TCUVerificationRequestNode!) {
    verifyApplicantTcu(input: $input) {
      status
      code
      message
      data {
        formFourIndexNumber
        statusCode
        statusDescription
      }
    }
  }
`

const GET_APPLICANT_NEXT_OF_KINS = gql`
  query GetApplicantNextOfKins($applicantUid: String!) {
    getApplicantNextOfKins(applicantUid: $applicantUid) {
      message
      data {
        uid
        fullName
        phoneNumber
        kinshipName
      }
    }
  }
`

const GET_PROGRAM_CATEGORY_BY_CATEGORY_CODE = gql`
    query GetProgramCategoryByCategoryCode($programCategoryCode: String!) {
        getProgramCapacityByCategoryCode(
            programCategoryCode: $programCategoryCode
        ) {
            status
            code
            message
            data {
                items {
                    isActive
                    uid
                    programUid
                    programName
                    programCategoryCode
                    admissionYear {
                        numberOfChoices
                    }
                }
            }
        }
    }
`

const GET_ACTIVE_ADMISSION_WINDOWS = gql`
  query GetCurrentAdmissionWindow($applicationLevelCode: String!) {
    getActiveAdmissionWindow(applicationLevelCode: $applicationLevelCode) {
      status
      code
      message
      data {
        uid
        admissionYear {
          numberOfChoices
        }
        description
        startDate
        endDate
      }
    }
  }
`
const GET_APPLICANT_SELECTION = gql`
    query getApplicantSelections(
        $applicantUid: String!
        $admissionWindowUid: String!
    ) {
        getApplicantSelections(
            applicantUid: $applicantUid
            admissionWindowUid: $admissionWindowUid
        ) {
            status
            code
            message
            data {
                items {
                    uid
                    rejectionReason
                    selectionStatus
                    choice
                    applicantUid
                    programCapacity {
                        uid
                        capacity
                        programName
                    }
                    admissionWindow {
                        uid
                        batchNumber
                        description
                    }
                }
                totalCount
            }
        }
    }
`

const GET_CURRENT_WINDOW_APPLICANT_SELECTIONS = gql`
  query GetCurrentWindowApplicantSelections($applicantUid: String!) {
    getCurrentWindowApplicantSelections(applicantUid: $applicantUid) {
      status
      code
      data {
        items {
          uid
          selectionStatus
          choice
          applicantUid
          programCapacity {
            programName
          }
        }
      }
    }
  }
`
const GE_SUMMARY_REPORT = gql`
  query GetApplicantSummaryReport($inputData: ApplicationYearInput!) {
    getApplicantSummaryReport(inputData: $inputData) {
      code
      status
      message
      data {
        totalApplicants
        applicantsInGender {
          female
          male
        }
        applicantsInGenderPercent {
          female
          male
        }
        totalUndergraduate
        undergraduatePercentage
        undergraduateInGender {
          female
          male
        }
        undergraduateInGenderPercent {
          female
          male
        }
        totalPostgraduate
        postgraduatePercentage
        postgraduateInGender {
          female
          male
        }
        postgraduateInGenderPercent {
          female
          male
        }
        totalCertificate
        certificateInGender {
          female
          male
        }
        certificateInGenderPercent {
          female
          male
        }
        totalBachelor
        totalDiploma
        diplomaInGender {
          female
          male
        }
        diplomaInGenderPercent {
          female
          male
        }
        bachelorInGender {
          female
          male
        }
        bachelorInGenderPercent {
          female
          male
        }
        totalMaster
        totalPhd
        totalPostGraduateDiploma
        masterInGender {
          female
          male
        }
        masterInGenderPercent {
          male
          female
        }
        phdInGenderPercent {
          female
          male
        }
        phdInGender {
          female
          male
        }
        postGraduateDiplomaInGender {
          female
          male
        }
        postGraduateDiplomaInGenderPercent {
          female
          male
        }
      }
    }
  }
`

const GET_APPLICANT_SELECTED_WINDOW = gql`
    query GetApplicantAppliedWindow($applicantUid: String!) {
        getApplicantAppliedWindow(applicantUid: $applicantUid) {
            status
            code
            data{
                items{
                    uid
                    description
                    code
                    batchNumber
                    admissionYear{
                        uid
                        name
                    }
                    levelType{
                        uid
                        name
                    }
                    startDate
                    endDate
                }
            }
        }
    }
`

export {
  GET_APPLICANT_DETAILSS,
  VERIFY_APPLICANT_TCU,
  GET_APPLICANT_NEXT_OF_KINS,
  GET_PROGRAM_CATEGORY_BY_CATEGORY_CODE,
  GET_ACTIVE_ADMISSION_WINDOWS,
  GET_APPLICANT_SELECTION,
  GET_CURRENT_WINDOW_APPLICANT_SELECTIONS,
  GE_SUMMARY_REPORT,
  GET_APPLICANT_SELECTED_WINDOW
}
