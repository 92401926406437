import React, { createContext, useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Label,
  Input,
  FormFeedback,
} from "reactstrap"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import * as Yup from "yup"
import { useFormik } from "formik"
import LayoutHelper from "../../../helpers/LayoutHelper"
import { useLocation, useNavigate } from "react-router-dom"
import SubmitButtonHelper from "../../../helpers/SubmitButtonHelper"
import { useMutation, useQuery } from "@apollo/client"
import { GET_PROGRAM_CAPACITY } from "../../OnlineApplication/AdmissionProgramCapacity/Queries/ProgramAdmissionQueries"
import {
  onlineApplicationGraphQLClient,
  registrationGraphQLClient,
  uaaGraphQLClient,
} from "../../Authentication/ApolloClient"
import Select from "react-select"
import { GET_COUNTRY } from "../../Country/Queries/CountryQueries"
import { paginationHelper } from "../../../helpers/Functions/GraphqlUpdateFunction"
import { STUDENT_MANUAL_REGISTRATION } from "../Mutations/StudentRegistrationMutations"
import showToast from "../../../helpers/ToastHelper"

const breadcrumbItem = [
  {
    title: "Student Manual Admission",
    path: "",
    isActive: true,
  },
]

export const StudentListContext = createContext() // Create data to transfer to child modal

const genderes = [
  {
    value: "Male",
    label: "Male",
  },
  {
    value: "Female",
    label: "Female",
  },
]
const ManualStudentAdmission = () => {
  const location = useLocation()
  const [studentData, setStudentData] = useState(false),
    [currentPage, setCurrentPage] = useState(0),
    [offset, setOffset] = useState(0),
    [search, setSearch] = useState(null),
    navigate = useNavigate()
  const pagination = { offset: currentPage, limit: 10000, search: null }
  const { loading, error, data, refetch } = useQuery(GET_PROGRAM_CAPACITY, {
    client: onlineApplicationGraphQLClient,
    variables: {
      pagination,
    },
    fetchPolicy: "cache-first",
  })
  const programAdmission = data?.getProgramCapacities?.data?.items // extract program capabilities

  const [manualAdmission] = useMutation(STUDENT_MANUAL_REGISTRATION, {
    client: uaaGraphQLClient,
  })

  const {
    loading: countryLoading,
    error: countryError,
    data: countryData,
  } = useQuery(GET_COUNTRY, {
    client: uaaGraphQLClient,
    variables: {
      pagination: pagination,
    },
  })
  const countries = countryData?.getCountries?.data?.items

  const qualificationMode = [
    {
      label: "Direct",
      value: "D",
    },
    {
      label: "Equivalent",
      value: "E",
    },
  ]

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: "",
      middleName: "",
      lastName: "",
      email: "",
      registrationNumber: "",
      formFourIndexNo: "",
      formSixIndexNo: "",
      gender: "",
      phoneNumber: "",
      programmeCode: "",
      countryCode: "",
      entryCategoryCode: "",
      username: "",
      studentCertificates: [],
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required("This field is required"),
      middleName: Yup.string().nullable("This field is required"),
      lastName: Yup.string().required("This field is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("This field is required"),
      formFourIndexNo: Yup.string().nullable("This field is required"),
      gender: Yup.string().required("This field is required"),
      phoneNumber: Yup.string().required("This field is required"),
      programmeCode: Yup.string().required("This field is required"),
      countryCode: Yup.string().required("This field is required"),
      entryCategoryCode: Yup.string().required("This field is required"),
    }),
    onSubmit: values => {
      const currentYear = new Date().getFullYear()
      values.username = values.formFourIndexNo + `/${currentYear}`
      const payload = {
        firstName: values.firstName,
        middleName: values.middleName,
        lastName: values.lastName,
        email: values.email,
        gender: values.gender,
        username: values.username,
        entryCategoryCode: values.entryCategoryCode,
        countryCode:
          values.countryCode && values.countryCode === "TZ" ? "255" : "254",
        programmeCode: values.programmeCode,
        phoneNumber: values.phoneNumber,
        studentCertificates: values.studentCertificates,
      }
      manualAdmission({
        variables: {
          studentInput: { ...payload },
        },
        onCompleted: ({ addManualAdmittedStudent }) => {
          if (addManualAdmittedStudent?.code === 8000) {
            showToast(addManualAdmittedStudent?.message, "success", "Feedback")
            formik.setSubmitting(false)
          } else if (addManualAdmittedStudent?.code >= 8001) {
            showToast(addManualAdmittedStudent?.message, "error", "Feedback")
            formik.setSubmitting(false)
          }
        },
        onError: error => {
          formik.setSubmitting(false)
        },
      })
    },
  })

  useEffect(() => {
    const certificates = []
    if (formik.values.formFourIndexNo) {
      certificates.push({
        certificateTypeCode: "CSEE",
        certificateNumber: formik.values.formFourIndexNo,
      })
    }
    if (formik.values.formSixIndexNo) {
      certificates.push({
        certificateTypeCode: "ACSEE",
        certificateNumber: formik.values.formSixIndexNo,
      })
    }
    formik.setFieldValue("studentCertificates", certificates)
  }, [formik.values.formFourIndexNo, formik.values.formSixIndexNo])

  return (
    <StudentListContext.Provider value={{ studentData, setStudentData }}>
      <LayoutHelper
        breadcrumbItem={breadcrumbItem}
        pageTitle="Student Manual Admission"
      >
        <Row>
          <Col md={12}>
            <Card>
              <CardBody>
                <Form
                  onSubmit={e => {
                    e.preventDefault()
                    formik.handleSubmit()
                    return false
                  }}
                >
                  <div className="modal-body">
                    <Row>
                      <Col md={6}>
                        <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                          <Label>Program Name</Label>
                          <Select
                            styles={{
                              menu: base => ({
                                ...base,
                                position: "absolute",
                                zIndex: 9999,
                              }),
                            }}
                            onChange={e => {
                              formik.setFieldValue("programmeCode", e.value)
                            }}
                            options={programAdmission?.map(pro => ({
                              value: pro?.code,
                              label: pro?.programName + " (" + pro?.code + ")",
                            }))}
                            className="select2-selection"
                            isLoading={loading}
                          />
                          <Input
                            name="programmeCode"
                            placeholder=""
                            type="hidden"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.programmeCode || ""}
                            invalid={
                              !!(
                                formik.touched.programmeCode &&
                                formik.errors.programmeCode
                              )
                            }
                          />
                          {formik.touched.programmeCode &&
                          formik.errors.programmeCode ? (
                            <FormFeedback type="invalid">
                              {formik.errors.programmeCode}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label>First Name</Label>
                          <Input
                            type="text"
                            name="firstName"
                            className="form-control"
                            id="text"
                            placeholder="Enter  First Name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            invalid={
                              formik.touched.firstName &&
                              formik.errors.firstName
                                ? true
                                : false
                            }
                          />
                          {formik.touched.firstName &&
                          formik.errors.firstName ? (
                            <FormFeedback type="invalid">
                              {formik.errors.firstName}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label>Middle Name</Label>
                          <Input
                            type="text"
                            name="middleName"
                            className="form-control"
                            id="text"
                            placeholder="Middle name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            invalid={
                              formik.touched.middleName &&
                              formik.errors.middleName
                                ? true
                                : false
                            }
                          />
                          {formik.touched.middleName &&
                          formik.errors.middleName ? (
                            <FormFeedback type="invalid">
                              {formik.errors.middleName}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label>Last Name</Label>
                          <Input
                            type="text"
                            name="lastName"
                            className="form-control"
                            id="text"
                            placeholder="Enter  Last Name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            invalid={
                              formik.touched.lastName && formik.errors.lastName
                                ? true
                                : false
                            }
                          />
                          {formik.touched.lastName && formik.errors.lastName ? (
                            <FormFeedback type="invalid">
                              {formik.errors.lastName}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                            <Label>Gender</Label>
                            <Select
                              // value={selectedMulti2}
                              styles={{
                                menu: base => ({
                                  ...base,
                                  position: "absolute",
                                  zIndex: 9999,
                                }),
                              }}
                              onChange={e => {
                                formik.setFieldValue("gender", e.value)
                              }}
                              options={genderes}
                              className="select2-selection"
                            />
                            <Input
                              name="gender"
                              placeholder=""
                              type="hidden"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.gender || ""}
                              invalid={
                                !!(
                                  formik.touched.gender && formik.errors.gender
                                )
                              }
                            />
                            {formik.touched.gender && formik.errors.gender ? (
                              <FormFeedback type="invalid">
                                {formik.errors.gender}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label>
                            Phone Number
                            <small>
                              (Start with international code e.g +255)
                            </small>
                          </Label>
                          <Input
                            type="text"
                            name="phoneNumber"
                            className="form-control"
                            id="text"
                            placeholder="e.g +255700000000"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.phoneNumber || ""}
                            invalid={
                              formik.touched.phoneNumber &&
                              formik.errors.phoneNumber
                                ? true
                                : false
                            }
                          />
                          {formik.touched.phoneNumber &&
                          formik.errors.phoneNumber ? (
                            <FormFeedback type="invalid">
                              {formik.errors.phoneNumber}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label>Email Address</Label>
                          <Input
                            type="text"
                            name="email"
                            className="form-control"
                            id="text"
                            placeholder="Enter  email"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email || ""}
                            invalid={
                              formik.touched.email && formik.errors.email
                                ? true
                                : false
                            }
                          />
                          {formik.touched.email && formik.errors.email ? (
                            <FormFeedback type="invalid">
                              {formik.errors.email}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="mb-3">
                          <Label>Registration Number (Optional)</Label>
                          <Input
                            type="text"
                            name="registrationNumber"
                            className="form-control"
                            id="text"
                            placeholder="Enter registration(If given)"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.registrationNumber || ""}
                            invalid={
                              formik.touched.registrationNumber &&
                              formik.errors.registrationNumber
                                ? true
                                : false
                            }
                          />
                          {formik.touched.registrationNumber &&
                          formik.errors.registrationNumber ? (
                            <FormFeedback type="invalid">
                              {formik.errors.registrationNumber}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label>Form Four Index Number</Label>
                          <Input
                            type="text"
                            name="formFourIndexNo"
                            className="form-control"
                            id="text"
                            placeholder="E.g: S1231/0023/2023"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.formFourIndexNo || ""}
                            invalid={
                              formik.touched.formFourIndexNo &&
                              formik.errors.formFourIndexNo
                                ? true
                                : false
                            }
                          />
                          {formik.touched.formFourIndexNo &&
                          formik.errors.formFourIndexNo ? (
                            <FormFeedback type="invalid">
                              {formik.errors.formFourIndexNo}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="mb-3">
                          <Label>Form Six Index Number (Optional)</Label>
                          <Input
                            type="text"
                            name="formSixIndexNo"
                            className="form-control"
                            id="text"
                            placeholder="E.g: S1231/0023/2003"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.formSixIndexNo || ""}
                            invalid={
                              formik.touched.formSixIndexNo &&
                              formik.errors.formSixIndexNo
                                ? true
                                : false
                            }
                          />
                          {formik.touched.formSixIndexNo &&
                          formik.errors.formSixIndexNo ? (
                            <FormFeedback type="invalid">
                              {formik.errors.formSixIndexNo}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label>Country</Label>
                          <Select
                            name="countryCode"
                            onChange={e => {
                              formik.setFieldValue("countryCode", e.value)
                            }}
                            options={countries?.map(country => ({
                              value: country?.code,
                              label: country?.name,
                            }))}
                            className="select2-selection"
                            styles={{
                              menu: base => ({
                                ...base,
                                position: "absolute",
                                zIndex: 9999,
                              }),
                            }}
                          />
                          <Input
                            name="countryCode"
                            placeholder="Select Region"
                            type="hidden"
                            rows={2}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.countryCode || ""}
                            invalid={
                              !!(
                                formik.touched.countryCode &&
                                formik.errors.countryCode
                              )
                            }
                          />
                          {formik.touched.countryCode &&
                          formik.errors.countryCode ? (
                            <FormFeedback type="invalid">
                              {formik.errors.countryCode}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label>Entry Mode</Label>
                          <Select
                            onChange={e => {
                              formik.setFieldValue("entryCategoryCode", e.value)
                            }}
                            options={qualificationMode}
                            className="select2-selection"
                            styles={{
                              menu: base => ({
                                ...base,
                                position: "absolute",
                                zIndex: 9999,
                              }),
                            }}
                          />
                          <Input
                            name="entryCategoryCode"
                            placeholder="Select qualfification mode"
                            type="hidden"
                            rows={2}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.entryCategoryCode || ""}
                            invalid={
                              !!(
                                formik.touched.entryCategoryCode &&
                                formik.errors.entryCategoryCode
                              )
                            }
                          />
                          {formik.touched.entryCategoryCode &&
                          formik.errors.entryCategoryCode ? (
                            <FormFeedback type="invalid">
                              {formik.errors.entryCategoryCode}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <SubmitButtonHelper
                    type="primary"
                    name="Submit"
                    formik={formik}
                  />
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </LayoutHelper>
    </StudentListContext.Provider>
  )
}

export default ManualStudentAdmission
