import React from "react";
import { Route, Routes } from "react-router-dom"
import AsimsDashboard from "../../ASIMS/views/asimsDashboard"
import Pages404 from "../../Utility/pages-404"
import StudentChangeProgram from "../Views/Application/StudentChangeProgram"


const ProgramChangeRoutes = () => {
  return (
    <Routes>
      <Route path= "/dashboard" element={<AsimsDashboard />} />
      <Route path="/change-programme"  element={<StudentChangeProgram />} />
      <Route path="*" element={<Pages404 />} />
    </Routes>
  );
};
export default ProgramChangeRoutes;
