//REGISTE
import { gql } from '@apollo/client';


const GENERATE_SEMESTER_EXAMINATION_RESULTS_XLS = gql`
    query generateSemesterExamResults($programUid: String!, $academicYearUid: String!,$semester: Int!, $yearOfStudy: Int!, $numberOfSitting: Int!) {
        generateSemesterExamResults(programUid: $programUid, academicYearUid: $academicYearUid, semester: $semester, yearOfStudy: $yearOfStudy, numberOfSitting: $numberOfSitting){
            status
            code
            message
            data{
                base64Data
                fileName
            }
        }
    }
`;

const GENERATE_ACADEMIC_UNIT_RESULTS_XLS = gql`
    query GenerateAcademicUnitResults($programUid: String!, $academicYearUid: String!, $yearOfStudy: Int!, $numberOfSitting: Int!) {
        generateAcademicUnitResults(programUid: $programUid, academicYearUid: $academicYearUid, yearOfStudy: $yearOfStudy, numberOfSitting: $numberOfSitting){
            status
            code
            message
            data{
                base64Data
                fileName
            }
        }
    }
`;

const GENERATE_SEMESTER_COURSE_RESULTS_XLS = gql`
  query getSemesterCourseResults($programCourseUid: String!) {
    getSemesterCourseResults(programCourseUid: $programCourseUid){
        status
        code
        message
        data{
          base64Data
          fileName
        }
    }
  }
`;



export { GENERATE_SEMESTER_EXAMINATION_RESULTS_XLS,GENERATE_SEMESTER_COURSE_RESULTS_XLS,GENERATE_ACADEMIC_UNIT_RESULTS_XLS }
