import React, { useCallback, useEffect, useRef } from "react"
import { useLocation } from "react-router-dom"
import PropTypes from "prop-types"
import SimpleBar from "simplebar-react"
import MetisMenu from "metismenujs"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import { jwtDecode } from "jwt-decode"
import ASIMSLinks from "./SidebarLinks/ASIMSLinks"
import UserManagementLinks from "./SidebarLinks/UserManagementLinks"
import RegistrationLinks from "./SidebarLinks/RegistrationLinks"
import AdmissionLinks from "./SidebarLinks/AdmissionLinks"
import PaymentLinks from "./SidebarLinks/PaymentLinks"
import ExaminationAndResultsLinks from "./SidebarLinks/ExaminationAndResultsLinks"
import InstructorLinks from "./SidebarLinks/InstructorLinks"
import AcademicAdvisorLinks from "./SidebarLinks/AcademicAdvisorLinks"
import HODLinks from "./SidebarLinks/HODLinks"
import AccommodationLinks from "./SidebarLinks/AccomodationLinks"
import DissertationAndThesisLinks from "./SidebarLinks/DissertationAndThesisLinks"
import PrincipleLinks from "./SidebarLinks/PrincipleLinks"
import DusLinks from "./SidebarLinks/DusLinks"
import ReportsLinks from "./SidebarLinks/ReportsLinks"
import SettingsLinks from "./SidebarLinks/SettingsLinks"
import StudentLinks from "./SidebarLinks/StudentLinks"
import ApplicantLinks from "./SidebarLinks/ApplicantLinks"
import TeachingAssesmentLinks from "./SidebarLinks/TeachingAssesmentLinks"
import SenateLinks from "./SidebarLinks/SenateLinks"
import ProgramChangeLinks from "./SidebarLinks/ProgramChangeLinks"

const SidebarContent = props => {
  const accessToken = JSON.parse(localStorage.getItem("authUser"))?.accessToken
  const userData = accessToken ? jwtDecode(accessToken) : null
  const userAuthorities = userData?.authorities || []

  const hasAuthority = authority => userAuthorities.includes(authority)

  const ref = useRef()
  const activateParentDropdown = useCallback(item => {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }, [])

  const removeActivation = items => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i]
      const parent = items[i].parentElement

      if (item && item.classList.contains("active")) {
        item.classList.remove("active")
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show")
        }

        parent.classList.remove("mm-active")
        const parent2 = parent.parentElement

        if (parent2) {
          parent2.classList.remove("mm-show")

          const parent3 = parent2.parentElement
          if (parent3) {
            parent3.classList.remove("mm-active") // li
            parent3.childNodes[0].classList.remove("mm-active")

            const parent4 = parent3.parentElement // ul
            if (parent4) {
              parent4.classList.remove("mm-show") // ul
              const parent5 = parent4.parentElement
              if (parent5) {
                parent5.classList.remove("mm-show") // li
                parent5.childNodes[0].classList.remove("mm-active") // a tag
              }
            }
          }
        }
      }
    }
  }

  const path = useLocation()

  const activeMenu = useCallback(() => {
    const pathName = path.pathname
    let matchingMenuItem = null
    const ul = document.getElementById("side-menu")
    const items = ul.getElementsByTagName("a")
    removeActivation(items)

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  }, [path.pathname, activateParentDropdown])

  useEffect(() => {
    ref.current.recalculate()
  }, [])

  useEffect(() => {
    new MetisMenu("#side-menu")
    activeMenu()
  }, [])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
    activeMenu()
  }, [activeMenu])

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  return (
    <React.Fragment>
      {/*<SimpleBar className="h-100" style={{backgroundColor: '#2e3440', color:'white'}} ref={ref}>*/}
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {path.pathname.startsWith("/student/") && (
              <StudentLinks hasAuthority={hasAuthority} />
            )}

            {path.pathname.startsWith("/programme-change/") && (
              <ProgramChangeLinks hasAuthority={hasAuthority} />
            )}

            {path.pathname.startsWith("/teaching-assessment/") && (
              <TeachingAssesmentLinks hasAuthority={hasAuthority} />
            )}

            {path.pathname.startsWith("/applicant/") && (
              <ApplicantLinks hasAuthority={hasAuthority} />
            )}

            {path.pathname.startsWith("/self-registration/") && (
              <RegistrationLinks hasAuthority={hasAuthority} />
            )}

            {path.pathname.startsWith("/admission/") && (
              <AdmissionLinks hasAuthority={hasAuthority} />
            )}

            {path.pathname.startsWith("/finance/") && (
              <PaymentLinks hasAuthority={hasAuthority} />
            )}

            {path.pathname.startsWith("/asims/") && (
              <ASIMSLinks hasAuthority={hasAuthority} />
            )}

            {path.pathname.startsWith("/accommodation/") && (
              <AccommodationLinks hasAuthority={hasAuthority} />
            )}

            {path.pathname.startsWith("/user-management/") && (
              <UserManagementLinks hasAuthority={hasAuthority} />
            )}

            {path.pathname.startsWith("/examination/") && (
              <>
                <ExaminationAndResultsLinks hasAuthority={hasAuthority} />
                <HODLinks hasAuthority={hasAuthority} />
                <InstructorLinks hasAuthority={hasAuthority} />
                <PrincipleLinks hasAuthority={hasAuthority} />
                <SenateLinks hasAuthority={hasAuthority} />
              </>
            )}

            {(path.pathname === "/dissertation" ||
              path.pathname === "/manu-script" ||
              path.pathname === "/intention-to-submit") && (
              <>
                <AcademicAdvisorLinks hasAuthority={hasAuthority} />
                <DissertationAndThesisLinks hasAuthority={hasAuthority} />
              </>
            )}

            {path.pathname.startsWith("/reports/") && (
              <ReportsLinks hasAuthority={hasAuthority} />
            )}

            {path.pathname.startsWith("/system-settings/") && (
              <SettingsLinks hasAuthority={hasAuthority} />
            )}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
