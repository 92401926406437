import { gql } from "@apollo/client"

const GET_APPLICANT_CONFIRMATION_BY_PROGRAMME = gql`
    query GetApplicantConfirmationByProgram($input:TCUApplicantStatusByProgrammeRequestNode!){
        getApplicantConfirmationStatusByProgrammeTcu(input:$input){
            status
            code
            message
            data{
                programmeCode
                statusCode
                statusDescription
                applicants{
                    formFourIndexNumber
                    phoneNumber
                    programmeCode
                    admissionStatusDescription
                    admissionStatusDescription
                    admissionStatus
                }
            }
        }
    }
`

const GET_APPLICANT_STATUS_BY_PROGRAMME_TCU = gql`
    query GetApplicantByProgram($input:TCUApplicantStatusByProgrammeRequestNode!){
        getApplicantStatusByProgrammeTcu(input:$input){
            status
            code
            message
            data{
                programmeCode
                applicants{
                    formFourIndexNumber
                    admissionStatusCode
                    admissionStatusDescription
                }
            }
        }
    }
`


const GET_APPLICANT_LIST_REPORT = gql`
    query getApplicantListReport ($inputData: ApplicantListReportInput!){
        getApplicantListReport(
            inputData: $inputData
        ) {
            code
            message
            status
            data {
                sno
                firstName
                middleName
                lastName
                gender
                nationality
                impairment
                dateOfBirth
                formFourIndexNumber
                formSixIndexNumber
            }
        }
    }
`

const GENERATE_APPLICANT_XLS_TEMPLATE = gql`
    query GenerateApplicantXlsTemplate ($inputData: ApplicantListReportInput!){
        generateApplicantXlsTemplate(
            inputData: $inputData
        ) {
            base64Data
            fileName
        }
    }
`

const GET_SELECTION_RANKINGS = gql`

    query getApplicantsRankingReport($inputData: ApplicantListReportInput!){
        getApplicantsRankingReport(inputData:$inputData){
            status
            code
            message
            data{
                items{
                    sno
                    firstName
                    middleName
                    lastName
                    gender
                    formFourIndexNumber
                    formSixIndexNumber
                    points
                    rejectionReasons
                    dateOfBirth
                    applicantCategory
                    selectionStatus
                    cseeLevelResults
                    acseeLevelResults
                    diplomaLevelResults,
                    certificateLevelResults,
                    programmeName
                    institutionName
                }
            }

        }
    }
`

const GET_SELECTED_APPLICANT_TCU = gql`
    query GetApplicantsSelectionReport($inputData: ApplicantSelectionReportInput!){
        getApplicantsSelectionReport(inputData:$inputData){
            status
            code
            data{
                items{
                    sno
                    lastName
                    email
                    gender
                    middleName
                    firstName
                    formFourIndexNumber
                    programmeName
                    tcuStatusDescription
                    isTcuMultipleSelection
                }
                totalCount
            }
        }
    }
`

export {
  GET_APPLICANT_CONFIRMATION_BY_PROGRAMME,
  GENERATE_APPLICANT_XLS_TEMPLATE,
  GET_APPLICANT_STATUS_BY_PROGRAMME_TCU,
  GET_APPLICANT_LIST_REPORT,
  GET_SELECTION_RANKINGS,
  GET_SELECTED_APPLICANT_TCU
}