import { Route, Routes } from "react-router-dom"
import StudentsList from "../../Modules/Students/Views/StudentsList"
import ViewStudent from "../../Modules/Students/Views/ViewStudent"
import UpdateStudentDetails from "../../Modules/Students/Views/UpdateStudentDetails"
import StudentEmailRecovery from "../../Modules/Students/Views/Recovery/StudentEmailRecovery"
import StudentAccountSwitch from "../../Modules/Students/Views/Recovery/StudentAccountSwitch"
import StudentCancelProgramChange from "../../Modules/Students/Views/Recovery/StudentCancelProgramChange"
import StudentExamResults from "../../Modules/Students/Views/Examinations/StudentExamResults"
import SingleStudentExamResults from "../../Modules/Students/Views/Examinations/SingleStudentExamResults"
import PartialTranscript from "../../Modules/Transcripts/Views/PartialTranscript"
import StudentPaymentRoutes from "../../Modules/Students/Routes/StudentPaymentRoutes"
import Pages404 from "../../Modules/Utility/pages-404"
import React from "react"
import AsimsDashboard from "../../Modules/ASIMS/views/asimsDashboard"
import ApplicationWindowRoutes from "../../Modules/ApplicationWindow/Routes/ApplicationWindowRoutes"
import CertificateTypeRoutes from "../../Modules/CertificateTypes/Routes/CertificateTypeRoutes"
import EntryCategoryRoutes from "../../Modules/EntryCategories/Routes/EntryCategoryRoutes"
import AcademicYearsRoutes from "../../Modules/AcademicYears/Routes/AcademicYearsRoutes"
import CoreCourseRegistration from "../../Modules/Courses/Views/CoreCourseRegistration"
import SemesterRoutes from "../../Modules/Semester/Routes/SemesterRoutes"
import ExamCategoryRoutes from "../../Modules/ExamCategories/Routes/ExamCategoryRoutes"
import LookupRoutes from "../../Modules/Lookups/Routes/LookupRoutes"
import GradePointRoutes from "../../Modules/GradePoints/Routes/GradePointRoutes"
import CategoryRoutes from "../../Modules/ProgramCategories/Routes/CategoryRoutes"
import ApplicantSelectedReports
  from "../../Modules/OnlineApplication/ApplicationConfirmationAndSelections/Views/ApplicantSelectedReports"
import { AdmissionOnlineApplicationRoutes, AdmissionStudentRoutes, ManualAdmissionStudentRoutes } from "../HelperRoutes"
import SignaturePad from "../../Modules/SignaturePad/Views/SignaturePad"
import ApplicantEmailRecovery from "../../Modules/OnlineApplication/Recovery/Views/RecoverApplicantDetails"
import ApplicantListReport from "../../Modules/OnlineApplication/Reports/Views/ApplicantListReport"
import ApplicantListByDate from "../../Modules/OnlineApplication/Reports/Views/ApplicantListByDate"
import ApplicantSubmissionStatusReport
    from "../../Modules/OnlineApplication/Reports/Views/ApplicantSubmissionStatusReport"

const AdmissionRoutes = () => {
  // console.log(history)
  return (
    <Routes>
      <Route path={"dashboard"} element={<AsimsDashboard />} />
      <Route exact={true} path="switch_account" element={<StudentAccountSwitch />} />
      <Route path={"/application-window/*"} index={true} element={<ApplicationWindowRoutes />} />
      <Route path={"/certificate-types/*"} element={<CertificateTypeRoutes />} />
      <Route path={"entry-categories"} element={<EntryCategoryRoutes />} />
      <Route path={"/academicyears"} element={<AcademicYearsRoutes />} />
      <Route path={"tcu_send_enrollment"} element={<StudentsList />} />
      <Route path={"/signaturepad"} element={<SignaturePad />} />
      <Route path={"force_core_registration"} element={<CoreCourseRegistration />} />
      <Route path={"/semesters/*"} index={true} element={<SemesterRoutes />} />
      <Route path={"/exam-category/*"} index={true} element={<ExamCategoryRoutes />} />
      <Route path={"recover_student_account"} element={<StudentEmailRecovery />} />
      <Route path="/recover-applicant-password" element={<ApplicantEmailRecovery />} />
      <Route path={"/students/*"} index={true} element={<AdmissionStudentRoutes />} />
      <Route path={"/manual/*"} index={true} element={<ManualAdmissionStudentRoutes />} />
      <Route path={"/lookups/*"} index={true} element={<LookupRoutes />} />
      <Route exact={true} path="/cancel_program_change" element={<StudentCancelProgramChange />} />
      {/*<Route index={true} path="/examinations" element={<StudentExamResults/>} />*/}
      <Route index={true} path="/student-examinations" element={<SingleStudentExamResults />} />
      <Route index={true} path="/partial-transcript" element={<PartialTranscript />} />
      <Route index={true} path="/grade-points/*" element={<GradePointRoutes />} />
      <Route index={true} path="/application/*" element={<AdmissionOnlineApplicationRoutes />} />
      <Route path="/programme-categories/*" element={<CategoryRoutes />} />
      <Route path="/selections" element={<ApplicantSelectedReports />} />
      <Route path="/application-report-by-date" element={<ApplicantListByDate />} />
      <Route path="/application-submission-status-report" element={<ApplicantSubmissionStatusReport />} />
      <Route path="/applications-report" element={<ApplicantListReport />} />
      {/*<Route index={true} path="/payments/*" element={<StudentPaymentRoutes />} />*/}
      <Route path="*" element={<Pages404 />} />

    </Routes>
  )
}
export default AdmissionRoutes