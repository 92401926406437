import jsPDF from "jspdf"
import "jspdf-autotable"
import showToast from "helpers/ToastHelper"

const sua_logo = process.env.PUBLIC_URL + "/logo.jpeg"

export const ApplicationSelectionsPDF = (applicants) => {
  try {
    const doc = new jsPDF({
      orientation: "portrait"
    })
    const pageWidth = doc.internal.pageSize.getWidth()

    // Add University Header
    doc.setFontSize(13)
    doc.setFont("Roboto", "bold")
    doc.text("SOKOINE UNIVERSITY OF AGRICULTURE", 100, 20, { align: "center" })

    doc.setFontSize(10)
    doc.setFont("Roboto", "bold")
    doc.text("OFFICE OF THE DEPUTY VICE CHANCELLOR (ARC)", 100, 25, {
      align: "center"
    })

    doc.setFontSize(8)
    doc.setFont("Roboto", "bold")
    doc.text("P.O. Box 3000, CHUO KIKUU, MOROGORO TANZANIA", 100, 30, {
      align: "center"
    })

    doc.setFontSize(7)
    doc.setFont("Roboto", "bold")
    doc.text(
      "Tel. +255-23-2603511/4, Dir. +255-23-2603236; Fax. +255-23-2604652)",
      100,
      35,
      { align: "center" }
    )

    doc.setFontSize(7)
    doc.setFont("Roboto", "bold")
    doc.text("E-mail: dvc@sua.ac.tz or admission@sua.ac.tz)", 100, 40, {
      align: "center"
    })

    doc.line(15, 52, pageWidth - 15, 52) // Draw a line across the page width

    // Function to group students by program
    const groupStudentsByProgram = (applicants) => {
      return applicants.reduce((acc, student) => {
        const program = student[6] // Index 6 corresponds to program name
        if (!acc[program]) {
          acc[program] = []
        }
        acc[program].push(student)
        return acc
      }, {})
    }

    const groupedStudents = groupStudentsByProgram(applicants)

    let startY = 60

    // Iterate over each program and create a table
    Object.keys(groupedStudents).forEach((program, index) => {
      if (index > 0) {
        startY += 20 // Add some space before the next table if not the first
      }

      // Add program name as a title for each table
      doc.setFontSize(13)
      doc.setFont("Roboto", "bold")
      doc.text(`Programe Name: ${program}`, 15, startY - 10)
      const textWidth = doc.getTextWidth(`Programe Name: ${program}`)

      doc.line(15, startY - 8, 15 + textWidth, startY - 8)
      const tableData = groupedStudents[program].map((student, idx) => [
        idx + 1, // S/No
        student[1], // Firstname
        student[2], // MidName
        student[3], // Surname
        student[4], // Gender
        student[5], // Index No.
        student[6]  // Program Name
      ])

      doc.autoTable({
        startY: startY,
        margin: { top: 5, left: 5, right: 5 },
        headStyles: { fillColor: [0, 100, 0], textColor: 255, lineColor: [0, 0, 0], lineWidth: 0.5 },
        bodyStyles: { textColor: 0, lineColor: [0, 0, 0], lineWidth: 0.5 },
        head: [[
          "S/No", "Firstname", "MidName", "Surname", "Sex", "F4 Index No.", "Programme name"]],
        body: tableData,
        didDrawPage: (data) => {
          // Add page number at the bottom center
          const pageCount = doc.internal.getNumberOfPages()
          const pageNumber = doc.internal.getCurrentPageInfo().pageNumber
          const pageText = `Page ${pageNumber} of ${pageCount}`
          doc.setFontSize(10)
          const textWidth = doc.getTextWidth(pageText)
          doc.text(pageText, (pageWidth - textWidth) / 2, doc.internal.pageSize.getHeight() - 10)
        },
        columnStyles: {
          0: { cellWidth: 15 },
          1: { cellWidth: 30 },
          2: { cellWidth: 30 },
          3: { cellWidth: 30 },
          4: { cellWidth: 15 },
          5: { cellWidth: 35 },
          6: { cellWidth: 45 }
        }
      })

      // Adjust startY for the next table
      startY = doc.autoTable.previous.finalY + 10
    })

    doc.save("Applicant-Selections-2024-2025" + ".pdf")
  } catch (error) {
    showToast("Something went wrong, try again later! " + error, "info")
  }
}

export const ApplicationRankingPDF = (tableDataResponse, title, programName, applicationType = "D") => {
  try {
    const doc = new jsPDF({
      orientation: "landscape"
    })
    const pageWidth = doc.internal.pageSize.getWidth()

    doc.setFontSize(13)
    doc.setFont("Roboto", "bold")
    doc.text("SOKOINE UNIVERSITY OF AGRICULTURE", 150, 20, { align: "center" })

    doc.setFontSize(10)
    doc.setFont("Roboto", "bold")
    doc.text("OFFICE OF THE DEPUTY VICE CHANCELLOR (ARC)", 150, 25, {
      align: "center"
    })

    doc.setFontSize(8)
    doc.setFont("Roboto", "bold")
    doc.text("P.O. Box 3000, CHUO KIKUU, MOROGORO TANZANIA", 150, 30, {
      align: "center"
    })

    doc.setFontSize(7)
    doc.setFont("Roboto", "bold")
    doc.text(
      "Tel. +255-23-2603511/4, Dir. +255-23-2603236; Fax. +255-23-2604652)",
      150,
      35,
      { align: "center" }
    )

    doc.setFontSize(7)
    doc.setFont("Roboto", "bold")
    doc.text("E-mail: dvc@sua.ac.tz or admission@sua.ac.tz)", 150, 40, {
      align: "center"
    })

    doc.setLineWidth(0.5)
    doc.setFontSize(15)
    doc.setFont("Roboto", "bold")
    doc.text(title, 150, 50, {
      align: "center"
    })
    doc.line(15, 52, pageWidth - 15, 52) // Draw a line across the page width

    const textAboveTableHeight = 82 // Adjust based on your actual text height

    let header = null

    if (applicationType === "D") {
      header = [
        ["S/No",
          "Firstname",
          "MidName",
          "Surname",
          "S",
          "Index No.",
          "Points",
          "CSEE",
          "ACSEE",
          "Institution",
          "Cat",
          "Status",
          "Reason"
        ]]
    } else {
      header = [
        ["S/No",
          "Firstname",
          "MidName",
          "Surname",
          "S",
          "Index No.",
          "Points",
          "CSEE",
          "Institution",
          "Diploma",
          "Cat",
          "Status",
          "Reason"
        ]]
    }


    doc.autoTable({
      startY: 60,
      margin: { top: 5, left: 1, right: 1 },
      headStyles: { fillColor: [0, 100, 0], textColor: 255, lineColor: [0, 0, 0], lineWidth: 0.5 },
      bodyStyles: { textColor: 0, lineColor: [0, 0, 0], lineWidth: 0.5 },
      head: header,
      body: tableDataResponse,
      didDrawPage: () => {
        // Add page number at the bottom center
        const pageCount = doc.internal.getNumberOfPages()
        const pageNumber = doc.internal.getCurrentPageInfo().pageNumber
        const pageText = `Page ${pageNumber} of ${pageCount}`
        doc.setFontSize(10)
        const textWidth = doc.getTextWidth(pageText)
        doc.text(pageText, (pageWidth - textWidth) / 2, doc.internal.pageSize.getHeight() - 10)
      },
      columnStyles: applicationType === "D" ? {
        11: { cellWidth: 20 }, // 7th index corresponds to "form four index"
        5: { cellWidth: 35 }, // 7th index corresponds to "form four index"
        7: { cellWidth: 50 }, // 7th index corresponds to "CSEE"
        8: { cellWidth: 60 }, // 8th index corresponds to "ACSEE",
        12: { cellWidth: 20 }, // 7th index corresponds to "form four index"
      } : {
        11: { cellWidth: 20 }, // 7th index corresponds to "form four index"
        5: { cellWidth: 35 }, // 7th index corresponds to "form four index"
        7: { cellWidth: 50 } // 7th index corresponds to "CSEE"
      }
    })

    doc.save(replaceSpacesWithUnderscores(programName) + ".pdf")
  } catch (error) {
    showToast("Something went wrong, try again later! " + error, "info")
  }
}

export const ApplicantConfirmedTCU = (pdfData, title, selectedProgramName) => {
  try {

    const doc = new jsPDF({
      orientation: "portrait"
    })

    const pageWidth = doc.internal.pageSize.getWidth()

    doc.setFontSize(13)
    doc.setFont("Roboto", "bold")
    doc.text("SOKOINE UNIVERSITY OF AGRICULTURE", 100, 20, { align: "center" })

    doc.setFontSize(10)
    doc.setFont("Roboto", "bold")
    doc.text("OFFICE OF THE DEPUTY VICE CHANCELLOR (ARC)", 100, 25, {
      align: "center"
    })

    doc.setFontSize(8)
    doc.setFont("Roboto", "bold")
    doc.text("P.O. Box 3000, CHUO KIKUU, MOROGORO TANZANIA", 100, 30, {
      align: "center"
    })

    doc.setFontSize(7)
    doc.setFont("Roboto", "bold")
    doc.text(
      "Tel. +255-23-2603511/4, Dir. +255-23-2603236; Fax. +255-23-2604652)",
      100,
      35,
      { align: "center" }
    )

    doc.setFontSize(7)
    doc.setFont("Roboto", "bold")
    doc.text("E-mail: dvc@sua.ac.tz or admission@sua.ac.tz)", 100, 40, {
      align: "center"
    })

    doc.setLineWidth(0.5)
    doc.setFontSize(15)
    doc.setFont("Roboto", "bold")
    doc.text(title, 100, 50, {
      align: "center"
    })
    doc.line(15, 52, pageWidth - 15, 52) // Draw a line across the page width

    const header = [["S/No",
      "Form four index No.",
      "Description",
      "Phone number",
    ]]

    doc.autoTable({
      startY: 60,
      margin: { top: 5, left: 5, right: 5 },
      headStyles: { fillColor: [0, 100, 0], textColor: 255, lineColor: [0, 0, 0], lineWidth: 0.5 },
      bodyStyles: { textColor: 0, lineColor: [0, 0, 0], lineWidth: 0.5 },
      head: header,
      body: pdfData,
      didDrawPage: () => {
        const pageCount = doc.internal.getNumberOfPages()
        const pageNumber = doc.internal.getCurrentPageInfo().pageNumber
        const pageText = `Page ${pageNumber} of ${pageCount}`
        doc.setFontSize(10)
        const textWidth = doc.getTextWidth(pageText)
        doc.text(pageText, (pageWidth - textWidth) / 2, doc.internal.pageSize.getHeight() - 10)
      },
      columnStyles: {
        0: { cellWidth: 20 },
        1: { cellWidth: 50 },
        2: { cellWidth: 50 }
      }
    })

    doc.save(replaceSpacesWithUnderscores(selectedProgramName) + ".pdf")
  } catch (error) {
    showToast("Something went wrong, try again later! " + error, "info")
  }
}


export const ApplicantCountByProgramPDF = (tableDataResponse, window) => {
  try {
    const doc = new jsPDF({
      orientation: "landscape"
    })
    const pageWidth = doc.internal.pageSize.getWidth()

    doc.setFontSize(13)
    doc.setFont("Roboto", "bold")
    doc.text("SOKOINE UNIVERSITY OF AGRICULTURE", 150, 20, { align: "center" })

    doc.setFontSize(10)
    doc.setFont("Roboto", "bold")
    doc.text("OFFICE OF THE DEPUTY VICE CHANCELLOR (ARC)", 150, 25, {
      align: "center"
    })

    doc.setFontSize(8)
    doc.setFont("Roboto", "bold")
    doc.text("P.O. Box 3000, CHUO KIKUU, MOROGORO TANZANIA", 150, 30, {
      align: "center"
    })

    doc.setFontSize(7)
    doc.setFont("Roboto", "bold")
    doc.text(
      "Tel. +255-23-2603511/4, Dir. +255-23-2603236; Fax. +255-23-2604652)",
      150,
      35,
      { align: "center" }
    )

    doc.setFontSize(7)
    doc.setFont("Roboto", "bold")
    doc.text("E-mail: dvc@sua.ac.tz or admission@sua.ac.tz)", 150, 40, {
      align: "center"
    })

    doc.setLineWidth(0.5)
    doc.setFontSize(15)
    doc.setFont("Roboto", "bold")
    doc.text(`Application Report Count by Program 2024/2025 for ${window ? window.toUpperCase() : "All Windows"}`, 150, 50, {
      align: "center"
    })
    doc.line(15, 52, pageWidth - 15, 52) // Draw a line across the page width

    const textAboveTableHeight = 82 // Adjust based on your actual text height

    let header = [
      ["S/No",
        "Program name",
        "Male",
        "Female",
        "Total",
        "Selected M",
        "Selected F",
        "Selected Total",
        "Single M",
        "Single F",
        "Single Total",
        "Multiple M",
        "Multiple F",
        "Multiple Total"
      ]]

    const headers = [
      [
        { content: "S/No", rowSpan: 2 }, // S/No header spanning two rows
        { content: "Program name", rowSpan: 2 }, // Program name header spanning two rows
        { content: "Applicants", colSpan: 3, styles: { halign: "center" } }, // Applicants header spanning three columns
        { content: "Selected", colSpan: 3, styles: { halign: "center" } }, // Selected header spanning three columns
        { content: "Single", colSpan: 3, styles: { halign: "center" } }, // Rejected header spanning three columns
        { content: "Multiple", colSpan: 3, styles: { halign: "center" } } // Rejected header spanning three columns
      ],
      [
        { content: "M", styles: { halign: "center" } }, // Male sub-header under Applicants
        { content: "F", styles: { halign: "center" } }, // Female sub-header under Applicants
        { content: "T", styles: { halign: "center" } }, // Total sub-header under Applicants

        { content: "M", styles: { halign: "center" } }, // Male sub-header under Selected
        { content: "F", styles: { halign: "center" } }, // Female sub-header under Selected
        { content: "T", styles: { halign: "center" } }, // Total sub-header under Selected

        { content: "M", styles: { halign: "center" } }, // Male sub-header under Single selected
        { content: "F", styles: { halign: "center" } }, // Female sub-header under Single selected
        { content: "T", styles: { halign: "center" } }, // Total sub-header under Single selected


        { content: "M", styles: { halign: "center" } }, // Male sub-header under Multiple
        { content: "F", styles: { halign: "center" } }, // Female sub-header under Multiple
        { content: "T", styles: { halign: "center" } } // Total sub-header under Multiple
      ]
    ]

    let totalSelectedMale = 0
    let totalSelectedFemale = 0
    let grandselectedTotal = 0

    let countAllMale = 0
    let countcountAllFemale = 0
    let countAllTotal = 0

    let countMultipleMale = 0
    let countMultipleFemale = 0
    let countMultipleTotal = 0

    let countSingleMale = 0
    let countSingleFemale = 0
    let countSingleTotal = 0

    const combinedData = tableDataResponse

// Calculate totals
    combinedData.forEach(row => {
      countAllMale += row[2]   // Summing up the Total column
      countcountAllFemale += row[3]   // Summing up the Total column
      countAllTotal += row[4]   // Summing up the Total column

      totalSelectedMale += row[5]    // Summing up the Male column
      totalSelectedFemale += row[6]  // Summing up the Female column
      grandselectedTotal += row[7]   // Summing up the Total column

      countSingleMale += row[8]   // Summing up the Total column
      countSingleFemale += row[9]   // Summing up the Total column
      countSingleTotal += row[10]   // Summing up the Total column

      countMultipleMale += row[11]   // Summing up the Total column
      countMultipleFemale += row[12]   // Summing up the Total column
      countMultipleTotal += row[13]   // Summing up the Total column
    })

// Create the total row with "-" in the "Program name" column
    const totalRow = [
      "TOTAL", "-",
      countAllMale,
      countcountAllFemale,
      countAllTotal,
      totalSelectedMale,
      totalSelectedFemale,
      grandselectedTotal,
      countSingleMale,
      countSingleFemale,
      countSingleTotal,
      countMultipleMale,
      countMultipleFemale,
      countMultipleTotal
    ]

// Add the total row to the combined data
    combinedData.push(totalRow)
    doc.autoTable({
      startY: 60,
      margin: { top: 5, left: 5, right: 5 },
      headStyles: { fillColor: [0, 100, 0], textColor: 255, lineColor: [0, 0, 0], lineWidth: 0.5 },
      bodyStyles: { textColor: 0, lineColor: [0, 0, 0], lineWidth: 0.5 },
      head: headers,
      body: combinedData,
      didDrawPage: () => {
        // Add page number at the bottom center
        const pageCount = doc.internal.getNumberOfPages()
        const pageNumber = doc.internal.getCurrentPageInfo().pageNumber
        const pageText = `Page ${pageNumber} of ${pageCount}`
        doc.setFontSize(10)
        const textWidth = doc.getTextWidth(pageText)
        doc.text(pageText, (doc.internal.pageSize.getWidth() - textWidth) / 2, doc.internal.pageSize.getHeight() - 10)
      }
    })
    const date = new Date();

    doc.save("Application_report"+date.getDate()+".pdf")
  } catch (error) {
    showToast("Something went wrong, try again later! " + error, "info")
  }
}


function replaceSpacesWithUnderscores(inputString) {
  return inputString.replace(/ /g, "_")
}