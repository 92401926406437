
import Swal from "sweetalert2";

export const showDeleteWarning = (confirmCallback) => {
  Swal.fire({
    title: 'Are you sure?',
    text: 'This action cannot be undone.',
    icon: 'warning',
    showCancelButton: true,
    allowOutsideClick: false,
    confirmButtonColor: '#dc3545',
    cancelButtonColor: '#6c757d',
    confirmButtonText: 'Delete',
  }).then((result) => {
    if (result.isConfirmed) {
      confirmCallback();
    }
  });
};

export const showCancelTCUSelectionWarning = (confirmCallback) => {
  Swal.fire({
    title: 'ARE YOU SURE YOU WANT TO CANCEL ADMISSION AT SOKOINE UNIVERSITY OF AGRICULTURE?',
    text: 'This action cannot be undone.',
    icon: 'warning',
    showCancelButton: true,
    allowOutsideClick: false,
    confirmButtonColor: '#dc3545',
    cancelButtonColor: '#6c757d',
    confirmButtonText: 'Confirm',
  }).then((result) => {
    if (result.isConfirmed) {
      confirmCallback();
    }
  });
};

export const showStartSelfRegistrationAlert = (confirmCallback) => {
  Swal.fire({
    title: 'SELF REGISTRATION ',
    text: 'Are you sure, you want to start self registration?',
    icon: 'info',
    showCancelButton: true,
    allowOutsideClick: false,
    confirmButtonColor: '#006400',
    cancelButtonColor: '#6c757d',
    confirmButtonText: 'Yes',
  }).then((result) => {
    if (result.isConfirmed) {
      confirmCallback();
    }
  });
};

export const showRestoreCancelTCUSelectionWarning = (confirmCallback) => {
  Swal.fire({
    title: 'Are you a SUA Admission applicant and want to restore your cancelled selection?',
    text: 'This action cannot be undone.',
    icon: 'warning',
    showCancelButton: true,
    allowOutsideClick: false,
    confirmButtonColor: '#dc3545',
    cancelButtonColor: '#6c757d',
    confirmButtonText: 'Confirm',
  }).then((result) => {
    if (result.isConfirmed) {
      confirmCallback();
    }
  });
};

export const showUnpublishProgramSemesterResultAlert = (confirmCallback) => {
  Swal.fire({
    title: 'Are you sure want to unpublish result?',
    text: 'This action cannot be undone.',
    icon: 'warning',
    showCancelButton: true,
    allowOutsideClick: false,
    confirmButtonColor: '#3cb371',
    cancelButtonColor: '#6c757d',
    confirmButtonText: 'Unpublish',
  }).then((result) => {
    if (result.isConfirmed) {
      confirmCallback();
    }
  });
}
export const showForwardingAlert = (confirmCallback) => {
  Swal.fire({
    title: 'Are you sure?',
    text: 'This action cannot be undone.',
    icon: 'warning',
    showCancelButton: true,
    allowOutsideClick: false,
    confirmButtonColor: '#3cb371',
    cancelButtonColor: '#6c757d',
    confirmButtonText: 'Forward',
  }).then((result) => {
    if (result.isConfirmed) {
      confirmCallback();
    }
  });
};

export const showReturnCourseResultAlert = (confirmCallback) => {
  Swal.fire({
    title: 'Are you sure want to reject forwarded course(s)?',
    text: 'This action cannot be undone.',
    icon: 'warning',
    showCancelButton: true,
    allowOutsideClick: false,
    confirmButtonColor: '#3cb371',
    cancelButtonColor: '#6c757d',
    confirmButtonText: 'Return',
  }).then((result) => {
    if (result.isConfirmed) {
      confirmCallback();
    }
  });
};

export const showForceCourseResultForwardingAlert = (confirmCallback) => {
  Swal.fire({
    title: 'Are you sure want to force course(s)?',
    text: 'This action cannot be undone.',
    icon: 'warning',
    showCancelButton: true,
    allowOutsideClick: false,
    confirmButtonColor: '#3cb371',
    cancelButtonColor: '#6c757d',
    confirmButtonText: 'Force forward',
  }).then((result) => {
    if (result.isConfirmed) {
      confirmCallback();
    }
  });
};



export const showActivationWarning = (confirmCallback, text, buttonText, buttonColor) => {
  Swal.fire({
    title: 'Are you sure?',
    text: text,
    icon: 'warning',
    showCancelButton: true,
    allowOutsideClick: false,
    confirmButtonColor: buttonColor,
    cancelButtonColor: '#6c757d',
    confirmButtonText: buttonText,
  }).then((result) => {
    if (result.isConfirmed) {
      confirmCallback();
    }
  });
};

export const showTaskWarning = (confirmCallback) => {
  Swal.fire({
    title: 'Are you sure?',
    text: 'This action cannot be undone.',
    icon: 'warning',
    showCancelButton: true,
    allowOutsideClick: false,
    confirmButtonColor: 'green',
    cancelButtonColor: '#6c757d',
    confirmButtonText: 'Yes! Thanks',
  }).then((result) => {
    if (result.isConfirmed) {
      confirmCallback();
    }
  });
};

export const showWaringConf = (confirmCallback,title,text,icon,confirmBtn,cancelCallBack,isHtml = false) => {
  Swal.fire({
    title: title,
    text: !isHtml? text : "",
    html: isHtml? text : "",
    icon: icon,
    showCancelButton: true,
    allowOutsideClick: false,
    // confirmButtonColor: '#dc3545',
    // cancelButtonColor: '#6c757d',
    confirmButtonText: confirmBtn,
  }).then((result) => {
    if (result.isConfirmed) {
      confirmCallback();
    }else{
      cancelCallBack();
    }
  });
};