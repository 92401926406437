import { gql } from "@apollo/client"

const MOVE_APPLICANT_TO_STUDENT = gql`
    mutation moveApplicantToStudent($applicantUid: String!, $programmeCode: String!){
        moveApplicantToStudent(applicantUid:$applicantUid, programmeCode:$programmeCode){
            status
            code
            message
        }
    }
`

export { MOVE_APPLICANT_TO_STUDENT }